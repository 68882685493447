import React, { useState, useMemo, Fragment } from "react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { cn } from "~/src/util/reusables";
import { Button } from "~/src/primitives/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "~/src/primitives/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/src/primitives/popover";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import API, { Hub, Media, Team } from "~/src/api/withApi";
import { AlertTriangle, Layers3, LucideIcon, ShipWheel } from "lucide-react";
import { setError, setLoading, setSuccess } from "../../reducers/toolkit";
import { setUser } from "../../reducers/user";
import { useLocation, useNavigate } from "react-router-dom";
import HubIcon from "../../views/Hubs/components/HubIcon";
import axios from "axios";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../primitives/alert-dialog";
import LocationDetailsForm from "../../views/Hubs/components/Locations/LocationDetailsForm";
import { getOrganizationWorkspaces } from "../../api/organization.api";
import { useToast } from "../../primitives/use-toast";

type Entity = {
  label: string;
  value: `Workspaces_${string}` | `Hubs_${string}` | "unknown";
  icon?: Media;
};

type Group = {
  icon: LucideIcon;
  label: string;
  entities: Entity[];
  onClick: (id: string) => void;
};

const extractEntityId = (value: string): string => {
  const match = value.match(/^(Workspaces_|Hubs_)(.*)/);
  return match ? match[2] : value;
};

const buildSwitcherOptions = (
  workspaces: Partial<Team>[] = [],
  hubs: Partial<Hub>[] = [],
  updateWorkspace: (id: string) => void,
  updateHub: (id: string) => void,
): Group[] => [
  {
    icon: Layers3,
    label: "Workspaces",
    entities: workspaces.map((team) => ({
      label: team.name || "Unnamed Workspace",
      value: `Workspaces_${team._id}`,
      icon: team.icon as Media,
    })),
    onClick: updateWorkspace,
  },
  ...(hubs.length > 0
    ? [
        {
          icon: ShipWheel,
          label: "Hubs",
          entities: hubs
            .map((hub) => ({
              label: hub.name || "Unnamed Hub",
              value: `Hubs_${hub._id}` as any,
              icon: hub.icon as Media,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
          onClick: updateHub,
        },
      ]
    : []),
];

const SwitcherOptions = ({
  options,
  selectedEntity,
  setOpen,
}: {
  options: Group[];
  selectedEntity: Entity;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  return (
    <Command>
      <CommandInput className="h-8" placeholder="Search..." />
      <CommandEmpty>No entities found</CommandEmpty>
      <CommandList>
        {options.map((group) => (
          <Fragment key={group.label}>
            <CommandGroup
              heading={
                <div className="mx-auto flex w-fit items-center gap-2 rounded-lg bg-secondary px-1.5 py-0.5">
                  <group.icon className="mr-1 size-3 text-gray-400" />
                  <span className="text-sm font-medium text-gray-500">
                    {group.label}
                  </span>
                  <span className="py-0.25 inline-flex min-w-6 items-center justify-center rounded-full bg-primary/40 px-1 text-xs font-medium text-primary-foreground/80 backdrop-blur-sm">
                    {group.entities.length}
                  </span>
                </div>
              }
            >
              {!user?.manifest?.view_organization &&
              group.label === "Workspaces" ? (
                <CommandItem
                  className="flex items-center justify-between rounded-md bg-yellow-50 p-2 text-sm text-yellow-600"
                  onSelect={() => navigate("/billing")}
                >
                  <div className="flex items-center gap-2 text-xs">
                    <AlertTriangle className="h-4 w-4 shrink-0 text-yellow-600" />
                    <span>Upgrade required to view workspaces</span>
                  </div>
                  <Button
                    variant="warning"
                    size="xs"
                    className="ml-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("/billing");
                    }}
                  >
                    Upgrade
                  </Button>
                </CommandItem>
              ) : (
                group.entities.map((entity) => (
                  <Fragment key={entity.value}>
                    <CommandSeparator className="opacity-40" />
                    <CommandItem
                      onSelect={() => {
                        group.onClick(extractEntityId(entity.value));
                        setOpen(false);
                      }}
                      className="text-sm"
                    >
                      {entity.icon &&
                        (entity.value?.startsWith("Hubs_") ? (
                          <HubIcon
                            icon={entity.icon}
                            className="mr-0 h-5 w-5"
                            dharmaClassName="h-4 w-4"
                          />
                        ) : (
                          <img
                            src={entity.icon?.uri}
                            crossOrigin="anonymous"
                            className="h-5 w-5 object-contain"
                          />
                        ))}
                      {entity.label}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          selectedEntity.value === entity.value
                            ? "opacity-100"
                            : "opacity-0",
                        )}
                      />
                    </CommandItem>
                  </Fragment>
                ))
              )}
            </CommandGroup>
            <CommandSeparator />
          </Fragment>
        ))}
      </CommandList>
      <CommandSeparator />
    </Command>
  );
};
export default function Switcher() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { pathname, search } = useLocation();
  const { toast } = useToast();

  const { data: workspaces, error } = useQuery<Team[]>({
    queryKey: ["workspaces", pathname],
    queryFn: async () => {
      try {
        const response = await getOrganizationWorkspaces();
        return response;
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 401) {
          toast({
            variant: "destructive",
            title: "Session Expired",
            description: "The session has expired. Please log in again",
          });
          let path = "/login";
          const query = `${pathname}${search}`;
          if (query && query !== "/") path = `${path}?after=${query}`;
          navigate(path);
          throw err;
        }

        // Report other errors
        await axios
          .post("/api/admin/report-error", {
            title: "Error fetching workspaces",
            location,
            error: err?.message,
            user: user?._id ?? null,
          })
          .catch((reportErr) =>
            console.error("Error reporting to backend:", reportErr),
          );

        throw err;
      }
    },
    enabled: !!user?.manifest?.view_organization,
  });

  const updateCurrentWorkspaceMutation = useMutation({
    mutationFn: (id: string) => {
      dispatch(setLoading("Switching active workspace..."));
      return API.updateWorkspace(id);
    },
    onSuccess: ({ name }) => {
      dispatch(setSuccess(`Workspace - ${name}`));
      API.getSession("?update=true").then(({ user }) =>
        dispatch(setUser(user)),
      );
      queryClient.invalidateQueries({ queryKey: ["workspaces"] });
      const newUrl = location.pathname
        .replace(/\/hub\/[^/]+/, "")
        .replace(/\/campaigns\/([^/]+)\/.*/, "/campaigns/$1");
      if (location.pathname !== newUrl) navigate(newUrl);
    },
    onError: (err) => {
      console.error(err);
      dispatch(setError("Error switching workspace"));
    },
  });

  const updateCurrentHubMutation = useMutation({
    mutationFn: (id: string) => {
      dispatch(setLoading("Switching active hub..."));
      return API.updateHubSession(id);
    },
    onSuccess: ({ name }, id) => {
      dispatch(setSuccess(`Hub - ${name}`));
      API.getSession("?update=true").then(({ user }) =>
        dispatch(setUser(user)),
      );
      const hubRegex = /\/hub\/[^/]+/;
      const campaignPathRegex = /\/campaigns\/([^/]+)\/.*/;
      const newUrl = (
        hubRegex.test(location.pathname)
          ? location.pathname.replace(hubRegex, `/hub/${id}`)
          : `/hub/${id}${location.pathname}`
      ).replace(campaignPathRegex, "/campaigns/$1");
      if (location.pathname !== newUrl) navigate(newUrl);
    },
    onError: (err) => {
      console.error(err);
      dispatch(setError("Error navigating to hub"));
    },
  });

  const options = useMemo(
    () =>
      buildSwitcherOptions(
        workspaces ?? [],
        (user?.hubs ?? []) as Hub[],
        updateCurrentWorkspaceMutation.mutate,
        updateCurrentHubMutation.mutate,
      ),
    [
      workspaces,
      user?.hubs,
      updateCurrentWorkspaceMutation.mutate,
      updateCurrentHubMutation.mutate,
    ],
  );

  const selectedEntity = useMemo(() => {
    if (user?.hub?._id && options[1]?.entities) {
      return (
        options[1].entities.find((e) => e.value === `Hubs_${user.hub._id}`) ||
        options[0]?.entities?.[0]
      );
    }
    if (options[0]?.entities) {
      return (
        options[0].entities.find(
          (e) => e.value === `Workspaces_${user?.workspace?._id}`,
        ) || options[0].entities[0]
      );
    }

    const fallback = options[0]?.entities?.[0];
    if (!fallback) {
      axios
        .post("/api/admin/report-error", {
          title: "Error fetching workspaces",
          location,
          error: error?.message,
          user: user?._id ?? null,
        })
        .catch((err) => console.error("Error reporting to backend:", err));
    }

    return fallback;
  }, [user, options]);

  if (!selectedEntity) return null;

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outlineFill"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a workspace or hub"
            className={cn(
              "h-fit justify-between",
              selectedEntity.value.startsWith("Hubs")
                ? "border-blue-500 xl:border-inherit"
                : "border-orange-500 xl:border-inherit",
            )}
          >
            <span className="line-clamp-1 text-2xs">
              {selectedEntity.label}
            </span>
            <CaretSortIcon className="ml-auto size-3 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0" side="bottom" align="center">
          <SwitcherOptions
            options={options}
            selectedEntity={selectedEntity}
            setOpen={setOpen}
          />
        </PopoverContent>
      </Popover>
      <AlertDialog open={!user?.hub && user?.workspace?.requiresOnboarding}>
        <AlertDialogContent className="flex h-full max-h-[90%] max-w-[90%] flex-col gap-4 bg-muted p-2">
          <AlertDialogHeader className="h-fit pt-2 sm:text-center">
            <AlertDialogTitle>Complete Location Onboarding</AlertDialogTitle>
          </AlertDialogHeader>
          <LocationDetailsForm
            onSuccess={() =>
              API.getSession("?update=true").then(({ user }) => {
                dispatch(setUser(user));
                queryClient.invalidateQueries({ queryKey: ["workspaces"] });
                navigate("/welcome");
              })
            }
            teamId={user?.workspace?._id}
          />
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
