import axios, { AxiosResponse } from "axios";

import { z } from "zod";

export const MetaAdLabel = z.object({
  id: z.string().describe("Ad Label ID"),
  name: z.string().describe("Ad Label name"),
  account_id: z.string().optional().describe("Ad Account ID"),
  created_time: z.string().datetime().optional().describe("Created time"),
  updated_time: z.string().datetime().optional().describe("Updated time"),
  hub: z.string().optional().describe("Hub ID"),
  team: z.string().optional().describe("Team ID"),
});

export type MetaAdLabel = z.infer<typeof MetaAdLabel>;

export interface CreateAdLabelRequest {
  name: string;
}

export interface UpdateAdLabelRequest {
  name?: string;
}

export const createMetaAdLabel = async (
  data: CreateAdLabelRequest,
): Promise<MetaAdLabel> => {
  const response: AxiosResponse<MetaAdLabel> = await axios.post(
    "/api/paid/adlabels",
    data,
  );
  return response.data;
};

export const getMetaAdLabels = async (): Promise<MetaAdLabel[]> => {
  const response: AxiosResponse<MetaAdLabel[]> =
    await axios.get("/api/paid/adlabels");
  return response.data;
};

export const getMetaAdLabel = async (labelId: string): Promise<MetaAdLabel> => {
  const response: AxiosResponse<MetaAdLabel> = await axios.get(
    `/api/paid/adlabels/${labelId}`,
  );
  return response.data;
};

export const updateMetaAdLabel = async (
  labelId: string,
  data: UpdateAdLabelRequest,
): Promise<MetaAdLabel> => {
  const response: AxiosResponse<MetaAdLabel> = await axios.patch(
    `/api/paid/adlabels/${labelId}`,
    data,
  );
  return response.data;
};

export const deleteMetaAdLabel = async (labelId: string): Promise<void> => {
  await axios.delete(`/api/paid/adlabels/${labelId}`);
};

export const syncMetaAdLabels = async (): Promise<MetaAdLabel[]> => {
  const response: AxiosResponse<MetaAdLabel[]> = await axios.post(
    "/api/paid/adlabels/sync",
  );
  return response.data;
};
