import React, { useContext, useEffect, useState } from "react";
import API, {
  createOrganizationWorkspace,
  getPrimaryHubIdForWorkspace,
  Hub,
  Media,
  OrganizationType,
  Role,
  Team,
  UserAccount,
} from "~/src/api/withApi";
import FadeIn from "~/src/partials/Transitions/FadeIn";
import { Badge } from "~/src/primitives/badge";
import { Button } from "~/src/primitives/button";
import { Card } from "~/src/primitives/card";
import { OrganizationContext } from "../Organization";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableHeader,
} from "~/src/primitives/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/src/primitives/dropdown-menu";
import {
  Check,
  CircleAlert,
  Cog,
  MoreHorizontal,
  Palette,
  Unplug,
  UserPlus,
  Workflow,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "~/src/reducers/user";

import CreateWorkspaceModal from "./components/CreateWorkspaceModal";
import WorkspaceSettingsModal from "./components/WorkspaceSettingsModal";
import { approvalWorkflowFromId } from "./Approval";
import WorkspaceSocialsModal from "./components/WorkspaceSocialsModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import WorkspaceIntegrationsModal from "./components/WorkspaceIntegrationsModal";
import { RootState } from "~/src/store";
import { cn, withPfp } from "~/src/util/reusables";
import { setError, setLoading, setSuccess } from "~/src/reducers/toolkit";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/src/primitives/select";
import HubIcon from "../../Hubs/components/HubIcon";
import { useQueryClient } from "@tanstack/react-query";

type EditQuery = "settings" | "socials" | "integrations";

const Workspaces = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useSelector((state: RootState) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const edit = searchParams.get("edit") as EditQuery;
  const defaultSelectedWorkspace = searchParams.get("selectedWorkspace");

  const organization = useContext(OrganizationContext);
  const [showCreateWorkspaceModal, setShowCreateWorkspaceModal] =
    useState<boolean>(false);
  const [showWorkspaceSettings, setShowWorkspaceSettings] =
    useState<boolean>(false);
  const [showWorkspaceSocials, setShowWorkspaceSocials] =
    useState<boolean>(false);
  const [showWorkspaceIntegrations, setShowWorkspaceIntegrations] =
    useState<boolean>(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState<number>();

  const workspacesAvailable =
    organization?.maxWorkspacesQuantity ??
    organization?.owner?.subscription?.workspaces;
  const workspacesUsed = organization?.workspaces?.length;

  const onUpdateWorkspace = (workspaceId: string, diff: Partial<Team>) =>
    API.updateOrganizationWorkspace(workspaceId, diff)
      .then((data) => data.status)
      .then(console.log)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  const handleUpdateCurrentWorkspace = (_id?: string) => {
    dispatch(setLoading("Switching active workspace..."));
    API.updateWorkspace(_id)
      .then(({ name }) => dispatch(setSuccess(`Workspace - ${name}`)))
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => {
        dispatch(setUser(user));
        dispatch(
          setSuccess(
            `Workspace - ${
              organization?.workspaces?.find((w) => w._id === _id)?.name
            }`,
          ),
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(setError("Error switching workspaces"));
      });
  };

  const onShowWorkspaceSettings = (workspaceId: string) => {
    setSelectedWorkspace(
      organization?.workspaces
        ?.map((w: Partial<Team>) => w._id)
        ?.indexOf(workspaceId),
    );
    handleUpdateCurrentWorkspace(workspaceId);
    setShowWorkspaceSettings(true);
  };

  const onShowWorkspaceSocials = (workspaceId: string) => {
    handleUpdateCurrentWorkspace(workspaceId);
    setSelectedWorkspace(
      organization?.workspaces
        ?.map((w: Partial<Team>) => w._id)
        ?.indexOf(workspaceId),
    );
    setShowWorkspaceSocials(true);
  };

  const onShowWorkspaceBrand = (workspaceId: string) => {
    handleUpdateCurrentWorkspace(workspaceId);
    setSelectedWorkspace(
      organization?.workspaces
        ?.map((w: Partial<Team>) => w._id)
        ?.indexOf(workspaceId),
    );
    navigate("/brand");
  };

  const onShowWorkspaceIntegrations = (workspaceId: string) => {
    handleUpdateCurrentWorkspace(workspaceId);
    setSelectedWorkspace(
      organization?.workspaces
        ?.map((w: Partial<Team>) => w._id)
        ?.indexOf(workspaceId),
    );
    setShowWorkspaceIntegrations(true);
  };

  // Respect query params
  useEffect(() => {
    if (organization?.workspaces?.length) {
      const workspaceIdx = parseInt(defaultSelectedWorkspace);
      if (typeof workspaceIdx === "number" && edit) {
        switch (edit) {
          case "settings":
            onShowWorkspaceSettings(
              organization?.workspaces?.[workspaceIdx]?._id,
            );
            break;
          case "socials":
            onShowWorkspaceSocials(
              organization?.workspaces?.[workspaceIdx]?._id,
            );
            break;
          case "integrations":
            onShowWorkspaceIntegrations(
              organization?.workspaces?.[workspaceIdx]?._id,
            );
            break;
        }
      }

      setSearchParams();
    }
  }, [organization?.workspaces]);

  const onCreateWorkspace = async (name: string) => {
    try {
      await createOrganizationWorkspace({ name });
      const { user } = await API.getSession("?update=true");
      dispatch(setUser(user));
    } catch (err: any) {
      if (err.response?.data?.error) {
        dispatch(setError(err.response.data.error));
      }
      console.error(err);
    }
  };

  const usersForWorkspace = (
    workspace: Partial<Team>,
    organization: OrganizationType,
  ): Partial<UserAccount>[] => {
    const allowed = organization.seats.filter(
      (e) =>
        e.organizationRole === Role.SUPER_ADMIN ||
        workspace.users?.includes(e._id),
    );
    return allowed;
  };

  const setRedirect = (src: EditQuery) => {
    sessionStorage.setItem(
      "flamel-redirect",
      `/organization?tab=Workspaces&selectedWorkspace=${selectedWorkspace}&edit=${src}`,
    );
  };

  return (
    <FadeIn show timeout={100}>
      <Card className="mt-2 w-full p-4">
        <div className="mb-4 flex flex-row items-center justify-between">
          <div className="flex items-center gap-2">
            {workspacesUsed > workspacesAvailable && (
              <Badge variant="secondary">
                <CircleAlert className="mr-1 size-3 text-red-600" />
                You have exceeded your workspace quota
              </Badge>
            )}
            <Badge variant="outline">
              {workspacesUsed} / {workspacesAvailable} Workspaces Used
            </Badge>
          </div>
          <div className="flex flex-row justify-start space-x-2">
            <Button
              variant="default"
              size="sm"
              disabled={
                workspacesUsed >= workspacesAvailable ||
                !user.manifest.manage_organization
              }
              onClick={() => setShowCreateWorkspaceModal(true)}
            >
              <Workflow className="mr-2 h-4 w-4" /> Add Workspace
            </Button>
          </div>
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Primary Hub</TableHead>
              <TableHead>Users</TableHead>
              <TableHead>Approval Workflow</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {(organization?.workspaces as Partial<Team>[])?.map((workspace) => (
              <TableRow key={`seat-user-${workspace._id}`}>
                <TableCell className="flex flex-row items-center space-x-4">
                  {workspace?.name}
                  <Check
                    className={cn(
                      "ml-2 h-4 w-4 text-green-400 text-opacity-0",
                      user.workspace._id === workspace._id &&
                        "text-opacity-100",
                    )}
                  />
                </TableCell>
                <TableCell>
                  {workspace?.hubs?.length ? (
                    <Select
                      value={getPrimaryHubIdForWorkspace(workspace)}
                      onValueChange={(val) =>
                        onUpdateWorkspace(workspace._id, { primaryHub: val })
                          .then(() =>
                            dispatch(setSuccess("Workspace hub updated!")),
                          )
                          .catch((err) => {
                            console.error(err);
                            dispatch(
                              setError("Unable to update workspace's hub"),
                            );
                          })
                      }
                    >
                      <SelectTrigger className="w-40 bg-white">
                        <SelectValue placeholder="No hub selected" />
                      </SelectTrigger>
                      <SelectContent>
                        {(workspace?.hubs as Hub[])?.map((hub) => (
                          <SelectItem key={hub._id} value={hub._id}>
                            <div className="flex items-center gap-2">
                              <HubIcon
                                icon={hub?.icon as Media}
                                className="mr-0 h-4 w-4"
                                dharmaClassName="h-4 w-4"
                              />
                              {hub?.name}
                            </div>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  ) : (
                    "N/A"
                  )}
                </TableCell>
                <TableCell>
                  <div className="relative h-6">
                    {usersForWorkspace(workspace, organization)?.map((e, i) => (
                      <img
                        src={withPfp(e.pfp, `${e?.firstname} ${e?.lastname}`)}
                        onError={(event) => {
                          // Fallback to a placeholder image if loading fails
                          (event.target as any).src = withPfp(
                            "",
                            `${e?.firstname} ${e?.lastname}`,
                          );
                        }}
                        key={e._id}
                        className="absolute h-6 w-6 rounded-full border border-white shadow-lg"
                        crossOrigin="anonymous"
                        style={{
                          top: 0,
                          left: 15 * i,
                          zIndex: 100 - i,
                        }}
                      />
                    ))}
                  </div>
                </TableCell>
                <TableCell>
                  {approvalWorkflowFromId(
                    workspace?.approvalWorkflow,
                    organization,
                  )?.name ?? <strong>None</strong>}
                </TableCell>
                <TableCell>
                  <div className="flex h-full flex-row items-center">
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <MoreHorizontal className="h-4 w-4" />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        {user.manifest.manage_organization && (
                          <>
                            <DropdownMenuGroup>
                              <DropdownMenuItem
                                onClick={() => {
                                  onShowWorkspaceSettings(workspace._id);
                                }}
                              >
                                <Cog className="mr-2 h-4 w-4" /> Settings
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => {
                                  onShowWorkspaceSocials(workspace._id);
                                }}
                              >
                                <UserPlus className="mr-2 h-4 w-4" /> Social
                                Accounts
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => {
                                  onShowWorkspaceIntegrations(workspace._id);
                                }}
                              >
                                <Unplug className="mr-2 h-4 w-4" /> Integrations
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => {
                                  onShowWorkspaceBrand(workspace._id);
                                }}
                              >
                                <Palette className="mr-2 h-4 w-4" /> Brand
                              </DropdownMenuItem>
                            </DropdownMenuGroup>
                            <DropdownMenuSeparator />
                          </>
                        )}

                        <DropdownMenuGroup>
                          <DropdownMenuItem
                            disabled={user.workspace._id === workspace._id}
                            onClick={() => {
                              handleUpdateCurrentWorkspace(workspace._id);
                            }}
                          >
                            <Check className="mr-2 h-4 w-4" />
                            <span>
                              {user.workspace._id !== workspace._id
                                ? "Set as Active"
                                : "Active"}
                            </span>
                          </DropdownMenuItem>
                        </DropdownMenuGroup>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>

      <CreateWorkspaceModal
        show={showCreateWorkspaceModal}
        onCreate={onCreateWorkspace}
        onHide={() => setShowCreateWorkspaceModal(false)}
      />

      <WorkspaceSettingsModal
        show={showWorkspaceSettings}
        onHide={() => {
          setShowWorkspaceSettings(false);
          queryClient.invalidateQueries({ queryKey: ["workspaces"] });
        }}
        workspace_id={organization?.workspaces?.[selectedWorkspace]?._id}
      />

      <WorkspaceSocialsModal
        show={showWorkspaceSocials}
        onHide={() => setShowWorkspaceSocials(false)}
        workspace={organization?.workspaces?.[selectedWorkspace]}
        onUpdate={() => setRedirect("socials")}
      />

      <WorkspaceIntegrationsModal
        show={showWorkspaceIntegrations}
        onHide={() => setShowWorkspaceIntegrations(false)}
        workspace={organization?.workspaces?.[selectedWorkspace]}
      />
    </FadeIn>
  );
};

export default Workspaces;
