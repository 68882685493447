import React from "react";
import { Button } from "~/src/primitives/button";
import { Download, Loader } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/src/primitives/dropdown-menu";
import { toast } from "~/src/primitives/use-toast";
import dayjs from "./dayjs";
import { Progress } from "~/src/primitives/progress";

// Sync
export interface SyncResponse {
  processed: number;
  created: number;
  updated: number;
}

// Pagination
export interface PaginationParams {
  q?: string;
  limit: number;
  offset: number;
}
export interface PaginatedResponse<T> {
  pagination: {
    limit: number;
    offset: number;
    totalResults: number;
    currentPage: number;
    totalPages: number;
    hasMore: boolean;
  };
  data: T[];
}

export interface ExporterProps<T extends Record<string, any>> {
  /**
   * Primary key needed for the fetch function (e.g., formId, userId)
   */
  primaryKey?: string;

  /**
   * Function to fetch data
   */
  fetchFn?:
    | ((params: PaginationParams) => Promise<PaginatedResponse<T>>)
    | ((
        primaryKey: string | undefined,
        params: PaginationParams,
      ) => Promise<PaginatedResponse<T>>);

  /**
   * Function to convert data array to CSV string
   */
  convertToCSV: (data: T[]) => string;

  /**
   * Base filename for exports (without extension)
   */
  baseFilename: string;

  /**
   * Current query result
   */
  currentQueryResponse: PaginatedResponse<T>;

  /**
   * Whether the export functionality is enabled
   */
  isEnabled?: boolean;

  /**
   * Whether the export functionality is minimal
   */
  isMinimal?: boolean;
}

const BATCH_SIZE = 500;

export default function Exporter<T extends Record<string, any>>({
  primaryKey,
  fetchFn,
  convertToCSV,
  baseFilename,
  currentQueryResponse,
  isEnabled = true,
  isMinimal = false,
}: ExporterProps<T>) {
  const [isExporting, setIsExporting] = React.useState(false);
  const [exportProgress, setExportProgress] = React.useState(0);

  if (!fetchFn && !isMinimal) {
    throw new Error("fetchFn is required when isMinimal is false");
  }

  const handleExport = async (scope: "current" | "all") => {
    if (!isEnabled) {
      toast({
        variant: "destructive",
        title: "Export not available",
      });
      return;
    }

    try {
      setIsExporting(true);
      setExportProgress(0);
      let dataToExport: T[] = [];

      if (scope === "current") {
        dataToExport = currentQueryResponse?.data || [];
      } else {
        const totalRecords = currentQueryResponse.pagination.totalResults;
        const batches = Math.ceil(totalRecords / BATCH_SIZE);

        for (let i = 0; i < batches; i++) {
          const response = await (typeof fetchFn === "function" &&
          fetchFn.length === 1
            ? (
                fetchFn as (
                  params: PaginationParams,
                ) => Promise<PaginatedResponse<T>>
              )({
                limit: BATCH_SIZE,
                offset: i * BATCH_SIZE,
              })
            : (
                fetchFn as (
                  primaryKey: string | undefined,
                  params: PaginationParams,
                ) => Promise<PaginatedResponse<T>>
              )(primaryKey, {
                limit: BATCH_SIZE,
                offset: i * BATCH_SIZE,
              }));

          dataToExport = [...dataToExport, ...response.data];
          setExportProgress(Math.round(((i + 1) / batches) * 100));
        }
      }

      if (dataToExport.length === 0) {
        toast({
          variant: "destructive",
          title: "No data to export",
        });
        return;
      }

      const csvContent = convertToCSV(dataToExport);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      const filename = `${baseFilename} - ${scope} - ${dayjs().format("YYYY-MM-DD")}.csv`;

      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast({
        title: "Export Successful",
        description: `Exported ${dataToExport.length} records successfully.`,
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Export Failed",
        description:
          error instanceof Error ? error.message : "Unknown error occurred",
      });
    } finally {
      setIsExporting(false);
      setExportProgress(0);
    }
  };

  return isMinimal ? (
    <Button
      variant="outline"
      size="sm"
      disabled={!isEnabled || isExporting}
      onClick={() => handleExport("current")}
    >
      <Download className="mr-2 h-4 w-4" />
      Export
    </Button>
  ) : (
    <div className="flex items-center gap-2">
      {/* Progress bar for "Export All" operation */}
      {isExporting && exportProgress > 0 && (
        <Progress value={exportProgress} showPercentage className="w-32" />
      )}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            disabled={!isEnabled || isExporting}
          >
            {isExporting ? (
              <Loader className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <Download className="mr-2 h-4 w-4" />
            )}
            Export...
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={() => handleExport("current")}
            className="gap-4"
          >
            <span className="flex-1">Export Current Page</span>
            <span className="text-xs text-muted-foreground">
              ({currentQueryResponse?.data?.length})
            </span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => handleExport("all")}
            className="gap-4"
          >
            <span className="flex-1">Export All Pages</span>
            {/* <span className="text-xs text-muted-foreground">
              ({currentQueryResponse?.pagination?.totalResults})
            </span> */}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
