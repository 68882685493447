import React, { ReactNode } from "react";
import { Badge } from "~/src/primitives/badge";
import { Dialog, DialogContent } from "~/src/primitives/dialog";
import { Media } from "~/src/api/withApi";
import { TooltipButton } from "~/src/util/reusables";
import { Action } from "~/src/lib/Media/ActionContainer";
import LoadMedia from "~/src/partials/Load/LoadMedia";

interface DisplayMediaProps {
  show: boolean;
  onHide: () => void;
  media: Media;
  forceSourceMedia?: boolean;
  actions?: Action[];
}

const DisplayMedia = ({
  show,
  onHide,
  media,
  forceSourceMedia = false,
  actions,
}: DisplayMediaProps) => {
  if (!media) return <></>;

  return (
    <Dialog
      open={show}
      onOpenChange={(open) => {
        if (!open) onHide();
      }}
    >
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="h-full max-h-[90vh] w-fit min-w-[50%] justify-center sm:max-w-[90%]"
      >
        <DisplayMediaContent
          media={media}
          forceSourceMedia={forceSourceMedia}
          actions={actions}
        />
      </DialogContent>
    </Dialog>
  );
};

export const DisplayMediaContent = ({
  media,
  forceSourceMedia,
  actions,
  children,
}: Pick<DisplayMediaProps, "media" | "forceSourceMedia" | "actions"> & {
  children?: ReactNode;
}) => {
  if (!media) return null;
  const tags = media.tags?.filter((t) => t && t !== "undefined");
  return (
    <>
      <LoadMedia
        alt={media?.alt}
        className="mx-auto mt-4 max-h-full w-auto max-w-full overflow-hidden rounded-md object-contain shadow"
        mimeType={media?.mimeType}
        src={forceSourceMedia ? media?.uri : (media?.editUri ?? media?.uri)}
      />
      <div className="flex justify-between gap-2">
        <div className="flex w-full items-center justify-between gap-8 py-2">
          {Array.isArray(media?.tags) && tags.length > 0 ? (
            <div className="flex items-center gap-2">
              <span className="text-sm font-bold">Tags:</span>
              {tags?.map((t, i) => (
                <Badge key={`tag-${i}`} variant="outline">
                  {t}
                </Badge>
              ))}
            </div>
          ) : (
            <div></div>
          )}
          {media?.alt && (
            <div className="flex items-center gap-2">
              <span className="text-sm font-bold">Alt text:</span>
              <p>{media.alt}</p>
            </div>
          )}
        </div>
        {!!children && children}
        <div className="flex items-center gap-4">
          {actions &&
            actions.map((a, i) => {
              if (!a) return null;
              if (a.isDisabled) return null;

              const Icon = a.icon;

              return (
                <div key={`media-action-${i}`}>
                  <TooltipButton asChild={false} text={a.tooltipText}>
                    <Icon
                      className={a.className}
                      onClick={() => a.onClick(media)}
                    />
                  </TooltipButton>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default DisplayMedia;
