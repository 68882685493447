import {
  createDefaultFontFamilyOptions,
  Shape,
  ShapeFontFamilyOption,
} from "@pqina/pintura";
import WebFont from "webfontloader";

const cvgFonts = [
  "Arial Bold",
  "Avenir Next Bold",
  "Calibri Regular",
  "Helvetica Neue Regular",
].sort();

const petbarFonts = [
  "Moret Bold",
  "Moret Bold Oblique",
  "Moret Book",
  "Moret Book Oblique",
  "Moret Extrabold",
  "Moret Extrabold Oblique",
  "Moret Regular",
  "Moret Regular Oblique",
  "Moret Semibold",
  "Moret Semibold Oblique",
].sort();

const flamelFonts = ["Mathelo"].sort();

const googleFonts = [
  "Abril Fatface",
  "Amatic SC",
  "Arimo",
  "Arvo",
  "Asap",
  "Assistant",
  "Barlow",
  "Bitter",
  "Cabin",
  "Cambay",
  "Cantarell",
  "Caudex",
  "Chewy",
  "Cinzel",
  "Cormorant Garamond",
  "Dosis",
  "Exo",
  "Fira Sans",
  "Fira Mono",
  "Fira Sans Condensed",
  "Fjalla One",
  "Flexo",
  "Heebo",
  "Hind",
  "Inconsolata",
  "Inter",
  "Jost",
  "Judson",
  "Kaushan Script",
  "Lato",
  "Libre Baskerville",
  "Lobster",
  "Lora",
  "Merriweather",
  "Montserrat",
  "Noto Sans",
  "Nunito",
  "Open Sans",
  "Oswald",
  "Pacifico",
  "Poppins",
  "PT Sans",
  "PT Serif",
  "Playfair Display",
  "Quattrocento Sans",
  "Quicksand",
  "Raleway",
  "Ribeye",
  "Roboto",
  "Roboto Condensed",
  "Roboto Mono",
  "Rokkitt",
  "Rubik",
  "Sacramento",
  "Saira",
  "Sansita One",
  "Source Sans Pro",
  "Titillium Web",
  "Ubuntu",
  "Varela Round",
  "Work Sans",
].sort();

export const localFonts = [...flamelFonts, ...cvgFonts, ...petbarFonts].sort();
export const allFonts = [...localFonts, ...googleFonts].sort();

export const loadFonts = () => {
  WebFont.load({
    google: {
      families: googleFonts,
    },
    custom: { families: localFonts },
  });
};

export const fontFamilyOptions = (
  brandFont?: string,
  secondaryBrandFonts?: string[],
  restrictFonts?: boolean,
): ShapeFontFamilyOption[] => [
  // Brand fonts
  ...((brandFont ? [[brandFont, brandFont]] : []) as any),

  ...(secondaryBrandFonts?.map((font) => [font, font]) ?? []),

  ...(restrictFonts ? [] : allFonts.map((font) => [font, font])),

  // Add the default options
  ...(restrictFonts ? [] : createDefaultFontFamilyOptions()),
];

export const fontScaleOptions = {
  extraSmall: "5%",
  small: "10%",
  mediumSmall: "15%",
  medium: "20%",
  mediumLarge: "25%",
  large: "30%",
  extraLarge: "35%",
};

export const textOptions = (primaryBrandFont?: string): Shape => ({
  // Set default text shape background to transparent
  backgroundColor: [0, 0, 0, 0],

  // Set default text shape outline to 0 width and white
  textOutline: ["0%", [1, 1, 1]],

  // Set default text shadow, shadow will not be drawn if x, y, and blur are 0.
  textShadow: ["0%", "0%", "0%", [0, 0, 0, 0.5]],

  fontSize: "20%",

  fontFamily: primaryBrandFont ?? "Open Sans",

  // Align to center by default, this triggers always showing the text align control
  textAlign: "center",

  // Enable text formatting
  format: "html",
});
