import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { setUser } from "../../reducers/user";
import { FaFacebookSquare } from "react-icons/fa";
import { APIComponent, Module } from "../../api/withApi";
import { unpackQueryString } from "../../util/loaders";
import { Button } from "~/src/primitives/button";
import { FcGoogle } from "react-icons/fc";
import { Label } from "~/src/primitives/label";
import { Input } from "~/src/primitives/input";
import { useToast } from "~/src/primitives/use-toast";
import AuthLayout from "~/src/layouts/AuthLayout";
import { formatEmail, parseBool } from "~/src/util/reusables";
import canvaIcon from "~/src/assets/CanvaIcon.png";
import {
  MailchimpTag,
  getMailchimpEntryTagFromPathname,
} from "~/src/util/chimp";
import { completeCanvaConfiguration } from "../../api/canva.api";

type LoginProps = APIComponent;

const Login = ({ loginUser, loginWithMagicLink, getSession }: LoginProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Check for Canva params
  const [searchParams] = useSearchParams();
  const canva_user_token = searchParams.get("canva_user_token");
  const state = searchParams.get("state");
  const nonce = searchParams.get("nonce");
  const forceLogin = searchParams.get("forceLogin");
  const redirectUri = searchParams.get("redirectUri");
  const isForcedLogin = parseBool(forceLogin);
  const isCanvaLogin = !!canva_user_token && !!state && !!nonce;

  let tags: MailchimpTag[] = [];
  const referrerPathname = sessionStorage.getItem("flamelai-referrer-pathname");
  if (referrerPathname) {
    sessionStorage.removeItem("flamelai-referrer-pathname");
    const inferredEntry = getMailchimpEntryTagFromPathname(referrerPathname);
    if (inferredEntry) tags = inferredEntry;
  }

  const after = searchParams.get("after");
  if (after)
    sessionStorage.setItem("flamelai-after-login", searchParams.get("after"));

  const handleCanvaLogin = async (isManual = false) => {
    // Handle Canva App Auth
    if (isCanvaLogin) {
      try {
        await completeCanvaConfiguration(
          canva_user_token,
          state,
          nonce,
          isManual,
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const appendParamsToOAuth = (url: URL): string => {
    // Canva params
    if (isCanvaLogin) {
      url.searchParams.set("canva_user_token", canva_user_token);
      url.searchParams.set("state", state);
      url.searchParams.set("nonce", nonce);
    }

    // Redirect params
    if (redirectUri) {
      url.searchParams.set("redirectUri", redirectUri);
    }

    // Tag params
    tags?.forEach((t) => url.searchParams.append("tags", t));

    return url.toString();
  };

  const handleNormalLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const { target } = event ?? {};
    const [email, password] = (target as any) ?? [];
    const formattedEmail = formatEmail(email?.value ?? "");
    const res = await loginUser(formattedEmail, password?.value);
    if (res.ok) {
      const user = await res.json();
      const basePath = user?.module === Module.Paid ? "/paid" : "/";

      if (redirectUri) window.top.location.href = redirectUri;

      dispatch(setUser(user));
      // Handle Canva App Auth
      if (isCanvaLogin) {
        await handleCanvaLogin(true);
      }
      // Handle regular login
      else {
        navigate(`${after ?? basePath}`);
      }
    } else {
      const err = await res.json();
      toast({
        title: "Error signing in",
        description: err.message,
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const magicTokenLogin = async () => {
      const query = unpackQueryString();
      if (!query.magicToken) return;

      try {
        const response = await loginWithMagicLink(query.magicToken);
        if (response.status !== 200) throw new Error("Bad request");
        const user = await response.json();
        const basePath = user?.module === Module.Paid ? "/paid" : "/";
        dispatch(setUser(user));
        // Handle Canva App Auth
        if (isCanvaLogin) {
          await handleCanvaLogin();
        }
        // Handle regular login
        else {
          navigate(`${after ?? basePath}`);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const hasSession = async () => {
      try {
        const json = await getSession();
        const user = json.user;
        const basePath = user?.module === Module.Paid ? "/paid" : "/";
        if (user !== undefined) {
          // Handle Canva App Auth
          if (isCanvaLogin) {
            await handleCanvaLogin();
          } else {
            dispatch(setUser(user));
            navigate(`${after ?? basePath}`);
          }
        } else {
          dispatch(setUser(undefined));
          // Reset Toolkit
        }
      } catch (err) {
        console.log("Could not restore session.");
      }
    };

    if (!isForcedLogin) {
      hasSession();
      magicTokenLogin();
    }
  }, []);

  return (
    <AuthLayout
      integrationLogo={isCanvaLogin && canvaIcon}
      heading={
        isCanvaLogin ? "Connect Flamel.AI to Canva" : "Sign in to your account"
      }
      bottomLink="/signup"
      bottomLinkText="Not a member? Start a 14 day free trial"
      signInOnly={isCanvaLogin}
      imageSrc="https://flamel-misc.s3.amazonaws.com/SignInBackground.webp"
    >
      <form onSubmit={async (e) => await handleNormalLogin(e)}>
        <div className="grid gap-4">
          <div className="grid gap-1.5">
            <Label htmlFor="email">Email</Label>
            <Input
              required
              id="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
            />
          </div>
          <div className="grid gap-1.5">
            <Label htmlFor="password">Password</Label>
            <Input
              required
              id="password"
              placeholder="********"
              type="password"
              autoCapitalize="none"
              autoComplete="password"
              autoCorrect="off"
              disabled={isLoading}
            />
          </div>
          <Button disabled={isLoading}>
            {isLoading && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="mr-2 h-4 w-4 animate-spin"
              >
                <path d="M21 12a9 9 0 1 1-6.219-8.56" />
              </svg>
            )}
            Sign In with Email
          </Button>
          {!isCanvaLogin && (
            <Link
              to="/forgot-password"
              className="mt-2 px-8 text-center text-sm text-muted-foreground underline underline-offset-4 hover:text-primary"
            >
              I forgot my password...
            </Link>
          )}
        </div>
      </form>
      <div className="relative flex items-center gap-1">
        <span className="h-0 w-full flex-1 border-t" />
        <div className="relative flex w-fit justify-center text-xs uppercase">
          <span className="px-2 text-muted-foreground">Or continue with</span>
        </div>
        <span className="h-0 w-full flex-1 border-t" />
      </div>
      <div className="grid gap-2">
        <Button
          variant="outline"
          type="button"
          disabled={isLoading}
          onClick={() => {
            const googleUrl = new URL(
              "/api/google/login",
              window.location.origin,
            );
            window.top.location.href = appendParamsToOAuth(googleUrl);
          }}
        >
          {isLoading ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2 h-4 w-4 animate-spin"
            >
              <path d="M21 12a9 9 0 1 1-6.219-8.56" />
            </svg>
          ) : (
            <FcGoogle className="mr-2 h-4 w-4" />
          )}{" "}
          Google
        </Button>
        <Button
          variant="outline"
          type="button"
          disabled={isLoading}
          onClick={() => {
            const fbUrl = new URL(
              "/api/facebook/login-oauth",
              window.location.origin,
            );
            window.top.location.href = appendParamsToOAuth(fbUrl);
          }}
        >
          {isLoading ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2 h-4 w-4 animate-spin"
            >
              <path d="M21 12a9 9 0 1 1-6.219-8.56" />
            </svg>
          ) : (
            <FaFacebookSquare className="mr-2 h-4 w-4" />
          )}{" "}
          Facebook
        </Button>
      </div>
    </AuthLayout>
  );
};

export default Login;
