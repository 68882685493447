import { UserAccount } from "./auth.api";
import { Team } from "./team.api";
import { Guest } from "./guest.api";
import { Concept } from "./concepts.api";
import axios from "axios";
import { PlatformType } from "../util/platforms";

export interface OrganizationType {
  _id: string;
  owner: string | UserAccount;
  seats: UserAccount[];
  invites: string[];
  inviteTimestamps?: Record<string /* email */, number /* timestamp */>;
  workspaces: string[] | Team[];
  approvalWorkflows?: ApprovalWorkflow[];
  guests: Guest[];
  name?: string;
  locked?: boolean;
  maxWorkspacesQuantity?: number;
}

export interface ApprovalWorkflow {
  _id: string;
  organization: string;
  approvers: string[] | UserAccount[];
  name: string;
}

export function sanitizeEmail(email: string): string {
  return btoa(email);
}

export function desanitizeEmail(sanitizedEmail: string): string {
  return atob(sanitizedEmail);
}

const getOrganization = (q?: string) => fetch(`/api/organization${q ?? ""}`);

const updateOrganization = (diff: Partial<OrganizationType>) =>
  fetch(`/api/organization`, {
    method: "PATCH",
    body: JSON.stringify({
      ...diff,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const inviteToOrganization = (email: string) =>
  fetch(`/api/organization/invite`, {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const revokeOrganizationInvite = (email: string) =>
  fetch(`/api/organization/invite`, {
    method: "DELETE",
    body: JSON.stringify({
      email,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const kickUserFromOrganization = (userId: string) =>
  fetch(`/api/organization/kick/${userId}`, {
    method: "DELETE",
  });

const updateOrganizationUser = (userId: string, diff: Partial<UserAccount>) =>
  fetch(`/api/organization/users/${userId}`, {
    method: "PATCH",
    body: JSON.stringify({
      ...diff,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const transferOrganizationOwnership = (transferToId: string) =>
  fetch(`/api/organization/transfer/${transferToId}`, {
    method: "POST",
  });

const leaveOrganization = () =>
  fetch(`/api/organization/leave`, {
    method: "DELETE",
  });

export const getOrganizationWorkspaces = async () =>
  (await axios.get<Team[]>(`/api/organization/workspaces`)).data;

export type CreateOrganizationWorkspaceParams = {
  name?: string;

  // Seed from current account connection
  teamId?: string;
  platform?: PlatformType;
  platformAccountId?: string;
};
export const createOrganizationWorkspace = async (
  params: CreateOrganizationWorkspaceParams,
) => (await axios.post(`/api/organization/workspaces/`, params)).data;

export type CopyPlatformAccountToCurrentWorkspaceParams = Required<
  Omit<CreateOrganizationWorkspaceParams, "name">
>;
export const copyPlatformAccountToCurrentWorkspace = async (
  params: CopyPlatformAccountToCurrentWorkspaceParams,
) =>
  (
    await axios.patch(
      `/api/organization/workspaces/copy-platform-account`,
      params,
    )
  ).data;

const selectOrganizationWorkspace = (workspaceId: string) =>
  fetch(`/api/organization/workspaces/${workspaceId}`, {
    method: "POST",
  });

const deleteOrganizationWorkspace = (workspaceId: string) =>
  fetch(`/api/organization/workspaces/${workspaceId}`, {
    method: "DELETE",
  });

const addUserToOrganizationWorkspace = (workspaceId: string, userId: string) =>
  fetch(`/api/organization/workspaces/${workspaceId}/${userId}`, {
    method: "POST",
  });

const removeUserFromOrganizationWorkspace = (
  workspaceId: string,
  userId: string,
) =>
  fetch(`/api/organization/workspaces/${workspaceId}/${userId}`, {
    method: "DELETE",
  });

const updateOrganizationWorkspace = (
  workspaceId: string,
  diff: Partial<Team>,
) =>
  fetch(`/api/organization/workspaces/${workspaceId}`, {
    method: "PATCH",
    body: JSON.stringify({
      ...diff,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const createApprovalWorkflow = (name: string, approvers: string[]) =>
  fetch(`/api/organization/approval-workflows/`, {
    method: "POST",
    body: JSON.stringify({
      name,
      approvers,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const deleteApprovalWorkflow = (approvalWorkflowId: string) =>
  fetch(`/api/organization/approval-workflows/${approvalWorkflowId}`, {
    method: "DELETE",
  });

const updateApprovalWorkflow = (
  approvalWorkflowId: string,
  diff: Partial<ApprovalWorkflow>,
) =>
  fetch(`/api/organization/approval-workflows/${approvalWorkflowId}`, {
    method: "PATCH",
    body: JSON.stringify({
      ...diff,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const applyApprovalWorkflowToWorkspace = (
  workspaceId: string,
  approvalWorkflowId: string,
) =>
  fetch(
    `/api/organization/approval-workflows/${approvalWorkflowId}/workspace/${workspaceId}`,
    {
      method: "POST",
    },
  );

const removeApprovalWorkflowFromWorkspace = (workspaceId: string) =>
  fetch(`/api/organization/approval-workflows/workspace/${workspaceId}`, {
    method: "DELETE",
  });

export const getHubMedia = () =>
  axios.get(`/api/organization/hub/media`).then((res) => res.data);

const getHubTemplates = (hubId?: string): Promise<Response> => {
  const hubTemplatesUrl = new URL(
    "/api/organization/hub/templates",
    window.location.origin,
  );
  if (hubId) hubTemplatesUrl.searchParams.set("hubId", hubId);
  return fetch(hubTemplatesUrl);
};

const getHubConcepts = (hubId?: string): Promise<Response> => {
  const hubConceptsUrl = new URL(
    "/api/organization/hub/concepts",
    window.location.origin,
  );
  if (hubId) hubConceptsUrl.searchParams.set("hubId", hubId);
  return fetch(hubConceptsUrl);
};

export interface BaseAccount {
  id: string;
  name: string;
  pfp: string;
  connectedWorkspaceId: string;
  connectedWorkspaceName: string;
}
export type FacebookAccount = BaseAccount;
export interface InstagramProfile extends BaseAccount {
  actor_id: string;
  username: string;
}
export type TwitterProfile = BaseAccount;
export type LinkedInAccount = BaseAccount;
export type NormalizedPlatformAccount =
  | FacebookAccount
  | InstagramProfile
  | TwitterProfile
  | LinkedInAccount;
export interface AccountsResponse<T extends BaseAccount> {
  accounts: T[];
  selectedAccountId: string | undefined;
}
type ConnectedAccountsResponse = Record<
  PlatformType,
  AccountsResponse<NormalizedPlatformAccount>
>;
export const getOrganizationConnectedAccounts = () =>
  axios
    .get<ConnectedAccountsResponse>(`/api/organization/connected-accounts`)
    .then((res) => res.data);

export interface OrganizationAPI {
  getOrganization: (q?: string) => Promise<Response>;
  updateOrganization: (diff: Partial<OrganizationType>) => Promise<Response>;
  archiveOrganization: () => Promise<Response>;
  inviteToOrganization: (email: string) => Promise<Response>;
  revokeOrganizationInvite: (email: string) => Promise<Response>;
  kickUserFromOrganization: (userId: string) => Promise<Response>;
  updateOrganizationUser: (
    userId: string,
    diff: Partial<UserAccount>,
  ) => Promise<Response>;
  transferOrganizationOwnership: (transferToId: string) => Promise<Response>;
  leaveOrganization: () => Promise<Response>;
  selectOrganizationWorkspace: (workspaceId: string) => Promise<Response>;
  deleteOrganizationWorkspace: (workspaceId: string) => Promise<Response>;
  addUserToOrganizationWorkspace: (
    workspaceId: string,
    userId: string,
  ) => Promise<Response>;
  removeUserFromOrganizationWorkspace: (
    workspaceId: string,
    userId: string,
  ) => Promise<Response>;
  updateOrganizationWorkspace: (
    workspaceId: string,
    diff: Partial<Team>,
  ) => Promise<Response>;
  createApprovalWorkflow: (
    name: string,
    approvers: string[],
  ) => Promise<Response>;
  deleteApprovalWorkflow: (approvalWorkflowId: string) => Promise<Response>;
  updateApprovalWorkflow: (
    approvalWorkflowId: string,
    diff: Partial<ApprovalWorkflow>,
  ) => Promise<Response>;
  applyApprovalWorkflowToWorkspace: (
    workspaceId: string,
    approvalWorkflowId: string,
  ) => Promise<Response>;
  removeApprovalWorkflowFromWorkspace: (
    workspaceId: string,
  ) => Promise<Response>;
  getConcepts: (hubId?: string) => Promise<Concept[]>;
  getHubTemplates: (hubId?: string) => Promise<Response>;
  getHubConcepts: () => Promise<Response>;
}

export default {
  getOrganization,
  updateOrganization,
  inviteToOrganization,
  revokeOrganizationInvite,
  kickUserFromOrganization,
  updateOrganizationUser,
  transferOrganizationOwnership,
  leaveOrganization,
  deleteOrganizationWorkspace,
  selectOrganizationWorkspace,
  addUserToOrganizationWorkspace,
  removeUserFromOrganizationWorkspace,
  updateOrganizationWorkspace,
  createApprovalWorkflow,
  deleteApprovalWorkflow,
  updateApprovalWorkflow,
  applyApprovalWorkflowToWorkspace,
  removeApprovalWorkflowFromWorkspace,
  getHubTemplates,
  getHubConcepts,
} as OrganizationAPI;
