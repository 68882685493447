import "./fonts.css";
import "@pqina/pintura/pintura.css";
import "@pqina/pintura-video/pinturavideo.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "./prosemirror.css";
import "./style.css";

import React, { lazy, Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  createBrowserRouter,
  Navigate,
  NavigateFunction,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "react-error-boundary";
import API, { PortfolioItem } from "./api/withApi";

import { persistor, store } from "./store";
import { loadFonts } from "./lib/Editor/util/fonts";
import { withManifest } from "./partials/CheckManifest/CheckManifest";

import AppLayout from "./layouts/AppLayout";
import HubLayout from "./layouts/HubLayout";
import AnalyticsLayout from "./layouts/AnalyticsLayout";
import PaidLayout from "./layouts/PaidLayout";

import Load from "./partials/Load/Load";
import Fatal from "./views/Error/Fatal";

// Eagerly loaded auth components
import Login from "./views/Auth/Login";
import Signup from "./views/Auth/Signup";
import ForgotPassword from "./views/Auth/ForgotPassword";
import VerifyEmail from "./views/Auth/VerifyEmail";
import SetPassword from "./views/Auth/SetPassword";
import { TooltipProvider } from "./primitives/tooltip";

// Paid
// const PaidOverview = lazy(() => import("./views/Paid/Overview"));
const PaidCampaignManage = lazy(() => import("./views/Paid/Campaigns/Manage"));
const PaidCampaignList = lazy(() => import("./views/Paid/Campaigns/List"));
const PaidAnalytics = lazy(() => import("./views/Paid/Analytics"));
const PaidAdsetManage = lazy(() => import("./views/Paid/Adsets/Manage"));
const PaidAdManage = lazy(() => import("./views/Paid/Ads/Manage"));
const PaidAdCreativesList = lazy(() => import("./views/Paid/Adcreatives/List"));
const PaidAdCreativesManage = lazy(
  () => import("./views/Paid/Adcreatives/Manage"),
);
const PaidAdFormsList = lazy(() => import("./views/Paid/Forms/List"));
const PaidAdFormsManage = lazy(() => import("./views/Paid/Forms/Manage"));
const ListFormLeads = lazy(() => import("./views/Paid/Forms/Leads"));
const ListPaidTargeting = lazy(() => import("./views/Paid/Targeting/List"));
const ListPaidAudiences = lazy(() => import("./views/Paid/Audiences/List"));
const ListPaidAdLabels = lazy(() => import("./views/Paid/Adlabels/List"));
const MindbodyData = lazy(() => import("./views/Paid/Mindbody"));
// Lazy loaded components
const OrganicDashboard = lazy(
  () => import("./views/Dashboard/OrganicDashboard"),
);
const CalendarView = lazy(() => import("./views/Calendar"));
const Edit = lazy(() => import("./views/Edit/Edit"));
const Campaigns = lazy(() => import("./views/Campaigns"));
const PostDiscussion = lazy(
  () => import("./views/PostDiscussion/PostDiscussion"),
);
const Organization = lazy(() => import("./views/Organization/Organization"));
const GuestLayout = lazy(() => import("./views/Guest/GuestLayout"));
const GuestCalendar = lazy(() => import("./views/Guest/components/Calendar"));
const GuestPosts = lazy(() => import("./views/Guest/components/Posts"));
const GuestMedia = lazy(() => import("./views/Guest/components/Media"));
const GuestUpload = lazy(() => import("./views/Guest/components/Upload"));
const GuestDashboard = lazy(() => import("./views/Guest/components/Dashboard"));
const UnifiedInbox = lazy(
  () => import("./views/CommunityManagement/UnifiedInbox"),
);
const Admin = lazy(() => import("./views/Admin/Admin"));
const Profile = lazy(() => import("./views/Account/Profile"));
const Help = lazy(() => import("./views/Account/Help"));
const Conversion = lazy(() => import("./views/Conversion/Conversion"));
const Guides = lazy(() => import("./views/Guides"));
const SocialOnboarding = lazy(() => import("./views/SocialOnboarding"));
const Library = lazy(() => import("./views/Library/Library"));
const Drafts = lazy(() => import("./views/Drafts/Drafts"));
const ChainLayout = lazy(() => import("./views/Chain/ChainLayout"));
const CampaignDetail = lazy(() => import("./views/Campaigns/CampaignDetail"));
const PostDetail = lazy(() => import("./views/Post/PostDetail"));
const DraftDetail = lazy(() => import("./views/Post/DraftDetail"));
const Posts = lazy(() => import("./views/CommunityManagement/Posts"));
// const PostManagement = lazy(
//   () => import("./views/CommunityManagement/PostManagement"),
// );
const AccessDenied = lazy(
  () => import("./views/CommunityManagement/AccessDenied"),
);
const CampaignSpawner = lazy(() => import("./views/Campaigns/CampaignSpawner"));
const CommentManagement = lazy(
  () => import("./views/CommunityManagement/CommentManagement"),
);
const Billing = lazy(() => import("./views/Subscription/Billing"));
const WorkspaceBrand = lazy(() => import("./views/Brand"));
const Socials = lazy(() => import("./views/Socials/Socials"));
const IntegrationsView = lazy(() => import("./views/Integrations"));
const Error404 = lazy(() => import("./views/Error/404"));

// Analytics components
const Overview = lazy(() => import("./views/Analytics/Overview"));
const Audience = lazy(() => import("./views/Analytics/Audience"));
const Activity = lazy(() => import("./views/Analytics/Activity"));
const Impressions = lazy(() => import("./views/Analytics/Impressions"));
const Engagement = lazy(() => import("./views/Analytics/Engagement"));
const ByLocation = lazy(() => import("./views/Analytics/ByLocation"));
const Content = lazy(() => import("./views/Analytics/Content"));

// Hub components
const HubMedia = lazy(() => import("./views/Hubs/components/Media"));
const Concepts = lazy(() => import("./views/Concepts"));
const TemplatesView = lazy(() => import("./views/Templates"));
const Brand = lazy(() => import("./views/Brand"));
const BroadcastEditor = lazy(
  () => import("./views/Hubs/components/Locations/BroadcastEditor"),
);
const ConceptCalendar = lazy(
  () => import("./views/Hubs/components/ConceptCalendar"),
);
const LocationsTable = lazy(
  () => import("./views/Hubs/components/Locations/LocationsTable"),
);
const HubSettings = lazy(() => import("./views/Hubs/components/HubSettings"));
const Leaderboard = lazy(() => import("./views/Hubs/components/Leaderboard"));

declare global {
  interface Window {
    env: any;
  }
}
window.env = window.env || {};
export const queryClient = new QueryClient();

function RouterErrorBoundary() {
  const error = useRouteError();
  console.dir(error, { depth: null });
  return <Fatal error={error} />;
}

const PAID_ROUTES = {
  path: "paid",
  element: <PaidLayout />,
  children: [
    // { index: true, element: <PaidOverview /> },
    { index: true, element: <Navigate to="/paid/campaigns" /> },
    {
      path: "campaigns",
      children: [
        { index: true, element: <PaidCampaignList /> },
        {
          path: "create",
          element: <PaidCampaignManage key="create" />,
        },
        {
          path: ":campaignId",
          children: [
            { index: true, element: <PaidCampaignManage /> },
            {
              path: "adsets",
              children: [
                {
                  path: "create",
                  element: <PaidAdsetManage key="create" />,
                },
                {
                  path: ":adsetId",
                  children: [
                    { index: true, element: <PaidAdsetManage /> },
                    {
                      path: "ads",
                      children: [
                        {
                          path: "create",
                          element: <PaidAdManage key="create" />,
                        },
                        { path: ":adId", element: <PaidAdManage /> },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "adcreatives",
      children: [
        { index: true, element: <PaidAdCreativesList /> },
        {
          path: "create",
          element: <PaidAdCreativesManage key="create" />,
        },
        { path: ":adCreativeId", element: <PaidAdCreativesManage /> },
      ],
    },
    {
      path: "forms",
      children: [
        { index: true, element: <PaidAdFormsList /> },
        {
          path: "create",
          element: <PaidAdFormsManage key="create" />,
        },
        {
          path: ":formId",
          children: [
            { index: true, element: <PaidAdFormsManage /> },
            { path: "leads", element: <ListFormLeads /> },
          ],
        },
      ],
    },
    {
      path: "targeting",
      children: [
        {
          index: true,
          element: <ListPaidTargeting className="overflow-y-auto pt-16" />,
        },
      ],
    },
    {
      path: "adlabels",
      children: [{ index: true, element: <ListPaidAdLabels /> }],
    },
    {
      path: "audiences",
      children: [{ index: true, element: <ListPaidAudiences /> }],
    },
    { path: "analytics", element: <PaidAnalytics /> },
    { path: "mindbody/*", element: <MindbodyData /> },
  ],
};

const HUB_ROUTES = {
  path: "hub/:hubId",
  element: <HubLayout />,
  children: [
    { index: true, element: <OrganicDashboard /> },
    { path: "media", element: <HubMedia /> },
    { path: "concepts", element: <Concepts /> },
    { path: "templates", element: <TemplatesView canManageItems /> },
    { path: "brand", element: <Brand /> },
    { path: "drafts/:portfolioItemId", element: <BroadcastEditor /> },
    { path: "design", element: <Edit /> },
    { path: "design/template/:templateId", element: <Edit /> },
    { path: "calendar", element: <ConceptCalendar /> },
    {
      path: "drafts",
      element: (
        <Drafts
          onEdit={(
            pfi: PortfolioItem,
            navigate: NavigateFunction,
            hubId: string,
          ) => navigate(`/hub/${hubId}/drafts/${pfi._id}`)}
        />
      ),
    },
    {
      path: "analytics",
      element: <AnalyticsLayout />,
      children: [
        { index: true, element: <Overview /> },
        { path: "audience", element: <Audience /> },
        { path: "activity", element: <Activity /> },
        { path: "impressions", element: <Impressions /> },
        { path: "engagement", element: <Engagement /> },
        { path: "locations", element: <ByLocation /> },
        { path: "content", element: <Content /> },
        { path: "leaderboard", element: <Leaderboard canRefresh /> },
      ],
    },
    { path: "locations", element: <LocationsTable /> },
    { path: "settings", element: <HubSettings /> },
    { path: "socials", element: <Socials /> },
    { path: "profile", element: <Profile {...API} /> },
    { path: "help", element: <Help /> },
    { path: "integrations", element: <IntegrationsView /> },
    PAID_ROUTES,
  ],
};

const GUEST_ROUTES = {
  path: "/guest",
  element: <GuestLayout />,
  children: [
    { index: true, element: <GuestDashboard /> },
    { path: "calendar", element: <GuestCalendar /> },
    { path: "posts", element: <GuestPosts /> },
    { path: "media", element: <GuestMedia /> },
    { path: "upload", element: <GuestUpload /> },
    {
      path: "analytics",
      element: <AnalyticsLayout />,
      children: [
        { index: true, element: <Overview /> },
        { path: "audience", element: <Audience /> },
        { path: "activity", element: <Activity /> },
        { path: "impressions", element: <Impressions /> },
        { path: "engagement", element: <Engagement /> },
      ],
    },
    { path: "posts/:larvalId/discussion", element: <PostDiscussion /> },
    { path: "*", element: <Error404 /> },
  ],
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <RouterErrorBoundary />,
    children: [
      { index: true, element: <OrganicDashboard /> },
      HUB_ROUTES,
      { path: "socials", element: <Socials /> },
      { path: "integrations", element: <IntegrationsView /> },
      { path: "calendar", element: withManifest(<CalendarView />, "calendar") },
      { path: "media", element: withManifest(<Library />, "view_media") },
      { path: "concepts", element: <Concepts /> },
      {
        path: "drafts",
        element: withManifest(
          <Drafts
            onEdit={(pfi: PortfolioItem, navigate: NavigateFunction) =>
              navigate(`/drafts/${pfi._id}`)
            }
          />,
          "view_media",
        ),
      },
      {
        path: "posts",
        element: withManifest(<Posts />, "view_media", <AccessDenied />),
      },
      // As of 1/15/2025 - this is buggy and needs to be revisited
      // {
      //   path: "post/:postId",
      //   element: withManifest(<PostManagement />, "view_media"),
      // },
      { path: "engage", element: <CommentManagement /> },
      { path: "design", element: <Edit /> },
      { path: "design/template/:templateId", element: <Edit /> },
      { path: "templates", element: <TemplatesView canManageItems /> },
      { path: "campaigns", element: withManifest(<Campaigns />, "campaigns") },
      {
        path: "campaigns/spawn",
        element: withManifest(<CampaignSpawner />, "campaigns"),
      },
      {
        path: "campaigns/:campaignId",
        element: withManifest(<CampaignDetail />, "campaigns"),
      },
      {
        path: "analytics",
        element: <AnalyticsLayout />,
        children: [
          { index: true, element: <Overview /> },
          { path: "audience", element: <Audience /> },
          { path: "activity", element: <Activity /> },
          { path: "impressions", element: <Impressions /> },
          { path: "engagement", element: <Engagement /> },
          { path: "content", element: <Content /> },
          { path: "leaderboard", element: <Leaderboard /> },
        ],
      },
      { path: "profile", element: <Profile {...API} /> },
      { path: "help", element: <Help /> },
      { path: "billing", element: withManifest(<Billing />, "subscription") },
      {
        path: "organization",
        element: withManifest(<Organization />, "view_organization"),
      },
      { path: "post/:larvalId/discussion", element: <PostDiscussion /> },
      {
        path: "drafts/:portfolioItemId",
        element: withManifest(<DraftDetail />, "edit"),
      },
      {
        path: "post/:larvalId/edit",
        element: withManifest(<PostDetail />, "edit"),
      },
      { path: "brain", element: <ChainLayout /> },
      {
        path: "inbox",
        element: withManifest(<UnifiedInbox />, "inbox", <AccessDenied />),
      },
      { path: "brand", element: <WorkspaceBrand /> },
      PAID_ROUTES,
      // GRAVE YARD ☠️ ------------------------------
      /**
       * @deprecated - 11/08/2024 - No longer relevant, but a fun Easter Egg 🥚
       */
      { path: "guides", element: <Guides /> },
      // ----------------------------------------------
      { path: "*", element: <Error404 /> },
    ],
  },
  { path: "/login", element: <Login {...API} /> },
  { path: "/signup", element: <Signup {...API} /> },
  { path: "/welcome", element: <SocialOnboarding /> },
  { path: "/forgot-password", element: <ForgotPassword {...API} /> },
  { path: "/verify-email", element: <VerifyEmail /> },
  { path: "/set-password", element: <SetPassword /> },
  GUEST_ROUTES,
  // Utility routes
  { path: "/admin", element: withManifest(<Admin />, "admin") },
  { path: "/conversion", element: <Conversion /> },
  { path: "*", element: <Error404 /> },
]);

const App = () => {
  useEffect(() => {
    loadFonts();
  }, []);

  return (
    <Provider store={store}>
      <ErrorBoundary fallbackRender={({ error }) => <Fatal error={error} />}>
        <PersistGate loading={null} persistor={persistor}>
          <TooltipProvider>
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<Load />}>
                <RouterProvider router={router} fallbackElement={<Load />} />
              </Suspense>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </TooltipProvider>
        </PersistGate>
      </ErrorBoundary>
    </Provider>
  );
};

createRoot(document.getElementById("root")).render(<App />);

export default App;
