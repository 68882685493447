import { Loader } from "lucide-react";
import React, { useEffect, useState } from "react";
import { cn } from "~/src/util/reusables";

interface LoadProps {
  className?: string;
  messages?: string[];
  messageInterval?: number;
}

export default function Load({
  className,
  messages = [],
  messageInterval = 6000,
}: LoadProps) {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    if (messages.length <= 1) return;

    const interval = setInterval(() => {
      setCurrentMessageIndex((prev) => (prev + 1) % messages.length);
    }, messageInterval);

    return () => clearInterval(interval);
  }, [messages, messageInterval]);

  return (
    <div
      className={cn(
        "flex h-full w-full flex-col items-center justify-center gap-4",
        className,
      )}
    >
      <div className="animate-pulse">
        <Loader className="size-10 animate-spin text-muted-foreground" />
      </div>
      {messages.length > 0 && (
        <span className="animate-pulse text-base font-semibold uppercase tracking-wide text-muted-foreground">
          {messages[currentMessageIndex]}
        </span>
      )}
    </div>
  );
}
