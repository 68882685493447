import axios from "axios";

const BASE_URL = "/api/canva";

export const completeCanvaConfiguration = (
  canva_user_token: string,
  state: string,
  nonce: string,
  isManual = false,
) =>
  axios
    .get(`${BASE_URL}/configuration/complete`, {
      params: {
        canva_user_token,
        state,
        nonce,
        isManual,
      },
    })
    .then((res) => res.data);

export const deleteCanvaConfiguration = () =>
  axios
    .patch(`${BASE_URL}/configuration/delete/internal`)
    .then((res) => res.data);
