import axios from "axios";
import { SearchParams } from "../views/Admin/components/lib/data-table";
import { Role, UserAccount } from "./auth.api";
import { Post } from "./withApi";

const onboardUser = (user: UserAccount) =>
  fetch("/api/admin/onboard", {
    method: "POST",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json",
    },
  });
const generateMagicLink = (email: string, navigateToPasswordReset = false) =>
  fetch("/api/admin/magic-link", {
    method: "POST",
    body: JSON.stringify({ email, navigateToPasswordReset }),
    headers: {
      "Content-Type": "application/json",
    },
  });
const getAllLivePosts = (limit = 100) =>
  fetch(`/api/admin/posts?limit=${limit.toString()}`).then((res) => res.json());

const permanentlyDeletePost = (postId: string) =>
  fetch(`/api/admin/posts/${postId}`, {
    method: "DELETE",
  });
const addWorkspaceToOrganization = (
  organizationOwnerEmail: string,
  workspaceName: string,
) =>
  fetch("/api/admin/organization/workspace", {
    method: "POST",
    body: JSON.stringify({ organizationOwnerEmail, workspaceName }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const backfillTeamAnalytics = (workspaceId: string) =>
  fetch(`/api/admin/analytics/backfill/${workspaceId}`, {
    method: "POST",
    body: JSON.stringify({}),
    headers: {
      "Content-Type": "application/json",
    },
  });
const backfillAllWorkspacesAnalytics = () =>
  fetch(`/api/admin/analytics/backfill/all`, {
    method: "POST",
    body: JSON.stringify({}),
    headers: {
      "Content-Type": "application/json",
    },
  });
const initializeWorkspaceCm = (workspaceId: string) =>
  fetch(`/api/admin/community/ingest/${workspaceId}`);

const getPlatformHashtags = (
  page: number,
  limit: number,
  search?: Omit<SearchParams, "setSearch">,
) => {
  const getUrl = new URL(`/api/admin/hashtags`, window.location.origin);

  getUrl.searchParams.set("page", page.toString());
  getUrl.searchParams.set("limit", limit.toString());

  if (search) getUrl.searchParams.set("search", JSON.stringify(search ?? []));

  return fetch(getUrl);
};

const getPlatformAccountTags = (
  page: number,
  limit: number,
  search?: Omit<SearchParams, "setSearch">,
) => {
  const getUrl = new URL(`/api/admin/account-tags`, window.location.origin);

  getUrl.searchParams.set("page", page.toString());
  getUrl.searchParams.set("limit", limit.toString());

  if (search) getUrl.searchParams.set("search", JSON.stringify(search ?? []));

  return fetch(getUrl);
};

const getPlatformComments = (
  page: number,
  limit: number,
  search?: Omit<SearchParams, "setSearch">,
) => {
  const getUrl = new URL(`/api/admin/comments`, window.location.origin);

  getUrl.searchParams.set("page", page.toString());
  getUrl.searchParams.set("limit", limit.toString());

  if (search) getUrl.searchParams.set("search", JSON.stringify(search ?? []));

  return fetch(getUrl);
};

const getPlatformData = (page: number, limit: number) => {
  const getUrl = new URL(`/api/admin/platform-data`, window.location.origin);

  getUrl.searchParams.set("page", page.toString());
  getUrl.searchParams.set("limit", limit.toString());

  return fetch(getUrl);
};

export const extendTrial = async (
  email: string,
  trialEndTimestamp: number,
): Promise<{ message: string; trialEndDate: string; createdAt: string }> => {
  const response = await axios.post<{
    message: string;
    trialEndDate: string;
    createdAt: string;
  }>("/api/admin/extend-trial", { email, trialEndTimestamp });
  return response.data;
};

export const getTrial = async (
  email: string,
): Promise<{ trialEndDate: string; createdAt: string }> => {
  const response = await axios.post<{
    trialEndDate: string;
    createdAt: string;
  }>("/api/admin/trial-info", { email });
  return response.data;
};

export const updateOrganizationWorkspaceQuota = async (
  email: string,
  { maxWorkspaces }: { maxWorkspaces: number },
): Promise<{ message: string; maxWorkspaces: number }> => {
  const response = await axios.post<{ message: string; maxWorkspaces: number }>(
    "/api/admin/update-organization-workspace-quota",
    { email, maxWorkspaces },
  );
  return response.data;
};

export const transferUserToOrganization = async (
  userEmail: string,
  destinationOwnerEmail: string,
  role: Role,
) =>
  (
    await axios.post(`/api/admin/transfer-user`, {
      userEmail,
      destinationOwnerEmail,
      role,
    })
  ).data;

export interface AdminAPI {
  getAllLivePosts: (limit?: number) => Promise<Post[]>;
  onboardUser: (user: UserAccount) => Promise<Response>;
  generateMagicLink: (
    email: string,
    navigateToPasswordReset?: boolean,
  ) => Promise<Response>;
  permanentlyDeletePost: (postId: string) => Promise<Response>;
  addWorkspaceToOrganization: (
    organizationOwnerEmail: string,
    workspaceName: string,
  ) => Promise<Response>;
  backfillTeamAnalytics: (workspaceId: string) => Promise<Response>;
  backfillAllWorkspacesAnalytics: () => Promise<Response>;
  initializeWorkspaceCm: (workspaceId: string) => Promise<Response>;
  getPlatformHashtags: (
    page: number,
    limit: number,
    search?: Omit<SearchParams, "setSearch">,
  ) => Promise<Response>;
  getPlatformAccountTags: (
    page: number,
    limit: number,
    search?: Omit<SearchParams, "setSearch">,
  ) => Promise<Response>;
  getPlatformComments: (
    page: number,
    limit: number,
    search?: Omit<SearchParams, "setSearch">,
  ) => Promise<Response>;
  getPlatformData: (page: number, limit: number) => Promise<Response>;
}

export default {
  onboardUser,
  generateMagicLink,
  getAllLivePosts,
  permanentlyDeletePost,
  addWorkspaceToOrganization,
  backfillTeamAnalytics,
  backfillAllWorkspacesAnalytics,
  initializeWorkspaceCm,
  getPlatformHashtags,
  getPlatformAccountTags,
  getPlatformComments,
  getPlatformData,
} as AdminAPI;
