import axios from "axios";
import { RotateCcw } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button } from "~/src/primitives/button";
import { RootState } from "../../store";

interface ErrorDetails {
  message: string;
  stack?: string;
  code?: string;
}

interface FatalProps {
  error: ErrorDetails | any;
}

export default function Fatal({ error }: FatalProps) {
  const location = useLocation?.();
  const user = useSelector((state: RootState) => state.user);
  const [isReporting, setIsReporting] = useState(false);

  useEffect(() => {
    const reportError = async () => {
      setIsReporting(true);
      try {
        await axios.post("/api/admin/report-error", {
          title: "[Fatal Error] - Client is stuck...",
          location,
          error,
          user: user?._id ?? null,
        });
      } catch (err) {
        console.error("Error reporting to backend:", err);
      } finally {
        setIsReporting(false);
      }
    };

    // Only run on production
    if (process.env.NODE_ENV === "development") return;

    // Check if we've already tried reloading
    const hasReloaded = sessionStorage.getItem("fatalErrorReload");

    if (!hasReloaded) {
      // Set reload flag and reload page
      sessionStorage.setItem("fatalErrorReload", "true");
      window.top?.location?.reload();
    } else {
      // Clear reload flag and report error
      sessionStorage.removeItem("fatalErrorReload");
      reportError();
    }
  }, [user, error, location]);

  return (
    <div className="grid min-h-full place-items-center bg-background px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-red-500">OOPS!</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-primary sm:text-5xl">
          An error has occurred
        </h1>
        <p className="mt-6 text-base leading-7 text-secondary-foreground">
          Our team has been notified. Please reach out if the issue persists.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button
            onClick={() => window.top?.location?.reload()}
            disabled={isReporting}
          >
            <RotateCcw className="size-3" />
            {isReporting ? "Reporting error..." : "Reload the page"}
          </Button>

          <a
            href="mailto:hello@flamel.ai"
            className="text-sm font-semibold text-primary hover:underline"
          >
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </div>
  );
}
