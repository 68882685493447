import axios from "axios";
import { APIKey } from "../../views/Hubs/components/HubSettings";

export const connectLumin = async (locationId: string) => {
  return (await axios.get(`/api/lumin/activate?locationId=${locationId}`)).data;
};

export const disconnectLumin = async () =>
  (await axios.get("/api/lumin/disconnect")).data;

export const getApiKeys = async (hubId: string) => {
  const response = await axios.get(`/api/hubs/${hubId}/keys`);
  return response.data;
};

export const createApiKey = async (hubId: string, newKey: APIKey) => {
  const response = await axios.post(`/api/hubs/${hubId}/keys/create`, newKey, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const deleteApiKey = async (hubId: string, key: string) => {
  const response = await axios.delete(`/api/hubs/${hubId}/keys/delete/${key}`);
  return response.data;
};
