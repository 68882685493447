
      import API from "!../../node_modules/.pnpm/style-loader@4.0.0_webpack@5.88.2/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../node_modules/.pnpm/style-loader@4.0.0_webpack@5.88.2/node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../node_modules/.pnpm/style-loader@4.0.0_webpack@5.88.2/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../node_modules/.pnpm/style-loader@4.0.0_webpack@5.88.2/node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../node_modules/.pnpm/style-loader@4.0.0_webpack@5.88.2/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../node_modules/.pnpm/style-loader@4.0.0_webpack@5.88.2/node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.88.2/node_modules/css-loader/dist/cjs.js!../../node_modules/.pnpm/postcss-loader@8.1.1_postcss@8.5.1_typescript@4.9.5_webpack@5.88.2/node_modules/postcss-loader/dist/cjs.js!./prosemirror.css";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = insertFn.bind(null, "head");
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.88.2/node_modules/css-loader/dist/cjs.js!../../node_modules/.pnpm/postcss-loader@8.1.1_postcss@8.5.1_typescript@4.9.5_webpack@5.88.2/node_modules/postcss-loader/dist/cjs.js!./prosemirror.css";
       export default content && content.locals ? content.locals : undefined;
