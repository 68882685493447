import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/src/primitives/dialog";
import { Team } from "~/src/api/team.api";
import SocialAccounts from "~/src/views/Account/components/SocialAccounts";

interface WorkspaceSocialsModalProps {
  show: boolean;
  workspace?: Partial<Team>;
  onHide: () => void;
  onUpdate?: () => void | Promise<void>;
}

const WorkspaceSocialsModal: React.FC<WorkspaceSocialsModalProps> = ({
  show,
  workspace,
  onHide,
  onUpdate = () => {},
}) => (
  <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
    <DialogContent className="flex h-full flex-col overflow-y-auto p-0 sm:max-h-[90vh]">
      <DialogHeader className="sr-only">
        <DialogTitle>Workspace Social Accounts</DialogTitle>
      </DialogHeader>
      <SocialAccounts
        workspace={workspace as Team}
        onUpdate={onUpdate}
        autoCopy
      />
    </DialogContent>
  </Dialog>
);

export default WorkspaceSocialsModal;
