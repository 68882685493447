import React, { useMemo, useState, Fragment } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import API, { Role, Team } from "~/src/api/withApi";
import {
  createOrganizationWorkspace,
  copyPlatformAccountToCurrentWorkspace,
  CreateOrganizationWorkspaceParams,
  getOrganizationConnectedAccounts,
  CopyPlatformAccountToCurrentWorkspaceParams,
  NormalizedPlatformAccount,
} from "~/src/api/organization.api";
import { Button } from "~/src/primitives/button";
import {
  Command,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandSeparator,
} from "~/src/primitives/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/src/primitives/popover";
import { RootState } from "~/src/store";
import {
  cn,
  NewSocialToLogo,
  TooltipButton,
  withPfp,
  withQueryParams,
} from "~/src/util/reusables";
import xIcon from "~/src/assets/XIcon.png";
import linkedinIcon from "~/src/assets/LinkedInIcon.png";
import instagramIcon from "~/src/assets/InstagramIcon.png";
import facebookIcon from "~/src/assets/FacebookIcon.png";
import metaIcon from "~/src/assets/MetaIcon.png";
import { setUser } from "~/src/reducers/user";
import { Link } from "react-router-dom";
import { Gate } from "~/src/gates";
import { setError, setSuccess } from "~/src/reducers/toolkit";
import {
  AlertTriangle,
  ExternalLink,
  Check,
  ChevronsUpDown,
  MoreHorizontal,
  Copy,
  Workflow,
  ArrowRight,
  TriangleAlert,
  MapPinPlus,
  RefreshCw,
  CircleX,
  Info,
} from "lucide-react";
import {
  ALL_PLATFORMS,
  getPlatformName,
  PlatformType,
} from "~/src/util/platforms";
import { Label } from "../../../primitives/label";
import { getCurrentWorkspaceAdAccounts } from "~/src/api/team.api";
import { getCurrentHubAdAccounts, Hub } from "~/src/api/hub.api";
import Load from "../../../partials/Load/Load";
import {
  disconnectMetaAdsAccount,
  getFacebookAccounts,
  syncFacebookLocations,
  // getInstagramAccounts,
} from "../../../api/meta.api";
import { openMetaAdsManagerEditor } from "../../../api/paid/meta";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuLabel,
} from "../../../primitives/dropdown-menu";
import { AxiosError } from "axios";
import {
  AlertDialog,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogFooter,
} from "../../../primitives/alert-dialog";
import { Separator } from "../../../primitives/separator";
import {
  removeSocialAccount,
  syncSocialAccounts,
} from "../../../api/socialaccounts.api";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../../primitives/hover-card";

const icons = {
  [PlatformType.Twitter]: xIcon,
  [PlatformType.LinkedIn]: linkedinIcon,
  [PlatformType.Instagram]: instagramIcon,
  [PlatformType.Facebook]: facebookIcon,
};

type SocialAccountsProps = {
  className?: string;
  workspace: Team;
  onUpdate?: () => void | Promise<void>;
  platforms?: PlatformType[];
  autoCopy?: boolean;
};

export default function SocialAccounts({
  className,
  workspace,
  onUpdate = () => {},
  platforms = ALL_PLATFORMS,
  autoCopy = false,
}: SocialAccountsProps) {
  const dispatch = useDispatch();
  const canManageSocials = useSelector(
    (state: RootState) => state.user?.manifest?.manage_socials,
  );
  const hasFullSocialAccess = useSelector(
    (state: RootState) => state.user?.manifest?.full_social,
  );
  const hasPaidAccess = useSelector(
    (state: RootState) =>
      state.user?.manifest?.paid && state.user?.module === "paid",
  );
  const embedBaseUrl = useSelector(
    (state: RootState) => state.user?.embedBaseUrl,
  );
  const isOrganizationScope = useSelector((state: RootState) =>
    Gate.isOrganizationScope(state.user),
  );
  const isHubScope = useSelector((state: RootState) =>
    Boolean(state.user?.hub),
  );
  const hub = useSelector((state: RootState) => state.user?.hub);
  const userOrganizationRole = useSelector(
    (state: RootState) => state.user?.organizationRole,
  );
  const isSuperAdmin = userOrganizationRole === Role.SUPER_ADMIN;
  const userOrganizationName = useSelector(
    (state: RootState) => state.user?.organization?.name,
  );

  const [confirmPlatformAccountCopy, setConfirmPlatformAccountCopy] = useState<
    (NormalizedPlatformAccount & { platform: PlatformType }) | null
  >(null);
  const [adAccountOpen, setAdAccountOpen] = useState(false);

  const updateWorkspaceMutation = useMutation({
    mutationFn: (diff: Partial<Team>) =>
      isOrganizationScope
        ? API.updateOrganizationWorkspace(workspace?._id, diff)
        : API.updateIndividualWorkspace(workspace?._id, diff),
    onSuccess: () => {
      API.getSession("?update=true").then(({ user }) =>
        dispatch(setUser(user)),
      );
    },
    onError: () => {
      dispatch(setError("You're not allowed to manage this workspace."));
    },
  });

  const updateHubMutation = useMutation({
    mutationFn: (diff: Partial<Hub>) => API.updateHub(hub?._id, diff),
    onSuccess: () => {
      API.getSession("?update=true").then(({ user }) =>
        dispatch(setUser(user)),
      );
    },
    onError: () => {
      dispatch(setError("You're not allowed to manage this hub."));
    },
  });

  const handleConnectSocial = async (platform: PlatformType) => {
    const endpoints = {
      [PlatformType.Twitter]: "/api/twitter/login-oauth",
      [PlatformType.LinkedIn]: "/api/linkedin/login-oauth",
      [PlatformType.Instagram]: "/api/facebook/instagram/login-oauth",
      [PlatformType.Facebook]: "/api/facebook/login-oauth",
    };
    window.top.location.href = withQueryParams(endpoints[platform], {
      redirect: embedBaseUrl,
    });
    await onUpdate();
  };

  const handleDisconnectSocial = async (platform: PlatformType) => {
    const endpoints = {
      [PlatformType.Twitter]: "/api/twitter/disconnect",
      [PlatformType.LinkedIn]: "/api/linkedin/disconnect",
      [PlatformType.Instagram]: "/api/facebook/instagram/disconnect",
      [PlatformType.Facebook]: "/api/facebook/disconnect",
    };
    const updatedUser = await fetch(endpoints[platform]).then((data) =>
      data.json(),
    );
    dispatch(setUser(updatedUser));
    await onUpdate();
  };

  const handleChangeAccount = async (
    platform: PlatformType,
    accountIndex: number,
  ) => {
    await updateWorkspaceMutation.mutateAsync({
      [platform]: {
        ...workspace[platform],
        selectedAccount:
          platform === PlatformType.LinkedIn && accountIndex === -1
            ? null
            : accountIndex,
      },
    });
    API.ingestPosts(platform).catch(() => {});
  };

  const socialPlatforms = useMemo(() => {
    if (isHubScope) return null;

    return platforms.map((platform) => (
      <SocialPlatform
        key={`${platform}-${(
          (workspace?.[platform] as any)?.selectedAccount ?? -1
        )?.toString()}`}
        platform={platform}
        workspace={workspace}
        handleConnectSocial={handleConnectSocial}
        handleDisconnectSocial={handleDisconnectSocial}
        handleChangeAccount={handleChangeAccount}
      />
    ));
  }, [workspace, hasPaidAccess, isHubScope]);

  const { data: adaccounts, isLoading: isLoadingAdAccounts } = useQuery({
    queryKey: ["adaccounts", isHubScope, workspace?._id],
    queryFn: () =>
      isHubScope ? getCurrentHubAdAccounts() : getCurrentWorkspaceAdAccounts(),
    enabled: isHubScope || !!workspace?._id,
  });

  const { data: fbAccounts, isLoading: isLoadingFbAccounts } = useQuery({
    queryKey: ["fbaccounts"],
    queryFn: () => getFacebookAccounts(),
    enabled: isHubScope,
  });

  // const { data: igAccounts, isLoading: isLoadingIgAccounts } = useQuery({
  //   queryKey: ["igaccounts"],
  //   queryFn: () => getInstagramAccounts(),
  //   enabled: isHubScope
  // });

  const {
    data: connectedAccounts = {},
    isLoading: isLoadingConnectedAccounts,
  } = useQuery({
    queryKey: ["connectedAccounts"],
    queryFn: () => getOrganizationConnectedAccounts(),
    enabled: !isHubScope && isSuperAdmin,
  });

  const disconnectMetaAdAccountMutation = useMutation({
    mutationFn: disconnectMetaAdsAccount,
    onSuccess: async (updatedUser) => {
      dispatch(setUser(updatedUser));
      queryClient.invalidateQueries({ queryKey: ["connectedAccounts"] });
      await onUpdate();
    },
    onError: (error) => {
      dispatch(
        setError(error.message ?? "Failed to disconnect Meta Ad Account"),
      );
    },
  });

  const queryClient = useQueryClient();
  const createWorkspaceMutation = useMutation({
    mutationFn: (params: CreateOrganizationWorkspaceParams) =>
      createOrganizationWorkspace(params),
    onSuccess: async ({ _id }) => {
      queryClient.invalidateQueries({ queryKey: ["workspaces"] });
      queryClient.invalidateQueries({ queryKey: ["currentOrganization"] });
      return API.updateWorkspace(_id).then(() =>
        API.getSession("?update=true").then(({ user }) =>
          dispatch(setUser(user)),
        ),
      );
    },
    onError: (error: AxiosError<{ error: string }>) => {
      if (error.response?.data?.error) {
        dispatch(setError(error.response.data.error));
      }
      console.error(error);
    },
  });

  const copyPlatformAccountMutation = useMutation({
    mutationFn: (params: CopyPlatformAccountToCurrentWorkspaceParams) =>
      copyPlatformAccountToCurrentWorkspace(params),
    onSuccess: async (_, { platform }) => {
      queryClient.invalidateQueries({ queryKey: ["workspaces"] });
      queryClient.invalidateQueries({ queryKey: ["currentOrganization"] });
      await API.getSession("?update=true").then(({ user }) =>
        dispatch(setUser(user)),
      );
      dispatch(
        setSuccess(`${getPlatformName(platform)} account copied to workspace.`),
      );
    },
    onError: (error: AxiosError<{ error: string }>) => {
      if (error.response?.data?.error) {
        dispatch(setError(error.response.data.error));
      }
      console.error(error);
    },
  });

  const syncFacebookStoreLocations = useMutation({
    mutationFn: () => syncFacebookLocations(),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["workspaces"] });
      queryClient.invalidateQueries({ queryKey: ["currentOrganization"] });
      await API.getSession("?update=true").then(({ user }) =>
        dispatch(setUser(user)),
      );
      dispatch(setSuccess("Facebook store locations synced successfully"));
    },
    onError: (error: AxiosError<{ error: string }>) => {
      if (error.response?.data?.error) {
        dispatch(setError(error.response.data.error));
      } else {
        dispatch(setError("Failed to sync Facebook store locations"));
      }
      console.error(error);
    },
  });

  if (
    isLoadingAdAccounts ||
    isLoadingFbAccounts ||
    isLoadingConnectedAccounts ||
    createWorkspaceMutation.isPending ||
    copyPlatformAccountMutation.isPending ||
    syncFacebookStoreLocations.isPending
  )
    return <Load />;

  if (!canManageSocials) {
    return (
      <div className="w-full p-6 @xl:p-12">
        <div className="rounded-lg border border-yellow-400 bg-yellow-100 p-4">
          <div className="flex gap-3">
            <AlertTriangle className="mt-1 h-5 w-5 shrink-0 text-yellow-600 sm:mt-0" />
            <p className="text-yellow-700">
              You don&apos;t have permission to manage social accounts for this
              workspace. Please contact your administrator for access.
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (!workspace) return null;

  const selectedMetaAdAccountId = isHubScope
    ? hub?.selectedMetaAdAccountId
    : workspace?.selectedMetaAdAccountId;

  return (
    <div className="flex h-full w-full flex-col overflow-hidden @container">
      <div
        className={cn(
          "flex h-full w-full flex-col justify-center gap-4 overflow-y-auto overflow-x-hidden rounded-xl border bg-muted p-4 pb-8 shadow-lg @lg:flex @[1200px]:flex-row",
          className,
        )}
      >
        <div
          className={cn(
            "flex h-auto w-full flex-col gap-4 @[1200px]:w-2/3",
            isHubScope && "mx-auto max-w-7xl @[1200px]:w-full",
          )}
        >
          <h5 className="text-lg font-semibold text-gray-800">
            {isHubScope ? "Paid Social Accounts" : "Social Accounts"}
            {!isHubScope && (
              <span className="ml-2 text-sm font-normal italic text-gray-600">
                (Workspace: {workspace?.name})
              </span>
            )}
          </h5>
          {!hasFullSocialAccess && (
            <p className="text-xs text-gray-700">
              You&apos;re limited to one social connection!{" "}
              <Link to="/billing" className="text-blue-600 hover:underline">
                Upgrade now
              </Link>{" "}
              for a full social set.
            </p>
          )}
          {hasPaidAccess && (
            <>
              <div className="flex w-full flex-col justify-between gap-4 rounded-lg border bg-background p-4 shadow-sm @xl:flex-row @xl:flex-wrap @xl:items-center">
                <div className="flex items-center gap-2">
                  <img
                    crossOrigin="anonymous"
                    src={metaIcon}
                    alt=""
                    className="h-8 w-8 shrink-0 rounded-full border object-contain shadow"
                  />
                  <span className="font-semibold">Meta Ads Manager</span>
                </div>
                {adaccounts?.length === 0 ? (
                  <p className="max-w-sm text-balance text-right text-xs text-yellow-600">
                    {isHubScope
                      ? "No Meta ad accounts found. Reconnect Instagram and Facebook on a workspace wihtin the hub to see the options here."
                      : "No Meta ad accounts found. Reconnect Instagram and Facebook to see ad accounts."}
                  </p>
                ) : (
                  <div className="w-full sm:w-auto">
                    <Label
                      htmlFor="adaccount-select"
                      className="mb-1 block text-xs font-semibold text-gray-600"
                    >
                      Ad Account
                    </Label>
                    <div className="flex items-center gap-1">
                      <Popover
                        open={adAccountOpen}
                        onOpenChange={setAdAccountOpen}
                      >
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            role="combobox"
                            aria-expanded={adAccountOpen}
                            className="w-full justify-between truncate bg-background text-xs sm:w-64"
                            disabled={!adaccounts?.length}
                          >
                            {selectedMetaAdAccountId
                              ? adaccounts.find(
                                  (account) =>
                                    account?.account_id ===
                                    selectedMetaAdAccountId,
                                )?.name || "Select ad account?..."
                              : "Select ad account?..."}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-full p-0 sm:w-64">
                          <Command
                            filter={(value, search) => {
                              const adaccount = adaccounts.find(
                                (a) => a.account_id === value,
                              );
                              const searchString =
                                `${adaccount?.name} (${adaccount?.account_id})`.toLowerCase();
                              if (searchString.includes(search.toLowerCase()))
                                return 1;
                              return 0;
                            }}
                          >
                            <CommandInput
                              placeholder="Search ad accounts..."
                              className="h-9"
                            />
                            <CommandList>
                              <CommandEmpty>No ad account found.</CommandEmpty>
                              <CommandGroup>
                                {adaccounts?.map((adaccount) => (
                                  <CommandItem
                                    key={adaccount?.account_id}
                                    value={adaccount?.account_id}
                                    onSelect={(value) => {
                                      if (isHubScope) {
                                        updateHubMutation.mutateAsync({
                                          selectedMetaAdAccountId: value,
                                        });
                                      } else {
                                        updateWorkspaceMutation.mutateAsync({
                                          selectedMetaAdAccountId: value,
                                        });
                                      }
                                      setAdAccountOpen(false);
                                    }}
                                  >
                                    {adaccount?.name} ({adaccount?.account_id})
                                    {selectedMetaAdAccountId ===
                                      adaccount?.account_id && (
                                      <Check className="ml-auto h-4 w-4" />
                                    )}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      {!!selectedMetaAdAccountId && (
                        <>
                          <TooltipButton text="Open in Meta Ads Manager">
                            <Button
                              variant="ghost"
                              size="icon-sm"
                              onClick={() =>
                                openMetaAdsManagerEditor("campaigns", {
                                  act: selectedMetaAdAccountId,
                                })
                              }
                            >
                              <ExternalLink className="h-4 w-4" />
                              <span className="sr-only">
                                Open in Meta Ads Manager
                              </span>
                            </Button>
                          </TooltipButton>
                          <Button
                            className="h-8 shrink-0 px-3 text-xs font-semibold"
                            variant="secondary"
                            size="sm"
                            onClick={async () => {
                              await disconnectMetaAdAccountMutation.mutateAsync();
                            }}
                          >
                            Disconnect
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {isHubScope && (
                <>
                  <div className="flex w-full flex-col justify-between gap-4 rounded-lg border bg-background p-4 shadow-sm @xl:flex-row @xl:flex-wrap @xl:items-center">
                    <div className="flex items-center gap-2">
                      <img
                        crossOrigin="anonymous"
                        src={facebookIcon}
                        alt=""
                        className="h-8 w-8 shrink-0 rounded-full border object-contain shadow"
                      />
                      <span className="font-semibold">Facebook Page</span>
                    </div>
                    {fbAccounts?.accounts?.length === 0 && (
                      <p className="max-w-sm text-balance text-xs text-yellow-600">
                        No Facebook pages found. Reconnect Facebook on a
                        workspace within the hub to see the options here.
                      </p>
                    )}
                    <div className="w-full sm:w-auto">
                      <Label
                        htmlFor="fbaccount-select"
                        className="mb-1 block text-xs font-semibold text-gray-600"
                      >
                        Facebook Page
                      </Label>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            role="combobox"
                            className="w-full justify-between bg-background text-xs sm:w-64"
                            disabled={!fbAccounts?.accounts?.length}
                          >
                            {hub?.selectedFacebookPage ? (
                              <div className="flex items-center">
                                <img
                                  crossOrigin="anonymous"
                                  src={withPfp(
                                    hub.selectedFacebookPage.pfp,
                                    hub.selectedFacebookPage.name ||
                                      "Placeholder",
                                  )}
                                  className="mr-2 h-6 w-6 rounded-full border object-cover shadow"
                                />
                                <span className="truncate">
                                  {hub.selectedFacebookPage.name ||
                                    "Placeholder"}
                                </span>
                              </div>
                            ) : (
                              "Select Facebook page..."
                            )}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[--radix-popover-trigger-width] p-0">
                          <Command>
                            <CommandInput
                              placeholder={`Search Facebook pages...`}
                            />
                            <CommandList>
                              <CommandEmpty>
                                No Facebook pages found.
                              </CommandEmpty>
                              <CommandGroup>
                                {fbAccounts?.accounts?.map((fbAccount) => (
                                  <CommandItem
                                    key={fbAccount?.id}
                                    onSelect={() => {
                                      updateHubMutation.mutateAsync({
                                        selectedFacebookPage: fbAccount,
                                      });
                                    }}
                                  >
                                    <div className="flex items-center">
                                      <img
                                        crossOrigin="anonymous"
                                        src={withPfp(
                                          fbAccount?.pfp,
                                          fbAccount?.name || "Placeholder",
                                        )}
                                        className="mr-2 h-6 w-6 rounded-full border object-cover shadow"
                                      />
                                      <span className="text-xs">
                                        {fbAccount?.name || "Placeholder"}
                                      </span>
                                    </div>
                                    {hub?.selectedFacebookPage?.id ===
                                      fbAccount?.id && (
                                      <Check className="ml-auto h-4 w-4" />
                                    )}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {socialPlatforms}
        </div>

        {/* Connected Accounts Column */}
        {!isHubScope && isSuperAdmin && (
          <div className="max-h-98 flex h-full w-auto flex-col rounded-lg border bg-background shadow-sm @[1200px]:w-1/3">
            <div className="flex items-center justify-between pl-4 pr-2 pt-2">
              <h6 className="font-semibold">Browse existing connections...</h6>
              <HoverCard>
                <HoverCardTrigger>
                  <Button
                    variant="outline"
                    size="tinycircle"
                    className="whitespace-nowrap text-[10px]"
                  >
                    <Info className="h-3 w-3" />
                    What is this?
                  </Button>
                </HoverCardTrigger>
                <HoverCardContent
                  className="flex flex-col gap-2 text-xs font-medium"
                  side="bottom"
                  align="end"
                  sideOffset={70}
                >
                  <p>
                    Super Admins of{" "}
                    <i className="font-semibold">
                      &quot;{userOrganizationName}&quot;
                    </i>{" "}
                    can access all existing social connections within the
                    Organization.
                  </p>
                  <p>
                    These social connections can be shared with other
                    workspaces.
                  </p>
                  <Separator />
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2 font-semibold">
                      Click the menu icon{" "}
                      <MoreHorizontal className="h-4 w-4 text-muted-foreground" />{" "}
                      to either:
                    </div>
                    <ul className="ml-6 flex list-disc flex-col gap-1">
                      <li>Copy an account to your current workspace</li>
                      <li>Create a new workspace with the account</li>
                    </ul>
                  </div>
                </HoverCardContent>
              </HoverCard>
            </div>
            <Command className="h-full max-h-full">
              <CommandInput placeholder="Search accounts..." />
              <CommandList className="h-full max-h-full">
                <CommandEmpty>No connected accounts found</CommandEmpty>

                {Object.keys(connectedAccounts)?.map(
                  (platform: PlatformType, idx) => (
                    <Fragment key={platform}>
                      {idx > 0 && <CommandSeparator />}
                      <CommandGroup
                        heading={
                          <div className="flex w-fit items-center gap-2 rounded-md bg-muted px-2 py-1">
                            <img
                              crossOrigin="anonymous"
                              src={icons[platform]}
                              alt=""
                              className="h-4 w-4 shrink-0 rounded-full border object-contain shadow"
                            />
                            <span className="font-semibold">
                              {getPlatformName(platform)}
                            </span>
                          </div>
                        }
                      >
                        {connectedAccounts?.[platform]?.accounts.map(
                          (account) => (
                            <CommandItem key={`${platform}-${account?.id}`}>
                              <div className="flex w-full items-center justify-between">
                                <div className="flex items-center gap-2">
                                  <img
                                    crossOrigin="anonymous"
                                    src={withPfp(account?.pfp, account?.name)}
                                    className="h-6 w-6 rounded-full border object-cover shadow"
                                  />
                                  <div className="flex flex-col truncate">
                                    <span className="truncate">
                                      {account?.name}
                                    </span>
                                  </div>
                                </div>
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                    <Button variant="ghost" size="icon">
                                      <MoreHorizontal className="h-4 w-4" />
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent align="end">
                                    <DropdownMenuLabel>
                                      Actions
                                    </DropdownMenuLabel>
                                    <DropdownMenuItem
                                      className="flex items-center gap-2"
                                      onClick={() =>
                                        autoCopy
                                          ? copyPlatformAccountMutation.mutate({
                                              platform,
                                              teamId:
                                                account?.connectedWorkspaceId,
                                              platformAccountId: account?.id,
                                            })
                                          : setConfirmPlatformAccountCopy({
                                              ...account,
                                              platform,
                                            })
                                      }
                                    >
                                      <Copy className="size-4" /> Copy to this
                                      workspace
                                    </DropdownMenuItem>
                                    <DropdownMenuSeparator />
                                    <DropdownMenuItem
                                      className="flex items-center gap-2"
                                      onClick={() => {
                                        createWorkspaceMutation.mutate({
                                          platform,
                                          teamId: account?.connectedWorkspaceId,
                                          platformAccountId: account?.id,
                                        });
                                      }}
                                    >
                                      <Workflow className="size-4" /> Create new
                                      workspace
                                    </DropdownMenuItem>
                                    {platform === PlatformType.Facebook && (
                                      <>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem
                                          className="flex items-center gap-2"
                                          onClick={() =>
                                            syncFacebookStoreLocations.mutate()
                                          }
                                        >
                                          <MapPinPlus className="size-4" />
                                          Sync store locations
                                        </DropdownMenuItem>
                                      </>
                                    )}
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </div>
                            </CommandItem>
                          ),
                        )}
                      </CommandGroup>
                    </Fragment>
                  ),
                )}
              </CommandList>
            </Command>
          </div>
        )}
      </div>
      <AlertDialog
        open={!!confirmPlatformAccountCopy}
        onOpenChange={() => setConfirmPlatformAccountCopy(null)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="text-balance text-center font-semibold">
              Are you sure?
            </AlertDialogTitle>
            <AlertDialogDescription className="flex flex-col gap-4">
              {confirmPlatformAccountCopy && (
                <div className="mx-auto flex w-fit items-center justify-center gap-3 rounded-lg border p-2">
                  <img
                    crossOrigin="anonymous"
                    src={withPfp(
                      confirmPlatformAccountCopy?.pfp,
                      confirmPlatformAccountCopy?.name,
                    )}
                    className="h-10 w-10 rounded-full border object-cover shadow"
                  />
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold">
                      {confirmPlatformAccountCopy?.name}
                    </span>
                  </div>
                </div>
              )}
              <div className="flex w-full flex-wrap items-center justify-center gap-1 text-balance text-center">
                Copying{" "}
                <span className="inline-flex items-center gap-0.5">
                  <NewSocialToLogo
                    platform={confirmPlatformAccountCopy?.platform}
                  />
                  {getPlatformName(confirmPlatformAccountCopy?.platform)}
                </span>{" "}
                account from{" "}
                <span className="rounded-md bg-muted px-1 py-0.5 font-mono text-xs text-muted-foreground">
                  {confirmPlatformAccountCopy?.connectedWorkspaceName}
                </span>{" "}
                <ArrowRight className="size-3" />{" "}
                <span className="rounded-md bg-muted px-1 py-0.5 font-mono text-xs text-muted-foreground">
                  {workspace?.name}
                </span>
              </div>
              <Separator />
              <p className="flex w-full items-center gap-1 whitespace-nowrap text-center text-sm text-muted-foreground text-yellow-600">
                <TriangleAlert className="size-4 fill-yellow-200" /> This will
                override any existing{" "}
                <span className="inline-flex items-center gap-0.5">
                  <NewSocialToLogo
                    platform={confirmPlatformAccountCopy?.platform}
                  />
                  {getPlatformName(confirmPlatformAccountCopy?.platform)}
                </span>{" "}
                connections in this workspace.
              </p>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => setConfirmPlatformAccountCopy(null)}
            >
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                if (confirmPlatformAccountCopy) {
                  copyPlatformAccountMutation.mutate({
                    platform: confirmPlatformAccountCopy?.platform,
                    teamId: confirmPlatformAccountCopy?.connectedWorkspaceId,
                    platformAccountId: confirmPlatformAccountCopy?.id,
                  });
                }
                setConfirmPlatformAccountCopy(null);
              }}
            >
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

type SocialPlatformProps = {
  platform: PlatformType;
  workspace: Team;
  handleConnectSocial: (platform: PlatformType) => Promise<void>;
  handleDisconnectSocial: (platform: PlatformType) => Promise<void>;
  handleChangeAccount: (
    platform: PlatformType,
    accountIndex: number,
  ) => Promise<void>;
  className?: string;
};

const SocialPlatform = React.memo(
  ({
    platform,
    workspace,
    handleConnectSocial,
    handleDisconnectSocial,
    handleChangeAccount,
    className,
  }: SocialPlatformProps) => {
    const dispatch = useDispatch();
    const userOrganizationRole = useSelector(
      (state: RootState) => state.user?.organizationRole,
    );
    const isSuperAdmin = userOrganizationRole === Role.SUPER_ADMIN;
    const platformData = workspace?.[platform] as any;
    const accounts =
      platform === PlatformType.LinkedIn
        ? platformData?.orgs || []
        : platform === PlatformType.Twitter
          ? [
              {
                id: platformData?.id ?? platformData?.handle,
                name: platformData?.name,
                username: platformData?.handle,
                pfp: platformData?.pfp,
              },
            ]
          : platformData?.accounts || [];

    const selectedAccount =
      platformData?.selectedAccount ??
      (platform === PlatformType.LinkedIn ? -1 : 0);
    const isConnectionExpired =
      workspace?.expiredConnections?.includes(platform);

    const getAccountInfo = (index: number) => {
      if (platform === PlatformType.LinkedIn && index === -1) {
        return { pfp: platformData?.pfp, name: platformData?.name };
      }
      return accounts[index] || {};
    };

    const currentAccount = getAccountInfo(selectedAccount);

    const queryClient = useQueryClient();

    const syncSocialAccountsMutation = useMutation({
      mutationFn: () => {
        if (isSuperAdmin) return syncSocialAccounts(platform);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["connectedAccounts"] });
        API.getSession("?update=true").then(({ user }) => {
          dispatch(setUser(user));
          dispatch(
            setSuccess(
              `${getPlatformName(platform)} accounts synced successfully`,
            ),
          );
        });
      },
    });

    return (
      <div
        className={cn(
          "flex w-full flex-col justify-between gap-4 rounded-lg border bg-background p-4 shadow-sm @xl:flex-row @xl:flex-wrap",
          isConnectionExpired && "border-destructive bg-red-100",
          className,
        )}
      >
        {isConnectionExpired && (
          <div className="flex items-center gap-2 text-sm text-destructive">
            <AlertTriangle className="h-4 w-4" />
            <span>Expired</span>
          </div>
        )}
        <div className="flex items-center gap-2">
          <img
            crossOrigin="anonymous"
            src={icons[platform]}
            alt=""
            className="h-8 w-8 shrink-0 rounded-full border object-contain shadow"
          />
          <span className="font-semibold">{getPlatformName(platform)}</span>
        </div>
        {Boolean(platformData) && (
          <div className="flex flex-grow flex-col items-start justify-start gap-4 @xl:flex-row @xl:flex-wrap @xl:items-center @xl:justify-end">
            <div className="w-full sm:w-auto">
              <Label
                htmlFor="account-select"
                className="mb-1 block text-xs font-semibold text-gray-600"
              >
                Account
              </Label>
              <Popover>
                <div className="flex items-center gap-1">
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      className="w-full justify-between truncate bg-background text-xs sm:w-64"
                    >
                      <div className="flex items-center">
                        <img
                          crossOrigin="anonymous"
                          src={withPfp(
                            currentAccount?.profile_picture_url ??
                              currentAccount?.pfp,
                            currentAccount?.name || "Placeholder",
                          )}
                          className="mr-2 h-6 w-6 rounded-full border object-cover shadow"
                        />
                        <span className="truncate text-xs">
                          {currentAccount?.username ||
                            currentAccount?.name ||
                            "Placeholder"}
                        </span>
                      </div>
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  {isSuperAdmin &&
                    [PlatformType.Facebook, PlatformType.Instagram].includes(
                      platform as any,
                    ) && (
                      <TooltipButton
                        text={`Sync ${getPlatformName(platform)} accounts`}
                      >
                        <Button
                          variant="ghost"
                          size="icon-sm"
                          onClick={() => syncSocialAccountsMutation.mutate()}
                        >
                          <RefreshCw
                            className={cn(
                              "size-4",
                              syncSocialAccountsMutation.isPending &&
                                "animate-spin",
                            )}
                          />
                        </Button>
                      </TooltipButton>
                    )}
                </div>
                <PopoverContent className="w-[--radix-popover-trigger-width] p-0">
                  <Command>
                    <CommandInput
                      placeholder={`Search ${getPlatformName(
                        platform,
                      )} accounts...`}
                    />
                    <CommandList className="max-h-full">
                      <CommandEmpty>No accounts found.</CommandEmpty>
                      <CommandGroup>
                        {accounts.map((account, index) => (
                          <CommandItem
                            key={index}
                            onSelect={() =>
                              handleChangeAccount(platform, index)
                            }
                          >
                            <div className="flex w-full items-center justify-between">
                              <div className="flex items-center gap-2">
                                {selectedAccount === index && (
                                  <Check className="h-4 w-4" />
                                )}
                                <img
                                  crossOrigin="anonymous"
                                  src={withPfp(
                                    account?.profile_picture_url ??
                                      account?.pfp,
                                    account?.name || "Placeholder",
                                  )}
                                  className="mr-2 h-6 w-6 rounded-full border object-cover shadow"
                                />
                                <span className="text-xs">
                                  {account?.username ||
                                    account?.name ||
                                    "Placeholder"}
                                </span>
                              </div>
                              <SocialAccountActionsDropdown
                                platform={platform}
                                platformAccountId={String(account.id)}
                              />
                            </div>
                          </CommandItem>
                        ))}
                        {platform === PlatformType.LinkedIn &&
                          platformData?.name && (
                            <CommandItem
                              onSelect={() => handleChangeAccount(platform, -1)}
                            >
                              <div className="flex w-full items-center justify-between">
                                <div className="flex items-center gap-2">
                                  {selectedAccount === -1 && (
                                    <Check className="h-4 w-4" />
                                  )}
                                  <img
                                    crossOrigin="anonymous"
                                    src={withPfp(
                                      platformData?.pfp,
                                      platformData?.name,
                                    )}
                                    className="h-6 w-6 rounded-full border object-cover shadow"
                                  />
                                  <span className="text-xs">
                                    {platformData?.name || "Personal Account"}
                                  </span>
                                </div>
                                <SocialAccountActionsDropdown
                                  platform={platform}
                                  platformAccountId={String(platformData.id)}
                                />
                              </div>
                            </CommandItem>
                          )}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        )}
        <div className="flex w-auto items-center justify-end gap-2">
          {platformData ? (
            <>
              <Button
                className="h-8 shrink-0 px-3 text-xs font-semibold"
                size="sm"
                onClick={async () => {
                  await handleDisconnectSocial(platform);
                  await handleConnectSocial(platform);
                  await queryClient.invalidateQueries({
                    queryKey: ["connectedAccounts"],
                  });
                }}
              >
                Renew Login
              </Button>
              <Button
                className="h-8 shrink-0 px-3 text-xs font-semibold"
                variant="secondary"
                size="sm"
                onClick={async () => {
                  await handleDisconnectSocial(platform);
                  await queryClient.invalidateQueries({
                    queryKey: ["connectedAccounts"],
                  });
                }}
                data-testid={`${platform}-disconnect`}
              >
                Disconnect
              </Button>
            </>
          ) : (
            <Button
              className="h-8 shrink-0 px-3 text-xs font-semibold"
              onClick={() => handleConnectSocial(platform)}
              data-testid={`${platform}-connect`}
            >
              Connect {getPlatformName(platform)}
            </Button>
          )}
        </div>
      </div>
    );
  },
);

SocialPlatform.displayName = "SocialPlatform";

type SocialAccountActionsDropdownProps = {
  platform: PlatformType;
  platformAccountId: string;
};

type RemoveSocialAccount = Pick<
  SocialAccountActionsDropdownProps,
  "platform" | "platformAccountId"
>;

const SocialAccountActionsDropdown = ({
  platform,
  platformAccountId,
}: SocialAccountActionsDropdownProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const removeSocialAccountMutation = useMutation({
    mutationFn: ({ platform, platformAccountId }: RemoveSocialAccount) =>
      removeSocialAccount(platform, platformAccountId),
    onSuccess: (_, { platform }) => {
      queryClient.invalidateQueries({ queryKey: ["connectedAccounts"] });
      API.getSession("?update=true").then(({ user }) => {
        dispatch(setUser(user));
        dispatch(
          setSuccess(
            `${getPlatformName(platform)} account removed successfully`,
          ),
        );
      });
    },
    onError: (error: AxiosError<any>) => {
      dispatch(
        setError(
          error?.response?.data?.message ||
            error?.message ||
            "Error removing account",
        ),
      );
    },
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon-sm"
          className="dropdown-menu ml-2 h-6 w-6"
        >
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          className="flex items-center gap-1 text-xs text-destructive"
          onClick={(e) => {
            e.stopPropagation();
            removeSocialAccountMutation.mutate({ platform, platformAccountId });
          }}
        >
          <CircleX className="size-3" /> Remove Account
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

SocialPlatform.displayName = "SocialPlatform";
