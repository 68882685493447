import axios from "axios";
import { PlatformType } from "~/src/util/platforms";

/**
 * Remove a social media account from the workspace
 * @param platform The social media platform to remove the account from
 * @param platformAccountId The ID of the account to remove
 * @returns Promise that resolves when account is removed
 */
export const removeSocialAccount = async (
  platform: PlatformType,
  platformAccountId: string,
): Promise<void> => {
  await axios.delete(`/api/socialaccounts/${platform}/${platformAccountId}`);
};

/**
 * Sync social media accounts
 * @param platform The social media platform to sync
 * @returns Promise that resolves when accounts are synced
 */
export const syncSocialAccounts = async (
  platform: PlatformType,
): Promise<void> => {
  await axios.post(`/api/socialaccounts/${platform}/sync`);
};
