// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section {
  padding: 2rem 0;
  border-bottom: 1px solid hsl(var(--border));
}

.section-label {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.section .value {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.section .value * {
  vertical-align: middle;
  padding-top: auto;
  padding-bottom: auto;
}

.profile-photo {
  height: 64px;
  border-radius: 50vh;
  border: white 2px solid;
  box-shadow: 0px 2px 4px #efefef;
  vertical-align: middle;
  padding: 0px;
}

.social-account {
  height: 48px;
  border-radius: 50%;
  border: white 2px solid;
  box-shadow: 0px 2px 4px #efefef;
  vertical-align: middle;
}

.selected-account {
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
  border-radius: 5px;
  padding-right: 10px;
  opacity: 0.8;
  transition: all 150ms ease-in;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}

.selected-account:hover {
  opacity: 1;
}

.selected-account img {
  width: 100%;
  border-radius: 50vh;
}
`, "",{"version":3,"sources":["webpack://./ui/src/views/Account/account.style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,2CAA2C;AAC7C;;AAEA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,+BAA+B;EAC/B,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,+BAA+B;EAC/B,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,6BAA6B;EAC7B,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".section {\n  padding: 2rem 0;\n  border-bottom: 1px solid hsl(var(--border));\n}\n\n.section-label {\n  color: #333;\n  font-size: 14px;\n  font-weight: bold;\n  padding-bottom: 0.5rem;\n}\n\n.section .value {\n  display: flex;\n  justify-content: space-between;\n  padding-top: 10px;\n}\n\n.section .value * {\n  vertical-align: middle;\n  padding-top: auto;\n  padding-bottom: auto;\n}\n\n.profile-photo {\n  height: 64px;\n  border-radius: 50vh;\n  border: white 2px solid;\n  box-shadow: 0px 2px 4px #efefef;\n  vertical-align: middle;\n  padding: 0px;\n}\n\n.social-account {\n  height: 48px;\n  border-radius: 50%;\n  border: white 2px solid;\n  box-shadow: 0px 2px 4px #efefef;\n  vertical-align: middle;\n}\n\n.selected-account {\n  width: 30px;\n  height: 30px;\n  position: relative;\n  display: inline-block;\n  border-radius: 5px;\n  padding-right: 10px;\n  opacity: 0.8;\n  transition: all 150ms ease-in;\n  user-select: none;\n  cursor: pointer;\n}\n\n.selected-account:hover {\n  opacity: 1;\n}\n\n.selected-account img {\n  width: 100%;\n  border-radius: 50vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
