import axios from "axios";
import { UserAccount } from "./auth.api";
import { BrandType } from "./brand.api";
import { Concept } from "./concepts.api";
import { IPseudostructures } from "./foldering.api";
import { Media } from "./media.api";
import { FacebookAccount, InstagramProfile } from "./meta.api";
import { LarvalPost } from "./portfolio.api";
import {
  LuminIntegration,
  MindbodyIntegration,
  Team,
  TeamSnapshot,
} from "./team.api";
import { Template } from "./template.api";
import { Location } from "./locations.api";
import { PaginatedResponse } from "../util/table-utils";
import { LocationEntry } from "../views/Hubs/components/Locations/LocationsTable";
export interface Hub {
  _id?: string;
  name: string;
  description?: string;
  bound: Team[];
  admins: string[] | UserAccount[];
  media?: Media[];
  concepts?: Concept[];
  templates?: Template[];
  icon?: string | Media;
  brand?: BrandType;
  snapshot?: HubSnapshot;
  previousSnapshot?: HubSnapshot;
  pseudostructure?: IPseudostructures;
  apiKeys?: Array<{ id: string; key: string }>;

  integrations?: {
    mindbody?: MindbodyIntegration;
    lumin?: LuminIntegration;
  };

  /**
   * Tracking fields for Meta Ads syncing
   */
  selectedMetaAdAccountId?: string;
  selectedFacebookPage?: FacebookAccount;
  selectedInstagramProfile?: InstagramProfile;

  paidMetaAdCampaignsLastSyncTimestamp?: number;
  paidMetaAdCreativesLastSyncTimestamp?: number;
  paidMetaAdFormsLastSyncTimestamp?: number;
  paidMetaCustomAudiencesLastSyncTimestamp?: number;
  paidMetaSavedAudiencesLastSyncTimestamp?: number;
  paidMetaAdLabelsLastSyncTimestamp?: number;
}

export interface CategoryWinner {
  team: Team;
  value: number;
}

export interface PlatformWinners {
  instagram?: CategoryWinner;
  facebook?: CategoryWinner;
  linkedin?: CategoryWinner;
  cumulative?: CategoryWinner;
}

export interface HubSnapshot {
  _id?: string;
  createdAt: string;
  hub: Hub;
  teamSnapshots: TeamSnapshot[];
  mostActive?: PlatformWinners;
  longestStreak?: PlatformWinners;
  audienceGrowth?: PlatformWinners;
  rankJump?: PlatformWinners;
}

const getHubs = () => fetch("/api/hubs");

const getHub = async (hubId: string) =>
  (await axios.get<Hub>(`/api/hubs/${hubId}`))?.data;

const createHub = (name: string, description?: string) =>
  fetch(`/api/hubs/${name}`, {
    method: "POST",
    body: JSON.stringify({
      description,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const updateHub = (hubId: string, diff: Partial<Hub>) =>
  fetch(`/api/hubs/${hubId}`, {
    method: "PATCH",
    body: JSON.stringify({ diff }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const deleteHub = (hubId: string) =>
  fetch(`/api/hubs/${hubId}`, {
    method: "DELETE",
  });

const bindHubToWorkspace = (hubId: string, workspaceId: string) =>
  fetch(`/api/hubs/${hubId}/workspace/${workspaceId}`, {
    method: "POST",
  });

const bindHubToManyWorkspaces = (hubId: string, workspaceIds: string[]) => {
  const changeUrl = new URL(
    `/api/hubs/${hubId}/workspaces`,
    window.location.origin,
  );
  workspaceIds.forEach((wid) =>
    changeUrl.searchParams.append("workspaceIds", wid),
  );
  return fetch(changeUrl, {
    method: "PATCH",
  });
};

const unbindHubFromWorkspace = (hubId: string, workspaceId: string) =>
  fetch(`/api/hubs/${hubId}/workspace/${workspaceId}`, {
    method: "DELETE",
  });

const getHubAdmins = (hubId: string) => fetch(`/api/hubs/${hubId}/admin`);

const addHubAdmin = (hubId: string, email: string) =>
  fetch(`/api/hubs/${hubId}/admin/${email}`, {
    method: "POST",
  });

const removeHubAdmin = (hubId: string, email: string) =>
  fetch(`/api/hubs/${hubId}/admin/${email}`, {
    method: "DELETE",
  });

const getHubOrgs = async (hubId: string) => {
  const getHubOrgsUrl = new URL(
    `/api/hubs/${hubId}/organizations`,
    window.location.origin,
  );
  const response = await axios.get(getHubOrgsUrl.toString());
  return response.data;
};

export const getCurrentHubLocations = async () => {
  const url = new URL(`/api/hubs/locations`, window.location.origin);
  const { data } = await axios.get<PaginatedResponse<LocationEntry>>(
    url.toString(),
  );
  return data;
};

const addHubOrg = (hubId: string, email: string) =>
  fetch(`/api/hubs/${hubId}/organization/add?email=${email}`, {
    method: "PATCH",
  });

const removeHubOrg = (hubId: string, orgId: string) =>
  fetch(`/api/hubs/${hubId}/organization/${orgId}`, {
    method: "DELETE",
  });

const cloneMediaToWorkspace = async (mediaId: string, hubId: string) => {
  const result = await axios.post(`/api/hubs/${hubId}/media/${mediaId}/clone`);
  return result.data;
};
const cloneMediaFromWorkspace = async (mediaId: string, hubId: string) => {
  const result = await axios.post(`/api/hubs/${hubId}/media/${mediaId}`);
  return result.data;
};

const getHubSnapshot = (hubId: string, until: string) =>
  fetch(`/api/hubs/${hubId}/snapshots/${until}`).then((data) => data.json());
const initializeHubMemberAnalytics = (hubId: string) => {
  const initializeHubSnapshotUrl = new URL(
    `/api/hubs/${hubId}/snapshots/initialize`,
    window.location.origin,
  );

  return fetch(initializeHubSnapshotUrl, { method: "POST" });
};
const captureHubSnapshot = (
  hubId: string,
  until: string,
  forcePeriod?: "week" | "month",
) => {
  const captureHubSnapshotUrl = new URL(
    `/api/hubs/${hubId}/snapshots/capture/${until}`,
    window.location.origin,
  );
  if (forcePeriod)
    captureHubSnapshotUrl.searchParams.set("forcePeriod", forcePeriod);

  return fetch(captureHubSnapshotUrl, { method: "POST" });
};
const getBroadcasts = async (hubId: string) =>
  (await axios.get(`/api/hubs/${hubId}/broadcasts`)).data;

const createBroadcast = async (
  hubId: string,
  portfolioItemId: string,
  timestamp: number,
) =>
  (
    await axios.post(
      `/api/hubs/${hubId}/broadcast/${portfolioItemId}`,
      { timestamp },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
  ).data;

const deleteBroadcast = (hubId: string, larvalId: string) =>
  fetch(`/api/hubs/${hubId}/broadcast/${larvalId}`, {
    method: "DELETE",
  });

const getHubBrand = async (hubId: string) => {
  const res = await axios(`/api/hubs/${hubId}/brand`);
  return res.data;
};

interface AdAccount {
  account_id: string;
  [key: string]: any;
}

export const getCurrentHubAdAccounts = async (): Promise<AdAccount[]> => {
  const response = await axios.get<AdAccount[]>(`/api/hubs/adaccounts`);
  return response.data;
};

export const getCurrentHubBoundWorkspaces = async (): Promise<
  { _id: string; name: string; location: Location }[]
> => {
  const response =
    await axios.get<{ _id: string; name: string; location: Location }[]>(
      `/api/hubs/bound`,
    );
  return response.data;
};

export interface HubAPI {
  getHubs: () => Promise<Response>;
  getHub: (hubId: string) => Promise<Hub>;
  createHub: (name: string, description?: string) => Promise<Response>;
  updateHub: (hubId: string, diff: Partial<Hub>) => Promise<Response>;
  deleteHub: (hubId: string) => Promise<Response>;
  bindHubToWorkspace: (hubId: string, workspaceId: string) => Promise<Response>;
  bindHubToManyWorkspaces: (
    hubId: string,
    workspaceIds: string[],
  ) => Promise<Response>;
  unbindHubFromWorkspace: (
    hubId: string,
    workspaceId: string,
  ) => Promise<Response>;
  getHubAdmins: (hubId: string) => Promise<Response>;
  addHubAdmin: (hubId: string, email: string) => Promise<Response>;
  removeHubAdmin: (hubId: string, email: string) => Promise<Response>;
  getHubOrgs: (hubId: string) => Promise<any[]>;
  addHubOrg: (hubId: string, orgId: string) => Promise<Response>;
  removeHubOrg: (hubId: string, orgId: string) => Promise<Response>;
  cloneMediaToWorkspace: (mediaId: string, hubId: string) => Promise<any>;
  cloneMediaFromWorkspace: (mediaId: string, hubId: string) => Promise<any>;
  getHubSnapshot: (hubId: string, until: string) => Promise<any>;
  initializeHubMemberAnalytics: (hubId: string) => Promise<Response>;
  captureHubSnapshot: (
    hubId: string,
    until: string,
    forcePeriod?: "week" | "month",
  ) => Promise<Response>;
  getBroadcasts: (hubId: string) => Promise<LarvalPost[]>;
  createBroadcast: (
    hubId: string,
    portfolioItemId: string,
    timestamp: number,
  ) => Promise<LarvalPost[]>;
  deleteBroadcast: (hubId: string, larvalId: string) => Promise<Response>;
  getHubBrand: (hubId: string) => Promise<any>;
}

export default {
  getHubs,
  getHub,
  createHub,
  updateHub,
  deleteHub,

  bindHubToWorkspace,
  bindHubToManyWorkspaces,
  unbindHubFromWorkspace,
  getHubAdmins,
  addHubAdmin,
  removeHubAdmin,
  getHubOrgs,
  addHubOrg,
  removeHubOrg,
  cloneMediaToWorkspace,
  cloneMediaFromWorkspace,
  getHubSnapshot,
  initializeHubMemberAnalytics,
  captureHubSnapshot,
  getBroadcasts,
  createBroadcast,
  deleteBroadcast,
  getHubBrand,
} as HubAPI;

export const getPrimaryHubIdForWorkspace = (workspace: Partial<Team>) =>
  (workspace?.primaryHub as Hub)?._id ??
  (workspace?.primaryHub as string) ??
  (workspace?.hubs?.[0] as Hub)?._id ??
  (workspace?.hubs?.[0] as string);
