import React, { useContext, useEffect, useState } from "react";
import API, { Team } from "~/src/api/withApi";
import { Guest, GuestDashboardOption, GuestOptions } from "~/src/api/guest.api";
import { Button } from "~/src/primitives/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { Ghost, HelpCircle, Info } from "lucide-react";
import { useDispatch } from "react-redux";
import { setUser } from "~/src/reducers/user";
import { OrganizationContext } from "../../Organization";
import Selector from "~/src/partials/Tagger/Selector";
import { guestDashboardOptionToIcon, pluralize } from "~/src/util/reusables";
import { capitalize } from "lodash";
import { Input } from "~/src/primitives/input";
import { CampaignOption, PostFiltersRequired, months } from "../Guests";
import { Separator } from "~/src/primitives/separator";
import FadeIn from "~/src/partials/Transitions/FadeIn";
import { setError, setSuccess } from "~/src/reducers/toolkit";

interface UpdateGuestModalProps {
  show: boolean;
  guest?: Guest;
  onHide: () => void;
}

const UpdateGuestModal = ({ show, guest, onHide }: UpdateGuestModalProps) => {
  const dispatch = useDispatch();
  const organization = useContext(OrganizationContext);
  const [name, setName] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<
    GuestDashboardOption[]
  >([]);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<string[]>([]);

  const [campaignOptions, setCampaignOptions] = useState<CampaignOption[]>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);

  const onUpdateGuest = (guestId: string, diff: Partial<GuestOptions>) =>
    API.updateGuest(guestId, diff)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)))
      .then(() => dispatch(setSuccess("Guest updated!")))
      .catch(() => dispatch(setError("Failed to update guest.")));

  const workspaceNameFromId = (_id: string) => {
    if (organization?.workspaces) {
      const ws = organization.workspaces as Partial<Team>[];

      return ws.find((e) => e._id === _id)?.name ?? "";
    }
  };

  const campaignFromId = (_id: string) => {
    if (campaignOptions?.length > 0) {
      return campaignOptions?.find((e) => e._id === _id);
    }
  };

  const resetState = () => {
    setName("");
    setSelectedOptions([]);
    setSelectedWorkspaces([]);
    setCampaignOptions([]);
    setSelectedCampaigns([]);
    setSelectedMonths(months);
  };

  useEffect(() => {
    if (show) {
      // Initialize all values from guest
      const {
        name,
        dash,
        workspaces,
        campaigns = [],
        months: monthIds = [],
      } = guest;
      setName(name);
      setSelectedOptions(dash);
      setSelectedWorkspaces(workspaces);
      setCampaignOptions(
        organization?.workspaces
          ?.filter((w: Team) => workspaces?.includes(w._id))
          ?.flatMap((w: Team) => w?.campaigns as CampaignOption[]),
      );
      setSelectedCampaigns(campaigns);
      setSelectedMonths(monthIds?.map((idx) => months?.[idx]));
    }
  }, [show]);

  // Form Validation
  const isMissingName = !name;
  const isMissingOptions = !selectedOptions?.length;
  const isMissingWorkspaces = !selectedWorkspaces?.length;
  const requiresPostFilters = selectedOptions?.some((option) =>
    PostFiltersRequired.includes(option),
  );
  const isMissingPostFilters =
    requiresPostFilters &&
    !(selectedCampaigns?.length || selectedMonths?.length);
  const isButtonDisabled =
    isMissingName ||
    isMissingOptions ||
    isMissingWorkspaces ||
    isMissingPostFilters;
  const validationAssistance =
    isButtonDisabled || requiresPostFilters
      ? isMissingName
        ? "Name your guest."
        : isMissingWorkspaces
          ? "Select workspaces to show your guest."
          : isMissingOptions
            ? "Enable tools for the guest's dashboard."
            : isMissingPostFilters
              ? "Select post filters for campaigns or months."
              : "Update metadata or modify post filters."
      : "You are ready to update the guest!";

  return (
    <Dialog
      open={show}
      onOpenChange={(open) => {
        if (!open) {
          onHide?.();
          resetState();
        }
      }}
    >
      <DialogContent className="max-h-[80vh] overflow-y-auto sm:max-w-2xl">
        <DialogHeader>
          <div className="flex flex-row items-center justify-center">
            <div>
              <Ghost className="mr-2 h-4 w-4" />
            </div>
            <div>Update Guest</div>
          </div>
        </DialogHeader>
        <div className="flex flex-col gap-6">
          <div className="flex flex-row items-center gap-2">
            <div className="text-xs font-bold">Name: </div>
            <Input
              placeholder="My new guest"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <Separator />

          <div className="flex flex-col items-start gap-2">
            <div>
              <div className="flex w-full items-center gap-4">
                <div className="text-sm font-bold">Workspaces</div>
                <div className="flex items-center text-xs text-muted-foreground">
                  <Info className="mr-1 h-3 w-3" />
                  {`${pluralize(
                    selectedWorkspaces?.length,
                    "workspace",
                  )} selected.`}
                </div>
              </div>
              <div className="text-xs">
                Choose which workspaces your guest has access to.
              </div>
            </div>
            <Selector
              options={(organization?.workspaces as Partial<Team>[])?.map(
                (e) => e._id,
              )}
              current={selectedWorkspaces}
              onChange={(c) => {
                const tmp = [...selectedWorkspaces];
                const campaignsForWorkspace =
                  organization?.workspaces
                    ?.filter((w: Team) => w._id === c)
                    ?.flatMap((w: Team) => w?.campaigns as CampaignOption[]) ??
                  [];
                const campaignIdsForWorkspace =
                  campaignsForWorkspace?.map((w) => w._id) ?? [];
                if (selectedWorkspaces?.includes(c)) {
                  tmp.splice(tmp.indexOf(c), 1);
                  // Remove campaigns related to workspace
                  setCampaignOptions(
                    campaignOptions.filter(
                      (co) => !campaignIdsForWorkspace?.includes(co._id),
                    ),
                  );
                  setSelectedCampaigns(
                    selectedCampaigns.filter(
                      (cId) => !campaignIdsForWorkspace?.includes(cId),
                    ),
                  );
                } else {
                  tmp.push(c);
                  // Add campaigns related to workspace
                  setCampaignOptions([
                    ...campaignOptions,
                    ...campaignsForWorkspace,
                  ]);
                  setSelectedCampaigns([
                    ...selectedCampaigns,
                    ...campaignsForWorkspace.map((w) => w._id),
                  ]);
                }
                setSelectedWorkspaces(tmp);
              }}
              mutator={(e) => (
                <div className="flex flex-row items-center space-x-2">
                  <div>{workspaceNameFromId(e)}</div>
                </div>
              )}
            />
          </div>

          <div className="flex flex-col items-start gap-2">
            <div>
              <div className="flex w-full items-center gap-4">
                <div className="text-sm font-bold">Dashboard Tools</div>
                <div className="flex items-center text-xs text-muted-foreground">
                  <Info className="mr-1 h-3 w-3" />
                  {`${pluralize(selectedOptions?.length, "tool")} selected.`}
                </div>
              </div>
              <div className="text-xs">
                Choose which tools your guest has access to.
              </div>
            </div>
            <Selector
              options={Object.keys(GuestDashboardOption)}
              current={selectedOptions}
              onChange={(c: GuestDashboardOption) => {
                const tmp = [...selectedOptions];
                if (selectedOptions?.includes(c)) {
                  tmp.splice(tmp.indexOf(c), 1);
                } else {
                  tmp.push(c);
                }
                setSelectedOptions(tmp);
              }}
              mutator={(e: GuestDashboardOption) => (
                <div className="flex flex-row items-center space-x-2">
                  <div>{guestDashboardOptionToIcon(e, "h-3 w-3 mr")}</div>
                  <div>{capitalize(e)}</div>
                </div>
              )}
            />
          </div>

          <Separator />

          {selectedWorkspaces?.length > 0 &&
            selectedOptions.some((so) => PostFiltersRequired.includes(so)) && (
              <FadeIn
                show
                timeout={200}
                direction="down"
                className="flex flex-col items-start gap-4"
              >
                <div>
                  <div className="text-sm font-bold">Post Filters</div>
                  <div className="text-xs">
                    Filter the posts your guest has access to view.
                  </div>
                </div>
                {campaignOptions?.length > 0 && (
                  <div className="flex w-full flex-col gap-2">
                    <div className="flex w-full items-center gap-4">
                      <div className="text-xs font-bold">Campaigns</div>
                      <div className="flex items-center text-2xs text-muted-foreground">
                        <Info className="mr-1 h-2 w-2" />
                        {campaignOptions?.length === selectedCampaigns?.length
                          ? "All campaigns are selected by default."
                          : `${pluralize(
                              selectedCampaigns?.length,
                              "campaign",
                            )} selected.`}
                      </div>
                    </div>
                    <Selector
                      options={campaignOptions.map((co) => co._id)}
                      current={selectedCampaigns}
                      onChange={(c) => {
                        const tmp = [...selectedCampaigns];
                        if (selectedCampaigns?.includes(c)) {
                          tmp.splice(tmp.indexOf(c), 1);
                        } else {
                          tmp.push(c);
                        }
                        setSelectedCampaigns(tmp);
                      }}
                      mutator={(e) => {
                        const campaignOption = campaignFromId(e);
                        return (
                          <div className="flex flex-row items-center gap-2">
                            <div
                              className="h-3 w-3 shrink-0 rounded-full border border-white/30"
                              style={{ backgroundColor: campaignOption.color }}
                            ></div>
                            <div>{campaignOption.name}</div>
                          </div>
                        );
                      }}
                    />
                  </div>
                )}
                <div className="flex w-full flex-col gap-2">
                  <div className="flex w-full items-center gap-4">
                    <div className="text-xs font-bold">Months</div>
                    <div className="flex items-center text-2xs text-muted-foreground">
                      <Info className="mr-1 h-2 w-2" />
                      {months?.length === selectedMonths?.length
                        ? "All months are selected by default."
                        : `${pluralize(
                            selectedMonths?.length,
                            "month",
                          )} selected.`}
                    </div>
                  </div>
                  <Selector
                    options={months}
                    current={selectedMonths}
                    onChange={(c) => {
                      const tmp = [...selectedMonths];
                      if (selectedMonths?.includes(c)) {
                        tmp.splice(tmp.indexOf(c), 1);
                      } else {
                        tmp.push(c);
                      }
                      setSelectedMonths(tmp);
                    }}
                    mutator={(e) => (
                      <div className="flex flex-row items-center space-x-2">
                        <div>{e}</div>
                      </div>
                    )}
                  />
                </div>
              </FadeIn>
            )}
        </div>
        <DialogFooter className="items-center sm:justify-between">
          <div className="flex items-center gap-2 text-2xs text-muted-foreground">
            <HelpCircle className="h-3 w-3" />
            {validationAssistance}
          </div>
          <div className="flex items-center gap-2">
            <Button variant="outline" size="sm" onClick={onHide}>
              Close
            </Button>
            <Button
              variant="default"
              size="sm"
              disabled={isButtonDisabled}
              onClick={() => {
                if (!isButtonDisabled) {
                  onUpdateGuest(guest._id, {
                    name,
                    dashboard: selectedOptions,
                    workspaces: selectedWorkspaces,
                    campaigns: selectedCampaigns,
                    months: selectedMonths?.map((sm) => months.indexOf(sm)),
                  });
                  onHide();
                }
              }}
            >
              Update
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateGuestModal;
