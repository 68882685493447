"use client";

import * as React from "react";
import * as ProgressPrimitive from "@radix-ui/react-progress";

import { cn } from "~/src/util/reusables";

interface ProgressProps
  extends React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> {
  showPercentage?: boolean;
  percentageClassName?: string;
  containerClassName?: string;
  className?: string;
}

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(
  (
    {
      className,
      value,
      showPercentage: showLabel = true,
      percentageClassName,
      containerClassName,
      ...props
    },
    ref,
  ) => (
    <div
      className={cn(
        "relative flex w-full items-center gap-2",
        containerClassName,
      )}
    >
      <ProgressPrimitive.Root
        ref={ref}
        className={cn(
          "relative h-2 w-full overflow-hidden rounded-full bg-primary/20",
          className,
        )}
        {...props}
      >
        <ProgressPrimitive.Indicator
          className="h-full w-full flex-1 bg-primary transition-all"
          style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
        />
      </ProgressPrimitive.Root>
      {showLabel && (
        <span
          className={cn(
            "min-w-[2.5rem] text-sm text-muted-foreground",
            percentageClassName,
          )}
        >
          {Math.round(value || 0)}%
        </span>
      )}
    </div>
  ),
);
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
