import React, { ReactNode } from "react";
import {
  AiFillTwitterCircle,
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import {
  GuestDashboardOption,
  type Participant,
  type Team,
  type UserAccount,
  createPassthrough,
} from "../api/withApi";
import dayjs from "~/src/util/dayjs";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "~/src/primitives/tooltip";
import {
  BarChart,
  Calendar,
  CircleHelp,
  GalleryHorizontal,
  Share,
  Upload,
} from "lucide-react";
import { isNil, omitBy } from "lodash";
import { PlatformType } from "~/src/util/platforms";
// import twitterIcon from "~/src/assets/TwitterIcon.png";
import xIcon from "~/src/assets/XIcon.png";
import linkedinIcon from "~/src/assets/LinkedInIcon.png";
import instagramIcon from "~/src/assets/InstagramIcon.png";
import facebookIcon from "~/src/assets/FacebookIcon.png";
import { Button } from "../primitives/button";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../primitives/hover-card";
import mime from "mime";

/**
 * Construct `className` strings conditionally, and merge
 * Tailwind CSS classes without style conflicts.
 * clsx: https://github.com/lukeed/clsx
 * tailwind-merge: https://github.com/dcastil/tailwind-merge
 * @param inputs
 * @returns className with conditionals resolved and styles merged
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export type ObjectValues<T> = T[keyof T];

export const socialToLogo = (
  s: string,
  className?: string,
): React.ReactNode | undefined =>
  ({
    instagram: <AiFillInstagram className={className} />,
    facebook: <AiFillFacebook className={className} />,
    twitter: <AiFillTwitterCircle className={className} />,
    linkedin: <AiFillLinkedin className={className} />,
  })[s];

export const guestDashboardOptionToIcon = (
  gd: GuestDashboardOption,
  cn: string,
): React.ReactNode =>
  ({
    posts: <Share className={cn} />,
    media: <GalleryHorizontal className={cn} />,
    upload: <Upload className={cn} />,
    calendar: <Calendar className={cn} />,
    analytics: <BarChart className={cn} />,
  })[gd] ?? <></>;

export const paidUser = (user: UserAccount): boolean => {
  return (
    user?.subscription?.customerId !== undefined &&
    typeof user?.subscription?.customerId === "string"
  );
};

export const enforceUnpaidRestrictions = (user: UserAccount): boolean => {
  return !paidUser(user) && user?.metadata?.studioCalls > 10;
};

export const typewriter = async (
  text: string,
  delay: number,
  callback: (s: string) => void,
) => {
  let e = 0;

  while (e <= text.length) {
    callback(text.substring(0, e));
    e++;
    await new Promise((resolve) => setTimeout(resolve, delay));
  }
};

export const simplifyDate = (dateString: string) =>
  dateString.includes("T") ? dateString.split("T")[0] : dateString;

/**
 * Get the number of days in between two date strings
 * @param start Date-type string mm-dd-yyyy
 * @param end Date-type string mm-dd-yyyy
 */
export const dayDuration = (start: string, end: string) =>
  dayjs(end).diff(dayjs(start), "days");

/**
 * @deprecated use `TooltipButton` or raw `Tooltip` instead
 */
export const withTooltip = (
  element: React.ReactElement,
  text: string,
  key?: string,
) => {
  return (
    <Tooltip key={key}>
      <TooltipTrigger
        asChild={element.type === "button" || element.props?.type === "button"}
      >
        {element}
      </TooltipTrigger>
      <TooltipContent side={"bottom"}>
        <div className={cn("text-xs", "font-semibold")}>{text}</div>
      </TooltipContent>
    </Tooltip>
  );
};

export const TooltipButton = ({
  children,
  text,
  side = "bottom",
  align = "center",
  asChild = true,
  disabled = false,
  className,
  triggerClassName,
  ...rest
}: {
  children: ReactNode;
  text: string;
  side?: "bottom" | "top" | "right" | "left";
  align?: "center" | "end" | "start";
  asChild?: boolean;
  disabled?: boolean;
  className?: string;
  triggerClassName?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  if (disabled) return <>{children}</>;
  return (
    <Tooltip>
      <TooltipTrigger
        asChild={asChild}
        {...rest}
        className={cn("flex items-center justify-center", triggerClassName)}
      >
        {children}
      </TooltipTrigger>
      {!!text && (
        <TooltipContent side={side} align={align} className={className}>
          <p className="text-xs font-semibold">{text}</p>
        </TooltipContent>
      )}
    </Tooltip>
  );
};

export const scrollDown = (element: HTMLElement, offset?: number) => {
  if (element) {
    element.scroll({
      top: element.scrollHeight + (offset ?? 0),
      behavior: "smooth",
    });
  }
};

export const stripTags = (text: string) => {
  const el = document.createElement("div");
  el.className = "hidden";
  el.innerHTML = text;
  const result = el.textContent || el.innerText || "";
  return result === "undefined" ? undefined : result;
};

export const arraymove = (arr: any[], fromIndex: number, toIndex: number) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};

export function useForwardedRef<T>(ref: React.ForwardedRef<T>) {
  const innerRef = React.useRef<T>(null);

  React.useEffect(() => {
    if (!ref) return;
    if (typeof ref === "function") {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  });

  return innerRef;
}

export function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export interface ShallowMedia {
  _id?: string;
  editUri?: string;
  uri?: string;
  jpguri?: string;
  mimeType?: string;
}

function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

// Use to generate an hsl color based on the input str seed
export function pickColor(str: string, saturation = "100%", lightness = "30%") {
  return `hsl(${hashCode(str) % 360}, ${saturation}, ${lightness})`;
}

export const withPfp = (pfp?: string, seed?: string): string => {
  if (seed?.includes("undefined")) {
    seed = "";
  }

  if (pfp && pfp?.includes("lh3.googleusercontent.com"))
    pfp = createPassthrough(pfp);

  const usedPfp =
    pfp ||
    `https://api.dicebear.com/7.x/thumbs/png?seed=${encodeURIComponent(
      seed ?? "",
    )}`;
  return usedPfp;
};

type LinkedinType = Team["linkedin"];
export const isOrg = (linkedin: LinkedinType): boolean =>
  linkedin &&
  Array.isArray(linkedin.orgs) &&
  linkedin.selectedAccount !== undefined &&
  Object.keys(linkedin.orgs[linkedin.selectedAccount])?.length > 0;

export const username = (user?: Partial<UserAccount>) =>
  user?.firstname
    ? user?.lastname
      ? `${user.firstname} ${user.lastname}`
      : user.firstname
    : user?.email || "";

/**
 * Concatenates query parameters to the end of a uri, omitting fields with nullish values
 * @param uri The original uri
 * @param params An object that will be passed to the URLSearchParams constructor
 * @returns The updated uri
 */
export const withQueryParams = (uri: string, params: any) => {
  const q = new URLSearchParams(omitBy(params, isNil)).toString();
  return uri + (q ? "?" + q : q);
};

/**
 * Converts a number to its short ordinal.
 * @param n - number
 * @returns string with number ending. i.e. 1 => 1st
 */
export const toNth = (n: number) =>
  `${n}${["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th"}`;

export const intAbbr = (n: number) => {
  const orders = [
    { v: 1e12, s: "T" },
    { v: 1e9, s: "B" },
    { v: 1e6, s: "M" },
    { v: 1e3, s: "K" },
  ];

  for (const { v, s } of orders) {
    if (n >= v) {
      const rounded = Math.round((10 * n) / v) / 10;
      return rounded.toString() + s;
    }
  }

  return n;
};

export const pluralize = (
  count: number,
  noun: string,
  suffix = "s",
  hideCount = false,
) => `${hideCount ? "" : `${count} `}${noun}${count !== 1 ? suffix : ""}`;

export const hasConnected = (user: UserAccount) =>
  !!(
    user?.workspace?.twitter ||
    user?.workspace?.linkedin ||
    user?.workspace?.instagram ||
    user?.workspace?.facebook
  );

/**
 * Parse a string into a boolean (i.e. from query params)
 * @param value string boolean representation
 * @param defaultValue false
 * @returns The parsed value
 */
export const parseBool = (value: string, defaultValue = false): boolean =>
  (["true", "false", true, false].includes(value) && JSON.parse(value)) ||
  defaultValue;

export type ParticipantsObj = {
  [id: string]: Omit<Participant, "id">;
};

export const participantsToObj = (
  participants: Participant[],
): ParticipantsObj =>
  participants.reduce((acc, participant) => {
    const { id, ...participantWithoutId } = participant;
    acc[id] = participantWithoutId;
    return acc;
  }, {});

export function deepCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Gets the profile picture based on user data, and properly falls back if no pfp supplied
 * @param {PlatformType} platform - the platform
 * @param {string} userId - the user id for that platform
 * @param {string} seed - the seed to use as a fallback to profile image
 * @param {string} pfpUrl - the profile picture url
 * @returns
 */
export const getPlatformPfp = (
  platform: PlatformType,
  userId: string,
  seed: string,
  pfpUrl?: string,
) => {
  let pfp = "";
  switch (platform) {
    case PlatformType.Instagram:
      pfp = withPfp(`/api/facebook/instagram/${userId}/pfp`, seed);
      break;
    case PlatformType.Facebook:
      pfp = withPfp(`/api/facebook/${userId}/pfp`, seed);
      break;
    case PlatformType.LinkedIn:
      withPfp(pfpUrl ?? "", seed);
      break;
    default:
      withPfp(pfpUrl ?? "", seed);
      break;
  }
  return pfp;
};

export const platformIcon = (platform: PlatformType) =>
  ({
    instagram: instagramIcon,
    facebook: facebookIcon,
    twitter: xIcon,
    linkedin: linkedinIcon,
  })[platform];

/**
 * Get social logo from platform and optional className for custom styles
 * @param - { platform, className } get social logo based on the following
 * @returns Platform logo image
 */
export const NewSocialToLogo = ({
  platform,
  className,
  onClick,
}: {
  platform: PlatformType;
  className?: string;
  onClick?: (args: unknown) => void;
}) => (
  <div
    className={cn(
      "flex h-4 w-4 items-center justify-center overflow-hidden rounded-full",
      !!onClick && "cursor-pointer",
      className,
    )}
    onClick={onClick}
  >
    <img
      draggable={false}
      src={platformIcon(platform)}
      className="grow-1 flex h-[90%] w-auto rounded-full object-cover"
      crossOrigin="anonymous"
    />
  </div>
);

export const formatEmail = (s: string) => s.toLowerCase().trim();

const extensionOverrides = (ext: string) =>
  ({
    qt: "mov",
  })[ext] ?? ext;

export const getFileName = (file: File) => {
  // Remove any existing extension from name
  const nameWithoutExt = file.name.replace(/\.[^/.]+$/, "");
  const extension = mime.getExtension(file.type);

  return `${nameWithoutExt}.${extensionOverrides(extension)}`;
};

export const mergeVisuals = (
  a: React.ReactElement[],
  b: React.ReactElement[],
) => a.concat(b);

export function sortByUpdatedAt<T extends { updatedAt?: string }>(items: T[]) {
  return items?.sort(
    (a, b) =>
      new Date(b.updatedAt as string).valueOf() -
      new Date(a.updatedAt as string).valueOf(),
  );
}

function hexToRGB(hex: string): number[] {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse r, g, b values
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
}

function getsRGBComponent(c: number): number {
  const value = c / 255;
  return value <= 0.03928
    ? value / 12.92
    : Math.pow((value + 0.055) / 1.055, 2.4);
}

function getLuminance(hex: string): number {
  const [r, g, b] = hexToRGB(hex);

  const sR = getsRGBComponent(r);
  const sG = getsRGBComponent(g);
  const sB = getsRGBComponent(b);

  // Calculate luminance
  const luminance = 0.2126 * sR + 0.7152 * sG + 0.0722 * sB;

  return luminance;
}

function getContrast(f, b) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

export function getTextColor(bgColor: string) {
  const whiteContrast = getContrast(bgColor, "#ffffff");
  const blackContrast = getContrast(bgColor, "#000000");

  return whiteContrast > blackContrast ? "text-white" : "text-black";
}

export const HelpHoverCard = ({
  children,
  side = "right",
}: {
  children: ReactNode;
  side?: "left" | "right" | "top" | "bottom";
}) => (
  <HoverCard>
    <HoverCardTrigger asChild>
      <Button variant="ghost" size="tinycircle">
        <CircleHelp className="h-3 w-3" />
      </Button>
    </HoverCardTrigger>
    <HoverCardContent className="text-sm font-medium" side={side}>
      {children}
    </HoverCardContent>
  </HoverCard>
);
