import React, { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { Input } from "~/src/primitives/input";
import { Button } from "~/src/primitives/button";
import { Workflow } from "lucide-react";

interface CreateWorkspaceModalProps {
  show: boolean;
  onCreate: (name: string) => void;
  onHide: () => void;
}

const CreateWorkspaceModal = ({
  show,
  onCreate,
  onHide,
}: CreateWorkspaceModalProps) => {
  const [name, setName] = useState<string>();

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-row items-center justify-center">
            <div>
              <Workflow className="mr-2 h-4 w-4" />
            </div>
            <div>Create a new workspace</div>
          </div>
        </DialogHeader>
        <div>
          <Input
            placeholder="My new workspace"
            spellCheck={false}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <DialogFooter>
          <Button variant="outline" size="sm" onClick={onHide}>
            Close
          </Button>
          <Button
            variant="default"
            size="sm"
            disabled={!name}
            onClick={() => {
              if (name) {
                onCreate(name);
                onHide();
              }
            }}
          >
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateWorkspaceModal;
