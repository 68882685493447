import axios from "axios";
import { UserAccount } from "./withApi";

export interface FacebookAccount {
  id: string;
  name: string;
  pfp: string;
  connectedWorkspaceId: string;
}

export interface InstagramProfile {
  id: string;
  username: string;
  name: string;
  pfp: string;
  connectedWorkspaceId: string;
}

type MetaAccountsResponse<T> = {
  accounts: T[];
  selectedAccountId: string | null;
};

export const getFacebookAccounts = async (): Promise<
  MetaAccountsResponse<FacebookAccount>
> => {
  const response = await axios.get<MetaAccountsResponse<FacebookAccount>>(
    "/api/facebook/fbaccounts",
  );
  return response.data;
};

export const getInstagramAccounts = async (): Promise<
  MetaAccountsResponse<InstagramProfile>
> => {
  const response = await axios.get<MetaAccountsResponse<InstagramProfile>>(
    "/api/facebook/igaccounts",
  );
  return response.data;
};

export const disconnectMetaAdsAccount = async (): Promise<UserAccount> => {
  const response = await axios.post<UserAccount>(
    "/api/facebook/paid/disconnect",
  );
  return response.data;
};

export interface FacebookLocation {
  pfp: string;
  id: string;
  name: string;
  key: string;
  isStoreLocation: boolean;
  hours: {
    monday: { is_open: boolean; open?: string; close?: string };
    tuesday: { is_open: boolean; open?: string; close?: string };
    wednesday: { is_open: boolean; open?: string; close?: string };
    thursday: { is_open: boolean; open?: string; close?: string };
    friday: { is_open: boolean; open?: string; close?: string };
    saturday: { is_open: boolean; open?: string; close?: string };
    sunday: { is_open: boolean; open?: string; close?: string };
  };
  store_number?: string;
  phone?: string;
  description?: string;
  emails?: string[];
  store_location_descriptor?: string;
  store_code?: string;
  website?: string;
  username?: string;
}

export const getFacebookLocations = async (): Promise<FacebookLocation[]> => {
  const response = await axios.get<FacebookLocation[]>(
    "/api/facebook/locations",
  );
  return response.data;
};

export const syncFacebookLocations = async (): Promise<UserAccount> => {
  const response = await axios.get<UserAccount>("/api/facebook/locations/sync");
  return response.data;
};
