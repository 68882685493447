import React, { MouseEventHandler, useState } from "react";
import Preview, { PreviewProps } from "~/src/partials/Templates/Preview";
import Gallery from "./Gallery";
import { Media, Team } from "~/src/api/withApi";

type PostProps = {
  className?: string;
  media?: Media[];
  mediaClassName?: string;
  hideVideoControls?: boolean;
  showVideoIndicator?: boolean;
  forceWorkspace?: Team; // Allows you to override the PFP and Name per: https://gitlab.com/flamel-ai/flamel-ai/-/issues/905
  forceDisplayPfp?: string; // Allows absolute control over pfp
  forceDisplayName?: string; // Allows absolute control over name
  parentPostId?: string; // Using on facebook for reposts
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & Omit<PreviewProps, "media">;

const Post = ({
  media,
  mediaClassName,
  hideVideoControls,
  showVideoIndicator,
  onClick,
  ...rest
}: PostProps) => {
  const [carouselIndex, setCarouselIndex] = useState<number>(0);

  const gallery = media?.length ? (
    <Gallery
      media={media}
      className="mx-auto w-full"
      mediaClassName={mediaClassName}
      hideVideoControls={hideVideoControls}
      showVideoIndicator={showVideoIndicator}
      carouselIndex={carouselIndex}
      setCarouselIndex={setCarouselIndex}
    />
  ) : null;

  return onClick ? (
    <button
      type="button"
      className="w-full cursor-pointer text-left hover:opacity-90"
      onClick={onClick}
    >
      <Preview display={gallery} {...rest} />
    </button>
  ) : (
    <Preview display={gallery} {...rest} />
  );
};

export default Post;
