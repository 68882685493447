import React, { useEffect, useState } from "react";
import {
  ArrowDown01,
  ArrowUp10,
  ChevronDown,
  ClipboardCopy,
  Filter,
  Inbox,
  Info,
  Mail,
  MailOpen,
  MessageCircle,
  MoreHorizontal,
  Newspaper,
  Pencil,
  RefreshCcw,
  Save,
  Search,
  Send,
  SendHorizonal,
  Tag,
  Tags,
  Trash2,
  XCircle,
} from "lucide-react";
import {
  NewSocialToLogo,
  TooltipButton,
  cn,
  withPfp,
} from "~/src/util/reusables";
import {
  PlatformType,
  extractConnectedAccountsInfo,
  getActiveAccountForPlatform,
  getPlatformName,
} from "~/src/util/platforms";
import { Team, type Media } from "~/src/api/withApi";
import { Input } from "~/src/primitives/input";
import { Button } from "~/src/primitives/button";
import { Textarea } from "~/src/primitives/textarea";
import { useDispatch, useSelector } from "react-redux";
import FadeIn from "~/src/partials/Transitions/FadeIn";
import dayjs from "~/src/util/dayjs";
import { RootState } from "~/src/store";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  IPlatformAccountTag,
  IPlatformComment,
  buildCommentHierarchy,
  getComments,
} from "~/src/api/engage.api";
import DisplayMedia from "../Library/components/DisplayMedia";
import Post from "~/src/lib/Post/Post";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/src/primitives/dropdown-menu";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/src/primitives/popover";
import {
  DateRangePickerValue,
  MultiSelect,
  MultiSelectItem,
} from "@tremor/react";
import WizardIllustration from "~/src/assets/WizardIllustration";
import API from "~/src/api/withApi";
import { setError, setLoading, setSuccess } from "~/src/reducers/toolkit";
import { ToggleGroup, ToggleGroupItem } from "~/src/primitives/toggle-group";
import { capitalize, orderBy } from "lodash";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "~/src/primitives/sheet";
import Tagger from "~/src/partials/Tagger/Tagger";
import { Badge } from "~/src/primitives/badge";
import { Switch } from "~/src/primitives/switch";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/src/primitives/tabs";
import Comment, { NestedComment } from "./Comment";
import { useSearchParams } from "react-router-dom";
import { Skeleton } from "../../primitives/skeleton";
import ViewOnlyCaptionWithFallback from "../Post/components/editors/ViewOnlyCaptionWithFallback";
import { CustomDateRangePicker } from "../../primitives/CustomDateRangePicker";

/**
 * Platform-agnostic comment formatter to allow for things like `@` mentions
 * @param comment - the user viewable comment string
 * @param replacements - the rich text replacements to be made before comment submit
 * @returns
 */
function substituteReplacements(
  comment: string,
  replacements: Record<string, string>,
): string {
  let substitutedComment = comment;

  for (const [key, value] of Object.entries(replacements)) {
    substitutedComment = substitutedComment.replace(
      new RegExp(key, "g"),
      value,
    );
  }

  return substitutedComment;
}

const supportedPlatforms = [PlatformType.Facebook, PlatformType.Instagram];

type CommentsData = {
  comments: IPlatformComment[];
  accounts: IPlatformAccountTag[];
  posts: any[];
  commentPostHierarchy: any[];
};

const SentimentValues = [
  {
    value: "postive",
    label: "Positive",
    className: "bg-[#7AC078]",
    lowerThreshold: 0.6667,
    upperThreshold: 1.0,
  },
  {
    value: "neutral",
    label: "Neutral",
    className: "bg-[#ECC04E]",
    lowerThreshold: 0.3334,
    upperThreshold: 0.6666,
  },
  {
    value: "negative",
    label: "Negative",
    className: "bg-[#B84B4B]",
    lowerThreshold: 0.0,
    upperThreshold: 0.3333,
  },
];

export const findSentimentValue = (sentiment: number) =>
  SentimentValues.find(
    (sv) => sentiment >= sv.lowerThreshold && sentiment <= sv.upperThreshold,
  );

const getDefaultNestedComment = (platformPostId: string) => ({
  platformPostId,
  comments: [],
});

type PostCommentHierarchy = {
  comments: NestedComment[];
  platformPostId: string;
};

/**
 * Given relevant platform data, get the post account name if possible...
 */
const extractAccountDisplayInfo = (
  platform: PlatformType,
  platformAccountId: string | undefined,
  platformAccountName: string | undefined,
  platformAccountUsername: string | undefined,
  platformAccountPfp: string | undefined,
  isTaggedPost: boolean | undefined,
  currentWorkspace: Team | undefined,
) => {
  const { id, name, pfp } = currentWorkspace
    ? getActiveAccountForPlatform(platform, currentWorkspace)
    : ({} as any);

  const displayName: string =
    platformAccountUsername ??
    platformAccountName ??
    ((isTaggedPost && platformAccountName === name) ||
    (!isTaggedPost && platformAccountId === id)
      ? name
      : `${getPlatformName(platform)} User`);
  const displayPfp: string = withPfp(
    platformAccountPfp ??
      ((isTaggedPost && platformAccountName === name) ||
      (!isTaggedPost && platformAccountId === id)
        ? pfp
        : undefined),
    displayName,
  );

  return { displayName, displayPfp };
};

function sortItems<T = any>(items: T[], sortDirection: "desc" | "asc") {
  items = orderBy(items, ["timestamp"], [sortDirection]);

  return items;
}

export default function CommentManagement() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector((state: RootState) => state.user);
  const [focusedPost, setFocusedPost] = useState<any>();
  const handleSetFocusedPost = (post: any | undefined) => {
    if (!post) {
      setSearchParams((params) => {
        params.delete("pid");
        return params;
      });
      setFocusedPost(undefined);
      return;
    }

    setSearchParams((params) => {
      params.set("pid", post.platformPostId);
      return params;
    });
    setFocusedPost(post);
  };

  const [focusedComments, setFocusedComments] =
    useState<PostCommentHierarchy>();
  const [displayMedia, setDisplayMedia] = useState<Media>();
  const [replyToComment, setReplyToComment] = useState<NestedComment>();
  const [comment, setComment] = useState<string>();
  const [replacements, setReplacements] = useState<Record<string, string>>({});
  const [labelsOpen, setLabelsOpen] = useState<{
    labels: string[];
    _id: string;
    platform: PlatformType;
  }>();
  // LinkedIn Comment Attributes
  // Docs: https://learn.microsoft.com/en-us/linkedin/marketing/integrations/community-management/shares/comments-api?view=li-lms-2023-11&tabs=http#comment-mentioning
  // See also: https://stackoverflow.com/a/77525255/22960143
  // const [attributes, setAttributes] =
  //   useState<{ length: number; start: number; value: Record<string, any> }[]>();
  const [commentSuggestions, setCommentSuggestions] = useState<
    string[] | undefined
  >();

  // Filters
  const [filterSearch, setFilterSearch] = useState<string>();
  const [filterPlatforms, setFilterPlatforms] =
    useState<(PlatformType | string)[]>(supportedPlatforms);
  const [filterSentiments, setFilterSentiments] = useState<string[]>(
    SentimentValues.map((sv) => sv.value),
  );
  const [filterLabels, setFilterTags] = useState<string[]>([]);
  const [filterReadState, setFilterReadState] = useState<
    "all" | "unread" | "read" | string
  >("all");
  const [filterTaggedPostsOnly, setFilterTaggedPostsOnly] =
    useState<boolean>(false);

  // Sort
  const [sortDirection, setSortDirection] = useState<"desc" | "asc">("desc");
  const [dateRange, setDateRange] = useState<DateRangePickerValue>();

  const currentWorkspaceAccounts = extractConnectedAccountsInfo(
    user?.workspace,
  );

  const { name, pfp } = getActiveAccountForPlatform(
    focusedPost?.platform,
    user?.workspace,
  );

  const queryClient = useQueryClient();

  const handleRefreshPost = async (
    platform: PlatformType,
    platformPostId: string,
  ) => {
    await API.ingestCommunityManagementForPost(platform, platformPostId);
    queryClient.refetchQueries({
      queryKey: ["comments", user?.workspace?._id],
    });
  };

  const { data, isFetching } = useQuery<CommentsData>({
    queryKey: ["comments", user?.workspace?._id],
    queryFn: () =>
      getComments().then((res) => {
        if (focusedPost?.platformPostId) {
          const postData = res?.posts?.find(
            (p) => p.platformPostId === focusedPost.platformPostId,
          );
          handleSetFocusedPost(postData);

          const nestedComments =
            res?.commentPostHierarchy?.find(
              (ns) => ns.platformPostId === focusedPost.platformPostId,
            ) ?? getDefaultNestedComment(focusedPost.platformPostId);
          setFocusedComments(nestedComments);
        } else if (searchParams.get("pid")) {
          const postData = res?.posts?.find(
            (p) => p.platformPostId === searchParams.get("pid"),
          );
          handleSetFocusedPost(postData);

          const nestedComments =
            res?.commentPostHierarchy?.find(
              (ns) => ns.platformPostId === searchParams.get("pid"),
            ) ?? getDefaultNestedComment(searchParams.get("pid"));
          setFocusedComments(nestedComments);
        }

        return res;
      }),
  });

  useEffect(() => {
    const pid = searchParams.get("pid");

    if (
      (Boolean(data?.posts?.length) && pid && !focusedPost) ||
      focusedPost?.platformPostId !== pid
    ) {
      const p = data?.posts?.find((ph) => ph.platformPostId === pid);
      if (p) {
        setFocusedPost(p);
        const nc =
          data?.commentPostHierarchy?.find(
            (ph) => ph.platformPostId === p.platformPostId,
          ) ?? getDefaultNestedComment(p.platformPostId);
        if (nc) setFocusedComments(nc);
        markPostCommentsAsReadMutation.mutate({
          platform: p.platform,
          platformPostId: p.platformPostId,
        });
      }
    }
  }, [searchParams]);

  const availableLabels =
    (searchParams.get("tab") ?? "comments") === "comments"
      ? Array.from(
          new Set(
            data?.comments?.reduce((acc, comment) => {
              if (comment.labels) {
                acc.push(...comment.labels);
              }
              return acc;
            }, []),
          ),
        )
      : Array.from(
          new Set(
            data?.posts?.reduce((acc, post) => {
              if (post.labels) {
                acc.push(...post.labels);
              }
              return acc;
            }, []),
          ),
        );

  const cannotCommentOnInstagramTags =
    focusedPost?.isTaggedPost &&
    focusedPost?.platform === PlatformType.Instagram;

  const createCommentMutation = useMutation({
    mutationFn: ({
      platform,
      platformPostId,
      message,
      parentComment,
    }: {
      platform: PlatformType;
      platformPostId: string;
      message: string;
      parentComment?: string;
    }) =>
      API.createComment(platform, platformPostId, {
        message,
        parentComment,
        // attributes
      }),
    onMutate: () => dispatch(setLoading("Commenting...")),
    onSuccess: async (res, { platform, platformPostId }) => {
      if (!res.ok) {
        if (cannotCommentOnInstagramTags) {
          dispatch(setError("Cannot comment on Instagram tagged posts"));
        } else {
          dispatch(setError("Error creating comment"));
        }
      } else {
        await handleRefreshPost(platform, platformPostId);
        dispatch(setSuccess("Created comment!"));
        setComment(undefined);
        setReplyToComment(undefined);
        setCommentSuggestions(undefined);
        setComment("");
        setReplacements({});
      }
    },
    onError: (err) => {
      console.log(err.message);
      if (cannotCommentOnInstagramTags) {
        dispatch(setError("Cannot comment on Instagram tagged posts"));
      } else {
        dispatch(setError("Error creating comment"));
      }
    },
  });
  const deleteCommentMutation = useMutation({
    mutationFn: ({
      platform,
      platformPostId,
      platformCommentId,
    }: {
      platform: PlatformType;
      platformPostId: string;
      platformCommentId: string;
    }) => API.deleteComment(platform, platformPostId, platformCommentId),
    onMutate: () => dispatch(setLoading("Deleting...")),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["comments", user?.workspace?._id],
      }),
        dispatch(setSuccess("Deleted comment!"));
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error deleting comment"));
    },
  });

  const markAsReadCommentMutation = useMutation({
    mutationFn: (_id: string) => API.markPlatformCommentRead(_id),
    onSuccess: async (_, _id) => {
      queryClient.setQueryData<CommentsData>(
        ["comments", user?.workspace?._id].filter(Boolean),
        (old) => {
          const { comments, ...rest } = old ?? {};
          const updatedComments =
            comments?.map((c) =>
              c._id === _id ? { ...c, markedRead: true } : c,
            ) ?? [];
          const updatedCommentsPostHierarchy =
            buildCommentHierarchy(updatedComments);
          if (focusedComments?.platformPostId)
            setFocusedComments(
              updatedCommentsPostHierarchy.find(
                (ph) => ph.platformPostId === focusedComments.platformPostId,
              ),
            );
          return {
            ...rest,
            comments: updatedComments,
            commentPostHierarchy: updatedCommentsPostHierarchy,
          } as CommentsData;
        },
      );
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error marking comment as read"));
    },
  });

  const markAsUnreadCommentMutation = useMutation({
    mutationFn: (_id: string) => API.markPlatformCommentUnread(_id),
    onSuccess: async (_, _id) => {
      queryClient.setQueryData<CommentsData>(
        ["comments", user?.workspace?._id].filter(Boolean),
        (old) => {
          const { comments, ...rest } = old ?? {};
          const updatedComments =
            comments?.map((c) =>
              c._id === _id ? { ...c, markedRead: false } : c,
            ) ?? [];
          const updatedCommentsPostHierarchy =
            buildCommentHierarchy(updatedComments);
          if (focusedComments?.platformPostId)
            setFocusedComments(
              updatedCommentsPostHierarchy.find(
                (ph) => ph.platformPostId === focusedComments.platformPostId,
              ),
            );
          return { ...rest, comments: updatedComments } as CommentsData;
        },
      );
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error marking comment as unread"));
    },
  });

  const markPostCommentsAsReadMutation = useMutation({
    mutationFn: ({
      platform,
      platformPostId,
    }: {
      platform: PlatformType;
      platformPostId: string;
    }) => API.markPostCommentsAsRead(platform, platformPostId),
    onSuccess: async (_, { platformPostId }) => {
      queryClient.setQueryData<CommentsData>(
        ["comments", user?.workspace?._id].filter(Boolean),
        (old) => {
          const { comments, posts, ...rest } = old ?? {};
          const updatedComments =
            comments?.map((c) =>
              c.platformPostId === platformPostId
                ? { ...c, markedRead: true }
                : c,
            ) ?? [];
          const updatedCommentsPostHierarchy =
            buildCommentHierarchy(updatedComments);
          if (focusedComments?.platformPostId)
            setFocusedComments(
              updatedCommentsPostHierarchy.find(
                (ph) => ph.platformPostId === focusedComments.platformPostId,
              ),
            );

          const updatedPosts =
            posts?.map((p) =>
              p.platformPostId === platformPostId
                ? { ...p, markedRead: true }
                : p,
            ) ?? [];
          if (focusedPost?.platformPostId)
            handleSetFocusedPost(
              updatedPosts.find(
                (ph) => ph.platformPostId === focusedPost.platformPostId,
              ),
            );

          return {
            ...rest,
            posts: updatedPosts,
            comments: updatedComments,
            commentPostHierarchy: updatedCommentsPostHierarchy,
          } as CommentsData;
        },
      );
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error marking post comments as read"));
    },
  });

  const markPostAsReadMutation = useMutation({
    mutationFn: ({ platform, _id }: { platform: PlatformType; _id: string }) =>
      API.markPlatformPostRead(platform, _id),
    onSuccess: async (_, { _id }) => {
      queryClient.setQueryData<CommentsData>(
        ["comments", user?.workspace?._id].filter(Boolean),
        (old) => {
          const { posts, ...rest } = old ?? {};
          const updatedPosts =
            posts?.map((p) =>
              p._id === _id ? { ...p, markedRead: true } : p,
            ) ?? [];
          if (focusedPost?.platformPostId)
            handleSetFocusedPost(
              updatedPosts.find(
                (ph) => ph.platformPostId === focusedPost.platformPostId,
              ),
            );
          return {
            ...rest,
            posts: updatedPosts,
          } as CommentsData;
        },
      );
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error marking post as read"));
    },
  });

  const markPostAsUnreadMutation = useMutation({
    mutationFn: ({ platform, _id }: { platform: PlatformType; _id: string }) =>
      API.markPlatformPostUnread(platform, _id),
    onSuccess: async (_, { _id }) => {
      queryClient.setQueryData<CommentsData>(
        ["comments", user?.workspace?._id].filter(Boolean),
        (old) => {
          const { posts, ...rest } = old ?? {};
          const updatedPosts =
            posts?.map((p) =>
              p._id === _id ? { ...p, markedRead: false } : p,
            ) ?? [];
          if (focusedPost?.platformPostId)
            handleSetFocusedPost(
              updatedPosts.find(
                (ph) => ph.platformPostId === focusedPost.platformPostId,
              ),
            );
          return {
            ...rest,
            posts: updatedPosts,
          } as CommentsData;
        },
      );
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error marking post as unread"));
    },
  });

  const setPlatformCommentLabelsMutation = useMutation({
    mutationFn: ({ _id, labels }: { _id: string; labels: string[] }) =>
      API.setPlatformCommentLabels(_id, labels),
    onSuccess: async (_, { _id, labels }) => {
      setLabelsOpen(undefined);
      queryClient.setQueryData<CommentsData>(
        ["comments", user?.workspace?._id].filter(Boolean),
        (old) => {
          const { comments, ...rest } = old ?? {};
          const updatedComments =
            comments?.map((c) => (c._id === _id ? { ...c, labels } : c)) ?? [];
          const updatedCommentsPostHierarchy =
            buildCommentHierarchy(updatedComments);
          if (focusedComments?.platformPostId)
            setFocusedComments(
              updatedCommentsPostHierarchy.find(
                (ph) => ph.platformPostId === focusedComments.platformPostId,
              ),
            );
          return { ...rest, comments: updatedComments } as CommentsData;
        },
      );
      dispatch(setSuccess("Comment labels set"));
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error setting comment labels"));
    },
  });

  const setPlatformPostLabelsMutation = useMutation({
    mutationFn: ({
      platform,
      _id,
      labels,
    }: {
      platform: PlatformType;
      _id: string;
      labels: string[];
    }) => API.setPlatformPostLabels(platform, _id, labels),
    onSuccess: async (_, { _id, labels }) => {
      setLabelsOpen(undefined);
      queryClient.setQueryData<CommentsData>(
        ["comments", user?.workspace?._id].filter(Boolean),
        (old) => {
          const { posts, ...rest } = old ?? {};
          const updatedPosts =
            posts?.map((p) => (p._id === _id ? { ...p, labels } : p)) ?? [];
          return { ...rest, posts: updatedPosts } as CommentsData;
        },
      );
      dispatch(setSuccess("Post labels set"));
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error setting post labels"));
    },
  });

  const refreshInboxMutation = useMutation({
    mutationFn: () => API.ingestCommunityManagement(),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["comments", user?.workspace?._id],
      });
      queryClient.refetchQueries({
        queryKey: ["notifications", user?.workspace?._id],
      });
      dispatch(setSuccess("Inbox refreshed!"));
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error refreshing inbox"));
    },
  });

  const generateCommentSuggestionsMutation = useMutation({
    mutationFn: ({ prompt, n }: { prompt: string; n?: number }) =>
      API.generateCommentSuggestions(prompt, n),
    onSuccess: async (data) => {
      if (data?.suggestions) setCommentSuggestions(data.suggestions);
    },
    onError: (err) => {
      console.log(err.message);
      dispatch(setError("Error generating comment suggestions"));
    },
  });

  const handleCreateComment = (commentText: string) => {
    replyToComment
      ? createCommentMutation.mutate({
          platform: focusedPost.platform,
          platformPostId: focusedPost.platformPostId,
          message: substituteReplacements(commentText, replacements),
          // attributes,
          parentComment: replyToComment.platformCommentId,
        })
      : createCommentMutation.mutate({
          platform: focusedPost.platform,
          platformPostId: focusedPost.platformPostId,
          message: substituteReplacements(commentText, replacements),
          // attributes,
        });
  };

  const filterComments = (comments: NestedComment[]) => {
    // Filter search
    comments = filterSearch
      ? comments?.filter(
          (c) =>
            c?.content?.toLowerCase()?.includes(filterSearch?.toLowerCase()) ||
            c?.fromPlatformAccountName
              ?.toLowerCase()
              ?.includes(filterSearch?.toLowerCase()) ||
            c?.labels
              ?.join("")
              ?.toLowerCase()
              ?.includes(filterSearch?.toLowerCase()),
        )
      : comments;

    // Filter platform
    comments = comments?.filter(
      (c) =>
        filterPlatforms.includes(c.platform) &&
        (filterReadState === "all"
          ? true
          : filterReadState === "unread"
            ? !c.markedRead
            : c.markedRead),
    );

    // Filter sentiment
    comments = comments?.filter((c) =>
      filterSentiments.includes(findSentimentValue(c.sentiment)?.value),
    );

    // Filter labels
    comments = comments?.filter((c) =>
      !filterLabels.length
        ? c
        : c.labels?.some((t) => filterLabels?.includes(t)),
    );

    // Filter dates
    if (dateRange?.from && dateRange?.to)
      comments = comments?.filter(
        (c) =>
          c.timestamp >= dayjs(dateRange.from).valueOf() &&
          c.timestamp <= dayjs(dateRange.to).valueOf(),
      );

    // Filter tagged posts only
    if (filterTaggedPostsOnly)
      comments = comments?.filter((c) => {
        const postData = data?.posts?.find(
          (p) => p.platformPostId === c.platformPostId,
        );

        return postData?.isTaggedPost;
      });

    return comments;
  };

  const filterPosts = (posts: any[]) => {
    // Filter search
    posts = filterSearch
      ? posts?.filter(
          (p) =>
            p?.caption?.toLowerCase()?.includes(filterSearch?.toLowerCase()) ||
            p?.platformAccountUsername
              ?.toLowerCase()
              ?.includes(filterSearch?.toLowerCase()) ||
            p?.labels
              ?.join("")
              ?.toLowerCase()
              ?.includes(filterSearch?.toLowerCase()),
        )
      : posts;

    // Filter platform
    posts = posts?.filter(
      (p) =>
        filterPlatforms.includes(p.platform) &&
        (filterReadState === "all"
          ? true
          : filterReadState === "unread"
            ? !p.markedRead
            : p.markedRead),
    );

    // Filter sentiment
    posts = posts?.filter((p) =>
      p.sentiment
        ? !filterSentiments.length
          ? p
          : filterSentiments.includes(findSentimentValue(p.sentiment)?.value)
        : p,
    );

    // Filter labels
    posts = posts?.filter((p) =>
      !filterLabels.length
        ? p
        : p.labels?.some((t) => filterLabels?.includes(t)),
    );

    // Filter dates
    if (dateRange?.from && dateRange?.to)
      posts = posts?.filter(
        (p) =>
          p.timestamp >= dayjs(dateRange.from).valueOf() &&
          p.timestamp <= dayjs(dateRange.to).valueOf(),
      );

    // Filter tagged posts only
    if (filterTaggedPostsOnly) posts = posts?.filter((p) => p?.isTaggedPost);

    return posts;
  };

  const filteredComments = sortItems<NestedComment>(
    filterComments(data?.comments),
    sortDirection,
  );
  const filteredPosts = sortItems(filterPosts(data?.posts), sortDirection);

  const accountForFocusedPost = data?.accounts?.find(
    (acc) =>
      acc.platformAccountUsername &&
      acc.platformAccountUsername === focusedPost?.platformAccountUsername,
  );

  const focusedPostAccountDisplayInfo = extractAccountDisplayInfo(
    focusedPost?.platform ?? PlatformType.Instagram,
    focusedPost?.platformAccountId,
    focusedPost?.platformAccountName ??
      accountForFocusedPost?.platformAccountName,
    focusedPost?.platformAccountUsername ??
      accountForFocusedPost?.platformAccountUsername,
    focusedPost?.platformAccountPfp ??
      accountForFocusedPost?.platformAccountPfp,
    focusedPost?.isTaggedPost,
    user.workspace,
  );

  return (
    <div className="flex h-full w-full flex-col gap-4 p-4">
      {/* Body */}
      <div className="flex h-full max-h-screen w-full gap-2 ">
        {/* Sidebar */}
        <Tabs
          value={searchParams.get("tab") ?? "comments"}
          onValueChange={(val) => {
            handleSetFocusedPost(undefined);
            setSearchParams((params) => {
              params.set("tab", val);
              return params;
            });
          }}
          className="relative box-border flex h-full w-full max-w-sm flex-col gap-2 overflow-hidden rounded-xl border-r-2 border-white bg-gradient-to-b from-[#F2F2F2] to-[#DFDFDF] pb-6"
        >
          <TabsList className="grid w-full grid-cols-2 rounded-none border-b bg-transparent">
            <TabsTrigger
              value="comments"
              className="rounded-lg text-xs font-semibold"
            >
              <MessageCircle className="mr-1 size-3 fill-current" />
              Comments
            </TabsTrigger>
            <TabsTrigger
              value="posts"
              className="rounded-lg text-xs font-semibold"
            >
              <Newspaper className="mr-1 size-3" />
              Posts
            </TabsTrigger>
          </TabsList>
          <div className="flex flex-col gap-2 px-3">
            <div className="relative h-fit w-full">
              <Search className="absolute left-3 top-1/2 z-20 h-4 w-4 -translate-y-1/2 transform text-muted-foreground" />
              <Input
                value={filterSearch}
                onChange={(e) => setFilterSearch(e.target.value)}
                placeholder="Search"
                className="w-full rounded-full bg-white pl-10 pr-4"
              />
            </div>

            <div className="flex w-full items-center justify-between px-2">
              <div className="flex items-center gap-1">
                <Popover>
                  <TooltipButton text="Filter by...">
                    <PopoverTrigger asChild>
                      <Button variant="ghost" size="icon-sm">
                        <Filter className="h-4 w-4 text-muted-foreground" />
                      </Button>
                    </PopoverTrigger>
                  </TooltipButton>
                  <PopoverContent
                    className="w-80 space-y-4 rounded-2xl"
                    align="start"
                  >
                    {/* Platforms */}
                    <div className="grid gap-2">
                      <h4 className="text-sm font-medium leading-none">
                        Platforms
                      </h4>
                      <MultiSelect
                        value={filterPlatforms}
                        onValueChange={(val) => setFilterPlatforms(val)}
                      >
                        {supportedPlatforms.map((p) => (
                          <MultiSelectItem key={p} value={p}>
                            <span className="flex items-center gap-1">
                              <NewSocialToLogo platform={p} />
                              {getPlatformName(p)}
                            </span>
                          </MultiSelectItem>
                        ))}
                      </MultiSelect>
                    </div>

                    {/* Sentiment */}
                    <div className="grid gap-2">
                      <h4 className="text-sm font-medium leading-none">
                        Sentiment
                      </h4>
                      <MultiSelect
                        value={filterSentiments}
                        onValueChange={(val) => setFilterSentiments(val)}
                      >
                        {SentimentValues.map((sentiment) => (
                          <MultiSelectItem
                            key={sentiment.value}
                            value={sentiment.value}
                          >
                            <span className="flex items-center gap-1">
                              <div
                                className={cn(
                                  "h-2.5 w-2.5 rounded-full",
                                  sentiment.className,
                                )}
                              />
                              {sentiment.label}
                            </span>
                          </MultiSelectItem>
                        ))}
                      </MultiSelect>
                    </div>

                    {/* Labels */}
                    <div className="grid gap-2">
                      <h4 className="text-sm font-medium leading-none">
                        Labels
                      </h4>
                      <MultiSelect
                        value={filterLabels}
                        onValueChange={(val) => setFilterTags(val)}
                      >
                        {availableLabels?.map((t) => (
                          <MultiSelectItem key={t} value={t}>
                            {t}
                          </MultiSelectItem>
                        ))}
                      </MultiSelect>
                    </div>
                    {/* Tagged Posts */}
                    <div className="flex items-center gap-2">
                      <h4 className="text-sm font-medium leading-none">
                        Tagged Posts Only?
                      </h4>
                      <Switch
                        checked={filterTaggedPostsOnly}
                        onCheckedChange={setFilterTaggedPostsOnly}
                      />
                    </div>
                  </PopoverContent>
                </Popover>
                <TooltipButton text="Refresh your inbox, this may take a few moments">
                  <Button
                    variant="ghost"
                    size="icon-sm"
                    disabled={refreshInboxMutation.isPending}
                    onClick={() => {
                      dispatch(setLoading("Refreshing inbox..."));
                      refreshInboxMutation.mutate();
                    }}
                  >
                    <RefreshCcw className="h-4 w-4 text-muted-foreground" />
                  </Button>
                </TooltipButton>
              </div>

              <ToggleGroup
                variant="bold"
                type="single"
                value={filterReadState}
                onValueChange={(val) =>
                  !val ? setFilterReadState("all") : setFilterReadState(val)
                }
              >
                <ToggleGroupItem value="all" aria-label="Toggle all">
                  <TooltipButton text="All">
                    <Inbox className="h-4 w-4" />
                  </TooltipButton>
                </ToggleGroupItem>
                <ToggleGroupItem value="unread" aria-label="Toggle unread">
                  <TooltipButton text="Unread">
                    <Mail className="h-4 w-4" />
                  </TooltipButton>
                </ToggleGroupItem>
                <ToggleGroupItem value="read" aria-label="Toggle read">
                  <TooltipButton text="Read">
                    <MailOpen className="h-4 w-4" />
                  </TooltipButton>
                </ToggleGroupItem>
              </ToggleGroup>

              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="ghost" className="flex items-center gap-1">
                    <span className="text-muted-foreground">
                      {sortDirection === "desc"
                        ? "Newest First"
                        : "Oldest First"}
                    </span>
                    <ChevronDown className="h-5 w-5 text-muted-foreground" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  className="flex w-fit flex-col gap-2 rounded-2xl"
                  align="start"
                >
                  <ToggleGroup
                    variant="bold"
                    type="single"
                    value={sortDirection}
                    onValueChange={(val) =>
                      !val
                        ? setSortDirection("desc")
                        : setSortDirection(val as any)
                    }
                  >
                    <ToggleGroupItem
                      value="desc"
                      aria-label="Toggle descending"
                      className="grow"
                    >
                      <ArrowUp10 className="mr-1 h-4 w-4" />
                      Newest First
                    </ToggleGroupItem>
                    <ToggleGroupItem
                      value="asc"
                      aria-label="Toggle ascending"
                      className="grow"
                    >
                      <ArrowDown01 className="mr-1 h-4 w-4" />
                      Oldest First
                    </ToggleGroupItem>
                  </ToggleGroup>
                  <CustomDateRangePicker
                    className="mx-auto max-w-80"
                    maxDate={dayjs()?.toDate()}
                    value={dateRange}
                    onValueChange={setDateRange}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>
          {/* Comments Sidebar */}
          <TabsContent value="comments" asChild>
            {/* Scrollable Feed */}
            <div className="flex flex-col overflow-y-auto">
              {/* Secondary column (hidden on smaller screens) */}
              {isFetching ? (
                <FadeIn
                  show
                  className="flex flex-col gap-2 p-2 text-sm text-gray-600"
                >
                  <Skeleton className="h-[108px] w-full bg-[#DFDFDF]" />
                  <Skeleton className="h-[108px] w-full bg-[#DFDFDF]" />
                  <Skeleton className="h-[108px] w-full bg-[#DFDFDF]" />
                  <Skeleton className="h-[108px] w-full bg-[#DFDFDF]" />
                  <Skeleton className="h-[108px] w-full bg-[#DFDFDF]" />
                </FadeIn>
              ) : filteredComments?.length ? (
                filteredComments?.map((comment) => {
                  const isFocused =
                    comment?.platformPostId === focusedPost?.platformPostId;

                  // TODO: Figure out a better way to get account data...
                  const accountData = data?.accounts?.find(
                    (acc) =>
                      acc.platformAccountId === comment.fromPlatformAccountId ||
                      (acc.platformAccountUsername &&
                        acc.platformAccountUsername ===
                          comment?.fromPlatformAccountName),
                  );
                  const postData = data?.posts?.find(
                    (p) => p.platformPostId === comment.platformPostId,
                  );
                  const nestedComments =
                    data?.commentPostHierarchy?.find(
                      (ns) => ns.platformPostId === comment.platformPostId,
                    ) ?? getDefaultNestedComment(comment.platformPostId);

                  const commentAccountDisplayInfo = extractAccountDisplayInfo(
                    comment.platform,
                    comment?.fromPlatformAccountId,
                    comment?.fromPlatformAccountName ??
                      accountData?.platformAccountName,
                    accountData?.platformAccountUsername,
                    accountData?.platformAccountPfp,
                    undefined,
                    user.workspace,
                  );

                  // Skip leftbar entry if it is from existing account
                  if (
                    comment?.fromPlatformAccountId ===
                    currentWorkspaceAccounts?.find(
                      (acc) => acc.platform === comment.platform,
                    )?.id
                  )
                    return;

                  return (
                    <div className="relative" key={comment.platformCommentId}>
                      <FadeIn
                        show
                        className={cn(
                          "relative flex cursor-pointer flex-col gap-3 px-8 py-5 transition-all hover:bg-[#D9E7FC]",
                          isFocused &&
                            "bg-[#397FEF] text-white hover:bg-[#397FEF]",
                        )}
                        onClick={() => {
                          if (
                            focusedPost?.platformPostId ===
                            comment?.platformPostId
                          ) {
                            handleSetFocusedPost(undefined);
                            setFocusedComments(undefined);
                            setReplyToComment(undefined);
                          } else {
                            if (filterReadState === "unread")
                              setFilterReadState("all");
                            handleSetFocusedPost(postData);
                            setFocusedComments(nestedComments);
                            markPostCommentsAsReadMutation.mutate({
                              platform: comment.platform,
                              platformPostId: comment.platformPostId,
                            });
                          }
                        }}
                      >
                        {!comment.markedRead && (
                          <div
                            className={cn(
                              "absolute left-3 top-1/2 h-2.5 w-2.5 -translate-y-1/2 rounded-full",
                              isFocused ? "bg-white" : "bg-[#397FEF]",
                            )}
                          ></div>
                        )}
                        <FadeIn
                          show
                          className="mx-auto flex w-full items-center gap-1 truncate px-8 text-xs"
                        >
                          <NewSocialToLogo
                            platform={comment.platform}
                            className="shrink-0"
                          />
                          <p className="w-auto truncate">
                            {`Comment`}
                            {postData?.caption && (
                              <>
                                {` on `}
                                <i>&quot;{postData?.caption ?? "Post"}&quot;</i>
                              </>
                            )}
                          </p>
                        </FadeIn>
                        <FadeIn show className="flex gap-3">
                          <div className="flex-shrink-0">
                            <img
                              crossOrigin="anonymous"
                              className="h-10 w-10 rounded-full"
                              src={commentAccountDisplayInfo.displayPfp}
                              onError={(event) =>
                                ((event.target as any).src = withPfp(
                                  "",
                                  comment?.fromPlatformAccountId,
                                ))
                              }
                              alt=""
                            />
                          </div>
                          <div className="min-w-0 flex-1">
                            <p className="flex items-center gap-2 text-sm font-medium">
                              {commentAccountDisplayInfo.displayName}
                              <span
                                className={cn(
                                  "text-2xs text-gray-500",
                                  isFocused && "text-gray-200",
                                )}
                              >
                                {dayjs(comment.timestamp).fromNow()}
                              </span>
                            </p>
                            <p
                              className={cn(
                                "truncate text-sm opacity-60",
                                isFocused && "text-white",
                              )}
                            >
                              {comment.content}
                            </p>
                          </div>
                        </FadeIn>
                        {comment.sentiment && (
                          <TooltipButton
                            side="left"
                            text={`Sentiment: ${
                              findSentimentValue(comment.sentiment)?.label
                            }`}
                          >
                            <div
                              className={cn(
                                "absolute right-6 top-1/2 h-2.5 w-2.5 -translate-y-1/2 rounded-full",
                                findSentimentValue(comment.sentiment)
                                  ?.className,
                              )}
                            ></div>
                          </TooltipButton>
                        )}
                        <div className="absolute bottom-0.5 left-1/2 flex -translate-x-1/2 gap-1">
                          {comment.labels?.map((tag) => (
                            <Badge key={tag} size="tiny" variant="secondary">
                              {tag}
                            </Badge>
                          ))}
                        </div>
                      </FadeIn>
                      {postData?.isTaggedPost && (
                        <FadeIn
                          show
                          timeout={400}
                          className="absolute left-3 top-4 rounded"
                        >
                          <TooltipButton
                            text={`Tagged in post from ${
                              postData?.platformAccountUsername ??
                              postData?.platformAccountName ??
                              `${getPlatformName(postData?.platform)} User`
                            }`}
                            align="start"
                          >
                            <Button variant="ghost" size="xs">
                              <Tag className="h-3 w-3 shrink-0" />
                            </Button>
                          </TooltipButton>
                        </FadeIn>
                      )}
                      <FadeIn
                        show
                        timeout={400}
                        className="absolute right-3 top-4 rounded"
                      >
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="xs">
                              <span className="sr-only">Actions</span>
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            {comment.markedRead ? (
                              <DropdownMenuItem
                                onSelect={async (e) => {
                                  e.stopPropagation();
                                  markAsUnreadCommentMutation.mutate(
                                    comment._id,
                                  );
                                }}
                                className="text-xs"
                              >
                                <Mail className="mr-2 h-4 w-4" /> Mark as unread
                              </DropdownMenuItem>
                            ) : (
                              <DropdownMenuItem
                                onSelect={async (e) => {
                                  e.stopPropagation();
                                  markAsReadCommentMutation.mutate(comment._id);
                                }}
                                className="text-xs"
                              >
                                <MailOpen className="mr-2 h-4 w-4" /> Mark as
                                read
                              </DropdownMenuItem>
                            )}

                            <DropdownMenuItem
                              onSelect={() =>
                                setLabelsOpen({
                                  _id: comment._id,
                                  labels: comment.labels ?? [],
                                  platform: comment.platform,
                                })
                              }
                              className="text-xs"
                            >
                              <Tags className="mr-2 h-4 w-4" /> Manage labels
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onSelect={() =>
                                deleteCommentMutation.mutate({
                                  platform: comment.platform,
                                  platformPostId: comment.platformPostId,
                                  platformCommentId: comment.platformCommentId,
                                })
                              }
                              className="text-xs text-red-600"
                            >
                              <Trash2 className="mr-2 h-4 w-4" /> Delete comment
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              onSelect={() => {
                                navigator.clipboard.writeText(
                                  comment.platformCommentId,
                                );
                                dispatch(
                                  setSuccess("Comment ID copied to clipboard!"),
                                );
                              }}
                              className="text-xs"
                            >
                              <ClipboardCopy className="mr-2 h-3 w-3" /> Copy
                              comment ID
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </FadeIn>
                    </div>
                  );
                })
              ) : (
                <FadeIn
                  show
                  timeout={200}
                  className="px-6 text-sm text-gray-600"
                >
                  No results match the filters.
                </FadeIn>
              )}
            </div>
          </TabsContent>
          {/* Posts Sidebar */}
          <TabsContent value="posts" asChild>
            {/* Scrollable Feed */}
            <div className="flex flex-col overflow-y-auto">
              {/* Secondary column (hidden on smaller screens) */}
              {filteredPosts ? (
                filteredPosts?.map((post) => {
                  const isFocused =
                    post?.platformPostId === focusedPost?.platformPostId;

                  // TODO: Figure out a better way to get account data...
                  const accountData = data?.accounts?.find(
                    (acc) =>
                      acc.platformAccountUsername &&
                      acc.platformAccountUsername ===
                        post?.platformAccountUsername,
                  );

                  const nestedComments =
                    data?.commentPostHierarchy?.find(
                      (ns) => ns.platformPostId === post.platformPostId,
                    ) ?? getDefaultNestedComment(post.platformPostId);

                  const postAccountDisplayInfo = extractAccountDisplayInfo(
                    post.platform,
                    post?.platformAccountId,
                    post?.platformAccountName ??
                      accountData?.platformAccountName,
                    post?.platformAccountUsername ??
                      accountData?.platformAccountUsername,
                    post?.platformAccountPfp ?? accountData?.platformAccountPfp,
                    post?.isTaggedPost,
                    user.workspace,
                  );

                  return (
                    <div className="relative" key={post.platformPostId}>
                      <FadeIn
                        show
                        className={cn(
                          "relative flex cursor-pointer flex-col gap-3 px-8 py-5 transition-all hover:bg-[#D9E7FC]",
                          isFocused &&
                            "bg-[#397FEF] text-white hover:bg-[#397FEF]",
                        )}
                        onClick={() => {
                          if (
                            focusedPost?.platformPostId === post?.platformPostId
                          ) {
                            handleSetFocusedPost(undefined);
                            setFocusedComments(undefined);
                            setReplyToComment(undefined);
                          } else {
                            if (filterReadState === "unread")
                              setFilterReadState("all");

                            handleSetFocusedPost(post);
                            setFocusedComments(nestedComments);
                            markPostAsReadMutation.mutate({
                              platform: post.platform,
                              _id: post._id,
                            });
                          }
                        }}
                      >
                        {!post.markedRead && (
                          <div
                            className={cn(
                              "absolute left-3 top-1/2 h-2.5 w-2.5 -translate-y-1/2 rounded-full",
                              isFocused ? "bg-white" : "bg-[#397FEF]",
                            )}
                          ></div>
                        )}
                        <FadeIn
                          show
                          className="mx-auto flex w-full items-center gap-1 truncate px-8 text-xs"
                        >
                          <NewSocialToLogo
                            platform={post.platform}
                            className="shrink-0"
                          />
                          <p className="w-auto truncate">
                            {`${getPlatformName(post.platform)} ${capitalize(
                              post.platformContentType ?? "post",
                            )}`}
                          </p>
                        </FadeIn>
                        <FadeIn show className="flex gap-3">
                          <div className="flex-shrink-0">
                            <img
                              crossOrigin="anonymous"
                              className="h-10 w-10 rounded-full"
                              src={postAccountDisplayInfo.displayPfp}
                              onError={(event) =>
                                ((event.target as any).src = withPfp(
                                  "",
                                  post?.platformAccountId,
                                ))
                              }
                              alt=""
                            />
                          </div>
                          <div className="min-w-0 flex-1">
                            <p className="flex items-center gap-2 text-sm font-medium">
                              {postAccountDisplayInfo.displayName}
                              <span
                                className={cn(
                                  "text-2xs text-gray-500",
                                  isFocused && "text-gray-200",
                                )}
                              >
                                {dayjs(post.timestamp).fromNow()}
                              </span>
                            </p>
                            <p
                              className={cn(
                                "truncate text-sm opacity-60",
                                isFocused && "text-white",
                              )}
                            >
                              {post?.caption}
                            </p>
                          </div>
                        </FadeIn>
                        {post.sentiment && (
                          <TooltipButton
                            side="left"
                            text={`Sentiment: ${
                              findSentimentValue(post.sentiment)?.label
                            }`}
                          >
                            <div
                              className={cn(
                                "absolute right-6 top-1/2 h-2.5 w-2.5 -translate-y-1/2 rounded-full",
                                findSentimentValue(post.sentiment)?.className,
                              )}
                            ></div>
                          </TooltipButton>
                        )}
                        <div className="absolute bottom-0.5 left-1/2 flex -translate-x-1/2 gap-1">
                          {post.labels?.map((tag) => (
                            <Badge key={tag} size="tiny" variant="secondary">
                              {tag}
                            </Badge>
                          ))}
                        </div>
                      </FadeIn>
                      {post?.isTaggedPost && (
                        <FadeIn
                          show
                          timeout={400}
                          className="absolute left-3 top-4 rounded"
                        >
                          <TooltipButton
                            text={`Tagged in post from ${postAccountDisplayInfo.displayName}`}
                            align="start"
                          >
                            <Button variant="ghost" size="xs">
                              <Tag className="h-3 w-3 shrink-0" />
                            </Button>
                          </TooltipButton>
                        </FadeIn>
                      )}
                      <FadeIn
                        show
                        timeout={400}
                        className="absolute right-3 top-4 rounded"
                      >
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="xs">
                              <span className="sr-only">Actions</span>
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            {post.markedRead ? (
                              <DropdownMenuItem
                                onSelect={async (e) => {
                                  e.stopPropagation();
                                  markPostAsUnreadMutation.mutate({
                                    platform: post.platform,
                                    _id: post._id,
                                  });
                                }}
                                className="text-xs"
                              >
                                <Mail className="mr-2 h-4 w-4" /> Mark as unread
                              </DropdownMenuItem>
                            ) : (
                              <DropdownMenuItem
                                onSelect={async (e) => {
                                  e.stopPropagation();
                                  markPostAsReadMutation.mutate({
                                    platform: post.platform,
                                    _id: post._id,
                                  });
                                }}
                                className="text-xs"
                              >
                                <MailOpen className="mr-2 h-4 w-4" /> Mark as
                                read
                              </DropdownMenuItem>
                            )}

                            <DropdownMenuItem
                              onSelect={() =>
                                setLabelsOpen({
                                  _id: post._id,
                                  labels: post.labels ?? [],
                                  platform: post.platform,
                                })
                              }
                              className="text-xs"
                            >
                              <Tags className="mr-2 h-4 w-4" /> Manage labels
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              onSelect={() => {
                                navigator.clipboard.writeText(
                                  post.platformPostId,
                                );
                                dispatch(
                                  setSuccess("Post ID copied to clipboard!"),
                                );
                              }}
                              className="text-xs"
                            >
                              <ClipboardCopy className="mr-2 h-3 w-3" /> Copy
                              post ID
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </FadeIn>
                    </div>
                  );
                })
              ) : (
                <FadeIn
                  show
                  timeout={200}
                  className="px-6 text-sm text-gray-600"
                >
                  No results match the filters.
                </FadeIn>
              )}
            </div>
          </TabsContent>
        </Tabs>

        {/* Focused Content */}
        <FadeIn
          show
          className="flex h-full w-full justify-between overflow-hidden rounded-lg border bg-gradient-to-b from-[#FAFAFA] to-[#D9E6FC]/50 shadow-md"
        >
          {focusedPost ? (
            <>
              <div className="relative flex h-full w-[67%] overflow-y-auto border-r">
                {focusedPost?.isTaggedPost &&
                  focusedPost?.platform === PlatformType.Facebook &&
                  !focusedComments?.comments?.length && (
                    <Badge className="absolute left-1/2 top-4 w-fit -translate-x-1/2">
                      <Info className="mr-1 size-4" />
                      Facebook tagged posts cannot access comments.
                    </Badge>
                  )}
                {focusedComments && (
                  <div className="flex h-full w-full flex-col justify-between">
                    <div className="flex w-full flex-col gap-4 p-6">
                      {focusedComments?.comments?.map((c) => (
                        <Comment
                          comment={c}
                          key={c.platformCommentId}
                          setReplyToComment={(nc: NestedComment) => {
                            setReplyToComment(nc);
                            const { content, ...rest } = nc;
                            generateCommentSuggestionsMutation.mutate({
                              prompt: `Write a comment responding directly to the following comment: """${content}""".
                              
                              The rest of the comment information for additional context is: """${JSON.stringify(
                                rest,
                              )}"""
                            
                              The caption of the social media post these comments relate to is: """${
                                focusedPost?.caption
                              }"""
                            `,
                            });
                          }}
                          replyToComment={replyToComment}
                          accounts={data?.accounts}
                          deleteComment={deleteCommentMutation.mutate}
                          focusedPost={focusedPost}
                          markAsUnreadComment={
                            markAsUnreadCommentMutation.mutate
                          }
                          markAsReadComment={markAsReadCommentMutation.mutate}
                          // setDisplayMedia={setDisplayMedia}
                          // toggleCommentVisibility={toggleCommentVisibility}
                          // createLike={createLike}
                        />
                      ))}
                    </div>
                    <div className="sticky bottom-0 flex flex-col bg-inherit backdrop-blur-3xl">
                      {!!replyToComment && (
                        <div className="flex flex-col">
                          <div className="flex h-auto w-full items-center justify-between bg-primary/80 p-2 text-xs text-primary-foreground backdrop-blur-2xl">
                            <p className="truncate">
                              <strong>Replying to</strong>{" "}
                              <i>&quot;{replyToComment.content}&quot;</i>
                            </p>
                            <XCircle
                              className="ml-2 h-4 w-4 flex-shrink-0 cursor-pointer"
                              onClick={() => {
                                setReplyToComment(undefined);
                                setComment("");
                                setReplacements({});
                              }}
                            />
                          </div>
                          <FadeIn
                            show
                            timeout={300}
                            className="flex h-fit gap-2 border-primary/80 p-2"
                          >
                            {generateCommentSuggestionsMutation.isPending ? (
                              <>
                                <Skeleton className="h-20 w-full flex-1 bg-black/5" />
                                <Skeleton className="h-20 w-full flex-1 bg-black/5" />
                                <Skeleton className="h-20 w-full flex-1 bg-black/5" />
                              </>
                            ) : (
                              Boolean(commentSuggestions?.length) && (
                                <>
                                  {commentSuggestions.map((cs) => (
                                    <div
                                      key={cs}
                                      className="relative flex-1 cursor-pointer rounded-md bg-black/5 p-3 text-xs hover:scale-105 hover:bg-black/10 hover:transition-all"
                                    >
                                      <ViewOnlyCaptionWithFallback
                                        stringContent={cs}
                                      />
                                      <div className="absolute bottom-1 right-1 z-30 flex items-center">
                                        <TooltipButton
                                          text="Edit suggestion"
                                          className="z-40 bg-white"
                                          side="left"
                                        >
                                          <Button
                                            variant="ghost"
                                            size="tiny"
                                            onClick={() => {
                                              setComment(cs);
                                              setCommentSuggestions(undefined);
                                            }}
                                          >
                                            <Pencil className="size-3" />
                                          </Button>
                                        </TooltipButton>
                                        <TooltipButton
                                          text="Send suggestion"
                                          className="z-40 bg-white"
                                          side="left"
                                        >
                                          <Button
                                            variant="ghost"
                                            size="tiny"
                                            onClick={() =>
                                              handleCreateComment(cs)
                                            }
                                          >
                                            <Send className="size-3" />
                                          </Button>
                                        </TooltipButton>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )
                            )}
                          </FadeIn>
                        </div>
                      )}
                      <div className="flex items-center gap-2 p-6 pt-0">
                        {/* PFP */}
                        <div className="h-10 w-10 rounded-full drop-shadow">
                          <img
                            src={pfp}
                            className="rounded-full object-cover"
                            crossOrigin="anonymous"
                          />
                        </div>
                        <Textarea
                          className="min-h-8 rounded-lg"
                          rows={3}
                          placeholder={
                            replyToComment
                              ? `Reply to "${
                                  replyToComment.fromPlatformAccountName ??
                                  `${getPlatformName(
                                    replyToComment.platform,
                                  )} User`
                                }"`
                              : `Comment as "${name}"`
                          }
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                        <TooltipButton
                          side="left"
                          text={
                            cannotCommentOnInstagramTags
                              ? "Cannot comment on Instagram tagged posts"
                              : replyToComment
                                ? `Reply to "${
                                    replyToComment.fromPlatformAccountName ??
                                    "Facebook User"
                                  }"`
                                : "Comment"
                          }
                        >
                          <Button
                            size="icon"
                            disabled={
                              isFetching ||
                              !comment ||
                              cannotCommentOnInstagramTags
                            }
                            onClick={() => handleCreateComment(comment)}
                          >
                            <SendHorizonal className="h-5 w-5" />
                          </Button>
                        </TooltipButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="relative mx-auto flex h-min max-h-full w-fit max-w-[300px] shrink-0 overflow-y-auto p-3">
                <Post
                  key={focusedPost?.platformPostId}
                  media={focusedPost?.media ?? []}
                  caption={focusedPost?.caption}
                  platform={focusedPost?.platform}
                  platformContentType={
                    focusedPost?.platformContentType ?? "post"
                  }
                  likeCount={focusedPost?.likes ?? focusedPost?.reactions}
                  raw={
                    focusedPost?.platform === PlatformType.Facebook
                      ? {
                          totalComments: focusedPost?.comments,
                          shares: focusedPost?.shares,
                        }
                      : undefined
                  }
                  when={dayjs(focusedPost?.timestamp)}
                  mediaClassName="max-h-[400px] w-full"
                  hideVideoControls={
                    focusedPost?.platformContentType === "reel"
                  }
                  forceDisplayPfp={focusedPostAccountDisplayInfo.displayPfp}
                  forceDisplayName={focusedPostAccountDisplayInfo.displayName}
                  parentPostId={focusedPost?.parent_id}
                />
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      size="xs"
                      className={cn(
                        "absolute right-4 top-6 rounded",
                        focusedPost?.platformContentType === "reel" &&
                          "top-5 bg-gray-500/30 text-white shadow",
                      )}
                    >
                      <span className="sr-only">Actions</span>
                      <MoreHorizontal className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem
                      onSelect={() =>
                        window.open(
                          focusedPost?.permalink ?? focusedPost?.permalink_url,
                          "_blank",
                          "noopener,noreferrer",
                        )
                      }
                      className="flex items-center gap-1 text-xs text-gray-600"
                    >
                      <NewSocialToLogo platform={focusedPost.platform} />
                      View on {getPlatformName(focusedPost.platform)}
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onSelect={async () => {
                        dispatch(setLoading("Refreshing post..."));
                        await handleRefreshPost(
                          focusedPost.platform,
                          focusedPost.platformPostId,
                        );
                        dispatch(setSuccess("Refreshed post"));
                      }}
                      className="flex items-center gap-1 text-xs text-gray-600"
                    >
                      <RefreshCcw className="h-3 w-3" />
                      Refresh post
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      onSelect={() => {
                        navigator.clipboard.writeText(
                          focusedPost?.platformPostId,
                        );
                        dispatch(setSuccess("Post ID copied to clipboard!"));
                      }}
                      className="text-xs"
                    >
                      <ClipboardCopy className="mr-2 h-3 w-3" /> Copy post ID
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </>
          ) : (
            <div className="m-16 flex w-full flex-col items-center justify-center gap-2 rounded-md border-2 border-dashed">
              <WizardIllustration className="mx-auto h-auto w-48" />
              {/* <div className="max-w-sm text-center text-lg font-semibold text-gray-600/60">
                “If you want to go quickly, go alone. If you want to go far, go
                together.” ✨
              </div> */}
            </div>
          )}
        </FadeIn>
      </div>

      {/* Extras */}
      <Sheet
        open={!!labelsOpen}
        onOpenChange={(open) => !open && setLabelsOpen(undefined)}
      >
        <SheetContent className="flex flex-col justify-between">
          <div className="flex flex-col gap-4">
            <SheetHeader>
              <SheetTitle>Manage labels</SheetTitle>
              <SheetDescription>
                Add or remove labels. Click save when you&apos;re done.
              </SheetDescription>
            </SheetHeader>
            <Tagger<string>
              placeholder="Add labels..."
              tags={labelsOpen?.labels}
              getTag={(t) => t}
              onCreate={(t) =>
                labelsOpen &&
                !labelsOpen?.labels.includes(t) &&
                setLabelsOpen({
                  ...labelsOpen,
                  labels: [...(labelsOpen?.labels ?? []), t],
                })
              }
              onDelete={(t) => {
                if (!labelsOpen) return;
                const tmp = [...(labelsOpen?.labels ?? [])];
                const i = tmp.indexOf(t);
                if (i >= 0) {
                  tmp.splice(i, 1);
                  setLabelsOpen({ ...labelsOpen, labels: tmp });
                }
              }}
            />
          </div>
          <SheetFooter>
            <SheetClose asChild>
              <Button
                onClick={() =>
                  (searchParams.get("tab") ?? "comments") === "comments"
                    ? setPlatformCommentLabelsMutation.mutate(labelsOpen)
                    : setPlatformPostLabelsMutation.mutate({ ...labelsOpen })
                }
              >
                <Save className="mr-2 h-4 w-4" />
                Save
              </Button>
            </SheetClose>
          </SheetFooter>
        </SheetContent>
      </Sheet>
      <DisplayMedia
        show={!!displayMedia}
        onHide={() => setDisplayMedia(undefined)}
        media={displayMedia}
      />
    </div>
  );
}
